import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../Sidebar';
import { DatePicker, Space } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import config from '../../../Config/config';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import axios from 'axios';
import Heatmap from '../Heatmap';
import dayjs from 'dayjs';
import { valueSocket } from '../../../context/socket';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { calculateBrokerage } from '../../../utils/BrokerCharges';
import PortfolioOpenCards from '../../../utils/PortfolioOpenCards';
import PortfolioMobileCards from '../../../utils/PortfolioMobileCards';
import SwitchedStrategies from './SwitchedStrategies';
import PortfolioDataCards from './PortfolioDataCards';
import DateTime from '../../Common/DateTime';
import ExitTradeApi from '../../../API/OrderExit';
import PortfolioDesktopTable from './PortfolioDesktopTable';
import ClosedMobileCards from '../../../utils/ClosedMobileCards';
import TradeMobileCards from '../../../utils/TradeMobileCards';

var totalPnL = 0;

const VirtualPortfolio = () => {
  const [isMobile, setIsMobile] = useState(false);
  let getuserBots = localStorage.getItem('token');
  const [toggleState, setToggleState] = useState('open-order');
  const [heatmapData, setHeatmapData] = useState([]);
  const [overAllPnL, setOverAllPnL] = useState({});
  const [availableStrategies, setAvailableStrategies] = useState([]);
  const [tableDate, setTableDate] = useState([]);
  const [openOrder, setOpenOrder] = useState([]);
  const [strategyResult, setStrategyResult] = useState([]);
  const [objectMatch, setObjectMatch] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const { RangePicker } = DatePicker;
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  const location = useLocation();
  let tab = location.pathname.split('/portfolio/')[1];
  const [lastOrder, setLastOrder] = useState([]);
  const [dataLive, setDataLive] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [symbolsArray, setSymbolsArray] = useState([]);
  const [newData, setNewData] = useState([]);
  const [toggleMore, settoggleMore] = useState(1);
  const [emitTradeSymbol, setEmitTradeSymbol] = useState([]);
  const [currentPage, setCurrentPage] = useState(2);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [desktopClosedOrders, setDesktopClosedOrders] = useState([]);
  const [mobileClosedOrders, setMobileClosedOrders] = useState([]);
  const [desktopTrades, setDesktopTrades] = useState([]);
  const [mobileTrades, setMobileTrades] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const toggleShow = useCallback(
    (index) => {
      settoggleMore(index);
    },
    [toggleMore],
  );

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  //Range Picker Date Change
  const HandleDateChange = (date, dateString) => {
    setTableDate(dateString);
    setMobileClosedOrders([]);
    setMobileTrades([]);
    if (
      dateString[0] !== '' &&
      dateString[1] !== '' &&
      dateString[0] !== undefined &&
      dateString[1] !== undefined
    ) {
      if (toggleState === 'close-order') {
        userClosedOrders('', dateString, 1);
      }
      if (toggleState === 'trades') {
        userTradeOrders('', dateString, 1);
      }
    } else {
      if (toggleState === 'close-order') {
        userClosedOrders('today', '', 1);
      }
      if (toggleState === 'trades') {
        userTradeOrders('today', '', 1);
      }
    }
    pnlData('today', dateString);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  //PNL Data
  async function pnlData(Date, selectedDate) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate[0] !== '' &&
        selectedDate[1] !== '' &&
        selectedDate.length > 0
      ) {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-orders-pnl/${userid.id}?day=custom&start=${
          selectedDate[0]
        }&end=${selectedDate[1]}`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-orders-pnl/${userid.id}?day=${Date}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      setOverAllPnL(response?.data);
      setHeatmapData(response?.data?.TotalDayWisePnl);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //Open-orders call
  useEffect(() => {
    const openOrders = async () => {
      try {
        const response = await axios.get(
          `${config.base_url}${
            tab === 'live' ? config.live_trade : config.virtual_trade
          }/get-user-open-orders/${userid.id}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );
        const ordersData = response?.data?.orders_data;
        const finalOrder = ordersData
          ?.filter((obj) => obj.quantity_left > 0)
          .map((item) => ({
            ...item.vt_entry,
            quantity_left: item.quantity_left,
          }));
        setOpenOrder(
          finalOrder.map((item) => ({
            ...item,
            strategys: item?.strategys[0]?.name,
            vt_requests: item?.vt_requests[0]?.vt_inputs.report_name,
            livePrice: 0,
            pnl: 0,
            key: 0,
            entryPrice: item?.trigger_price,
            charges: 0,
            entryQuantity: item?.quantity,
            entryTime: item?.order_timestamp,
          })),
        );
        setSymbolsArray([
          ...new Set(
            ordersData
              .filter(
                (item) =>
                  item?.vt_entry &&
                  item?.vt_entry.tradingsymbol &&
                  item?.quantity_left > 0,
              )
              .map((item) => item.vt_entry.tradingsymbol),
          ),
        ]);
        valueSocket.connect();
        valueSocket.emit('trade_orders', {
          user_id: [`${userid.id}_${tab === 'live' ? 'lt' : 'vt'}`],
        });
      } catch (error) {
        console.log(error);
      }
    };
    openOrders();
    return () => {
      valueSocket.disconnect();
    };
  }, []);

  //To Emit the symbols
  useEffect(() => {
    const resultArray = strategyResult
      .map((obj1) => {
        const matchingObj2 = openOrder.find(
          (obj2) => obj2?.request_id === obj1.request_id,
        );
        return matchingObj2
          ? `${
              tab === 'live'
                ? obj1.lt_inputs.symbol.replace(/\s/g, '_')
                : obj1.vt_inputs.symbol.replace(/\s/g, '_')
            }_${matchingObj2.strike_price}_${matchingObj2.option_type}`
          : null;
      })
      .filter(Boolean);
    setEmitTradeSymbol([...new Set(resultArray)]);
  }, [strategyResult, openOrder.length]);

  useEffect(() => {
    valueSocket.emit('option_price', { symbol: emitTradeSymbol });
  }, [emitTradeSymbol.length]);

  useEffect(() => {
    const updatedData = openOrder.map((item) => {
      if (item.tradingsymbol === dataLive?.optionData?.symbol) {
        const matchingBroker = strategyResult.find(
          (item1) => item1.request_id === item.request_id,
        );
        const brokername = matchingBroker
          ? matchingBroker?.broker_name
          : 'Zerodha';
        item.livePrice = dataLive?.optionData?.price;
        item.pnl = calculateBrokerage(
          item?.entryPrice,
          dataLive.optionData.price,
          item?.lot_size * item?.quantity_left,
          item?.position_type,
          brokername === 'Kotak' ? 'Kotak' : 'Zerodha',
        )[1].toFixed(2);
        item.key = new Date().getTime() + item.request_id;
        item.charges = calculateBrokerage(
          item?.entryPrice,
          dataLive.optionData.price,
          item?.lot_size * item?.quantity_left,
          item?.position_type,
          brokername === 'Kotak' ? 'Kotak' : 'Zerodha',
        )[0].toFixed(2);
      }
      return item;
    });
    if (updatedData.length > 0) {
      setLastOrder(updatedData);
    }

    totalPnL = updatedData.reduce((accumulator, currentObj) => {
      return accumulator + parseFloat(currentObj.pnl);
    }, 0);
  }, [dataLive]);

  useEffect(() => {
    setLastOrder(openOrder);

    valueSocket.on('get_option_price', (livePriceData) => {
      setDataLive(livePriceData);
    });
  }, [symbolsArray]);

  useEffect(() => {
    valueSocket.on('get_trade_orders', (Data) => {
      setOpenOrder((prevData) => {
        let updatedData = [...prevData];
        const existingObjectIndex = updatedData.findIndex(
          (item) => item?.request_id === Data?.orderDataNew?.request_id,
        );
        if (existingObjectIndex !== -1) {
          if (Data?.orderDataNew?.quantity_left === 0) {
            updatedData.splice(existingObjectIndex, 1);
            setNewData(Data?.orderDataNew);
          } else {
            updatedData[existingObjectIndex] = {
              ...updatedData[existingObjectIndex],
              ...Data?.orderDataNew,
            };
          }
        } else {
          setObjectMatch(Data?.orderDataNew);
        }
        return updatedData;
      });
    });
  }, []);

  useEffect(() => {
    const index = symbolsArray.indexOf(newData?.tradingsymbol);
    setTimeout(() => {
      if (tableDate[0] !== '') {
        pnlData('today', '');
      }
    }, '2000');
    if (index !== -1 && symbolsArray.length > 1) {
      const newArray = [...symbolsArray];
      newArray.splice(index, 1);
      setSymbolsArray(newArray);
    }
    if (index !== -1 && symbolsArray.length === 1 && openOrder.length === 0) {
      setSymbolsArray([]);
      totalPnL = 0;
    }
    if (index !== -1 && symbolsArray.length === 1 && openOrder.length > 0) {
      setLastOrder(openOrder);
    }
  }, [newData]);

  useEffect(() => {
    const indexToUpdate = strategyResult.findIndex(
      (obj) => obj.request_id === objectMatch.request_id,
    );
    if (indexToUpdate !== -1) {
      objectMatch.vt_requests = strategyResult[indexToUpdate]?.report_name;
      objectMatch.strategys = strategyResult[indexToUpdate]?.st_name;
      objectMatch.entryPrice = objectMatch.trigger_price;
      objectMatch.entryQuantity = objectMatch.quantity;
      objectMatch.entryTime = objectMatch.order_timestamp;
      openOrder.push(objectMatch);

      const index = symbolsArray.indexOf(objectMatch?.tradingsymbol);
      if (index === -1) {
        setSymbolsArray([...symbolsArray, objectMatch.tradingsymbol]);
      }
    }
  }, [objectMatch]);

  async function userClosedOrders(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate[0] !== undefined &&
        selectedDate[1] !== undefined &&
        selectedDate !== null &&
        selectedDate[0] !== '' &&
        selectedDate[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-placed-orders/${
          userid.id
        }?limit=10&page=${page}&day=custom&start=${selectedDate[0]}&end=${
          selectedDate[1]
        }`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-placed-orders/${userid.id}?day=${Date}&page=${page}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const orders = response.data;
      const finalData = response.data.orders_data;
      if (isMobile) {
        setMobileClosedOrders((closedOrdersData) => [
          ...closedOrdersData,
          ...finalData,
        ]);
      } else {
        setDesktopClosedOrders(
          finalData.map((item) => ({
            ...item,
            key: item?._id,
            strategys: item?.strategys[0]?.name,
            vt_requests:
              tab === 'live'
                ? item?.lt_requests[0]?.lt_inputs?.report_name
                : item?.vt_requests[0]?.vt_inputs?.report_name,
          })),
        );
        setPagination({
          ...pagination,
          current: page,
          total: orders?.total_orders,
        });
      }

      if (mobileClosedOrders.length === orders?.total_orders) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //Closed order call
  async function userTradeOrders(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate[0] !== '' &&
        selectedDate[1] !== '' &&
        selectedDate.length > 0
      ) {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${
          userid.id
        }?limit=10&page=${page}&day=custom&start=${selectedDate[0]}&end=${
          selectedDate[1]
        }`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${
          userid.id
        }?limit=10&page=${page}&day=${Date}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      const finalData = response.data.orders_data;

      if (isMobile) {
        setMobileTrades((mobileTrades) => [...mobileTrades, ...finalData]);
      } else {
        setDesktopTrades(
          data?.orders_data.map((item) => ({
            ...item,
            key: item?._id,
          })),
        );
        setPagination({
          ...pagination,
          current: page,
          total: data?.total_orders,
        });
      }
      if (mobileTrades.length === data?.total_orders) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const paginationCall = useCallback(() => {
    if (toggleState === 'trades') {
      if (
        tableDate[0] !== '' &&
        tableDate[1] !== '' &&
        tableDate[0] !== undefined &&
        tableDate[1] !== undefined
      ) {
        userTradeOrders('', tableDate, currentPage);
      } else {
        userTradeOrders('today', '', currentPage);
      }
    } else {
      if (
        tableDate[0] !== '' &&
        tableDate[1] !== '' &&
        tableDate[0] !== undefined &&
        tableDate[1] !== undefined
      ) {
        userClosedOrders('', tableDate, currentPage);
      } else {
        userClosedOrders('today', '', currentPage);
      }
    }
    setCurrentPage(currentPage + 1);
  }, [tableDate, currentPage]);

  //initial function call
  useEffect(() => {
    setTableDate([]);
    setCurrentPage(2);
    setHasMore(true);
    setMobileClosedOrders([]);
    setMobileTrades([]);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    if (toggleState === 'close-order') {
      userClosedOrders('today', '', 1);
    }
    if (toggleState === 'trades') {
      userTradeOrders('today', '', 1);
    }
    if (toggleState !== 'open-order') {
      pnlData('today', '');
    }
  }, [toggleState]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    valueSocket.disconnect();
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };
  //To caluculate the total investment
  useEffect(() => {
    if (lastOrder.length === 0) {
      setTotalInvestment(0);
    } else {
      setTotalInvestment(
        lastOrder.reduce((accumulator, obj) => {
          const product = obj.entryPrice * obj.lot_size * obj.entryQuantity;
          return accumulator + product;
        }, 0),
      );
    }
  }, [lastOrder.length]);

  const handleKillSwitch = (status) => {
    const checkedReportNames = lastOrder.filter((obj) =>
      selectedItems.includes(obj.request_id),
    );
    const credentialData = lastOrder
      .filter((obj) => selectedItems.includes(obj.request_id))
      .map((obj) => ({
        ...obj,
        credential_id: availableStrategies
          .flatMap((obj) => obj.ltList || [])
          .find((item) => item.request_id === obj.request_id)?.credential_id,
      }));

    if (status !== 'day-exit') {
      setIsChecked(true);
      setTimeout(() => setIsChecked(false), 500);
    }

    const itemsToProcess = tab === 'live' ? credentialData : checkedReportNames;

    itemsToProcess.forEach((obj) => getKillSwitch(obj, status));
  };

  //selected checkbox
  const handleCheckboxChange = (request_id) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(request_id)) {
        return prevSelected.filter((item) => item !== request_id);
      } else {
        return [...prevSelected, request_id];
      }
    });
  };

  const handleTableChange = useCallback(
    (newPagination) => {
      if (toggleState === 'trades') {
        if (
          tableDate[0] !== '' &&
          tableDate[1] !== '' &&
          tableDate[0] !== undefined &&
          tableDate[1] !== undefined
        ) {
          userTradeOrders('', tableDate, newPagination.current);
        } else {
          userTradeOrders('today', '', newPagination.current);
        }
      } else {
        if (
          tableDate[0] !== '' &&
          tableDate[1] !== '' &&
          tableDate[0] !== undefined &&
          tableDate[1] !== undefined
        ) {
          userClosedOrders('', tableDate, newPagination.current);
        } else {
          userClosedOrders('today', '', newPagination.current);
        }
      }
    },
    [tableDate],
  );

  //Day status payload
  const getKillSwitch = (data, status) => {
    let payload = {
      exchange: data?.exchange,
      request_id: data?.request_id,
      user_id: data?.user_id,
      strategy_id: data?.strategy_id,
      option_type: data?.option_type,
      strike_price: data?.strike_price,
      lot_size: data?.lot_size,
      order_type: data?.order_type,
      tradingsymbol: data?.tradingsymbol,
      market_type: null,
      expiry: '',
      position_type: data?.position_type,
      quantity_left: data?.quantity,
      transaction_type: data?.transaction_type,
      trade_action: data?.trade_action ? data?.trade_action : data?.trade_type,
      trade_type: data?.trade_action ? data?.trade_action : data?.trade_type,
    };
    if (status === 'day-exit') {
      payload.day_status = false;
    }
    if (tab === 'virtual') {
      ExitTradeApi(payload, tab);
    } else {
      payload.credential_id = data?.credential_id;
      ExitTradeApi(payload, tab);
    }
    // console.log(payload);
  };

  const dropDownCall = () => {
    if (toggleState === 'open-order') {
      return <span>Open Order</span>;
    } else if (toggleState === 'close-order') {
      return <span>Closed Order</span>;
    } else if (toggleState === 'trades') {
      return <span>Trades</span>;
    } else if (toggleState === 'p&l') {
      return <span>PNL</span>;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="left__bar">
        <div className="row off__blue p-2 rounded">
          <div className="col-6">
            <h2 className="strtgy__ttl">Portfolio Over View</h2>
            <span className="badge__green me-2">
              {tab === 'live' ? 'Live' : 'Virtual'}
            </span>
            <DateTime />
          </div>
          <div className="col-6 text-end pe-1 pe-lg-4 d-flex justify-content-end">
            <div
              className={`align-items-end d-flex pb-3 pe-2 ${
                totalPnL > 0
                  ? 'text-success'
                  : totalPnL < 0
                  ? 'text-danger'
                  : ''
              }`}
            >
              {totalPnL > 0 ? (
                <BsArrowUp />
              ) : totalPnL < 0 ? (
                <BsArrowDown />
              ) : null}
            </div>
            <div>
              <h3 className="pnl__ttl">Current P&L</h3>
              <h4
                className={`pnl__details fw-semibold ${
                  totalPnL > 0
                    ? 'text-success'
                    : totalPnL < 0
                    ? 'text-danger'
                    : ''
                }`}
              >
                {totalPnL === 0 ? 0 : totalPnL.toFixed(2)}
              </h4>
            </div>
          </div>
        </div>

        <>
          <PortfolioDataCards
            toggleMore={toggleMore}
            toggleShow={toggleShow}
            overAllPnL={overAllPnL}
            totalInvestment={totalInvestment}
            tableDate={tableDate}
          />
        </>
        <>
          <SwitchedStrategies
            isMobile={isMobile}
            setStrategyResult={setStrategyResult}
            setAvailableStrategies={setAvailableStrategies}
            tab={tab}
          />
        </>
        <div className="row strtgy__table__box mt-1 lg-mt-3">
          <div className="col-5 col-lg-6 ps-0">
            <ul className="d-flex strtgy__tbl">
              <li
                onClick={() => toggleTab('open-order')}
                className={toggleState === 'open-order' ? 'active' : 'tabs'}
              >
                <span>Open Order</span>
              </li>
              <li
                onClick={() => toggleTab('close-order')}
                className={toggleState === 'close-order' ? 'active' : 'tabs'}
              >
                <span>Closed Order</span>
              </li>
              <li
                onClick={() => toggleTab('trades')}
                className={toggleState === 'trades' ? 'active' : 'tabs'}
              >
                <span>Trades</span>
              </li>
              <li
                onClick={() => toggleTab('p&l')}
                className={toggleState === 'p&l' ? 'active' : 'tabs'}
              >
                <span>P&L</span>
              </li>
            </ul>
            <div className="strtgy__table__drpdwn">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {dropDownCall()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => toggleTab('open-order')}
                    className={toggleState === 'open-order' ? 'active' : 'tabs'}
                  >
                    Open Order
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => toggleTab('close-order')}
                    className={
                      toggleState === 'close-order' ? 'active' : 'tabs'
                    }
                  >
                    Closed Order
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => toggleTab('trades')}
                    className={toggleState === 'trades' ? 'active' : 'tabs'}
                  >
                    Trades
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => toggleTab('p&l')}
                    className={toggleState === 'p&l' ? 'active' : 'tabs'}
                  >
                    PNL
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center pe-2 pe-lg-0">
            {toggleState === 'open-order' && (
              <>
                <div
                  style={{
                    display: `${selectedItems.length === 0 ? 'none' : 'block'}`,
                  }}
                >
                  <div className="d-flex btn_swipe_exit mx-2 ">
                    <div className="button" id="button-1">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={isChecked}
                        disabled={selectedItems.length === 0}
                        onClick={() => handleKillSwitch('order-exit')}
                      />
                      <div className="knobs"></div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: `${selectedItems.length === 0 ? 'none' : 'block'}`,
                  }}
                >
                  <button
                    className="d-flex btn__exit mx-2"
                    onClick={() => handleKillSwitch('day-exit')}
                  >
                    <Tooltip title="Kill Switch" placement="top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-power"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.5 1v7h1V1h-1z" />
                        <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                      </svg>
                    </Tooltip>
                  </button>
                </div>
              </>
            )}
            {toggleState === 'close-order' && (
              <div className="d-flex cstm__dt__pick">
                <Space direction="vertical">
                  <RangePicker
                    onChange={HandleDateChange}
                    placeholder={['Today', '']}
                    disabledDate={(current) => {
                      return dayjs().add(0, 'days') <= current;
                    }}
                  />
                </Space>
              </div>
            )}
            {toggleState === 'trades' && (
              <div className="d-flex cstm__dt__pick">
                <Space direction="vertical">
                  <RangePicker
                    onChange={HandleDateChange}
                    placeholder={['Today', '']}
                    disabledDate={(current) => {
                      return dayjs().add(0, 'days') <= current;
                    }}
                  />
                </Space>
              </div>
            )}
          </div>
        </div>

        <div className="tbl_data row">
          <div
            className={
              toggleState === 'open-order'
                ? 'tab-content-active'
                : 'tabs-content'
            }
          >
            <div className="col ps-0 pe-0 mt-3">
              <div>
                {isMobile == false ? (
                  <PortfolioOpenCards
                    tab={tab}
                    lastOrder={lastOrder}
                    selectedItems={selectedItems}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ) : (
                  <PortfolioMobileCards
                    lastOrder={lastOrder}
                    selectedItems={selectedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    tab={tab}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 'close-order'
                ? 'tab-content-active'
                : 'tabs-content'
            }
          >
            <div className="col ps-0 pe-0 mt-3">
              <>
                {isMobile === false ? (
                  <PortfolioDesktopTable
                    tradeOrder={desktopClosedOrders}
                    from="close"
                    pagination={pagination}
                    handleTableChange={handleTableChange}
                    tab={tab}
                  />
                ) : (
                  <ClosedMobileCards
                    paginationCall={paginationCall}
                    closedTrades={mobileClosedOrders}
                    tab={tab}
                    hasMore={hasMore}
                  />
                )}
              </>
            </div>
          </div>

          <div
            className={
              toggleState === 'trades' ? 'tab-content-active' : 'tabs-content'
            }
          >
            <div className="col ps-0 pe-0 mt-3">
              <>
                {isMobile == false ? (
                  <PortfolioDesktopTable
                    tradeOrder={desktopTrades}
                    from="trades"
                    pagination={pagination}
                    handleTableChange={handleTableChange}
                    tab={tab}
                  />
                ) : (
                  <TradeMobileCards
                    tradeCall={paginationCall}
                    tradeOrdersData={mobileTrades}
                    tab={tab}
                    hasMore={hasMore}
                  />
                )}
              </>
            </div>
          </div>

          <div
            className={
              toggleState === 'p&l' ? 'tab-content-active' : 'tabs-content'
            }
          >
            <div className="col ps-0 pe-0 mt-1">
              <div className="heatmap__bg">
                <Heatmap mapData={heatmapData} from="portfolio" />
              </div>
              {/* <div className="close__order__data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#4074f9"
                  className="bi bi-database-exclamation"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5Zm0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                </svg>
                <span>You have no data yet!</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualPortfolio;
