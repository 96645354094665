import React from 'react';
import { Input, Select, TimePicker, DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const ModalInputFields = ({
  handleInputFormExchange,
  handleselect,
  handleInputStocks,
  datepickersvalues,
  calcTopGL,
  handleInputFormtime,
  handelChangeInputs,
  checked,
  timeFormat,
  inputs,
}) => {
  return (
    <div className="row">
      {inputs.map((el, index) => {
        if (el.input_type === 'select' && el.value_type !== 'array') {
          if (el.value_name === 'exchange') {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>
                <Select
                  size="large"
                  name={el.value_name}
                  defaultValue={el.value_default}
                  onChange={(value) =>
                    handleInputFormExchange(el.value_name, value)
                  }
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  {el.select_options &&
                    el.select_options.map((elem) => (
                      <Select.Option value={elem}>{elem}</Select.Option>
                    ))}
                </Select>
              </div>
            );
          } else {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>
                <Select
                  size="large"
                  defaultValue={el.value_default}
                  onChange={(value) => handleselect(el.value_name, value)}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  {el.select_options &&
                    el.select_options.map((elem) => (
                      <Select.Option value={elem}>{elem}</Select.Option>
                    ))}
                </Select>
              </div>
            );
          }
        } else if (el.input_type === 'select' && el.value_type === 'array') {
          if (el.value_name === 'stocks') {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>
                <Select
                  showSearch
                  name={el.value_name}
                  size="large"
                  defaultValue={el.value_default ? el.value_default : []}
                  optionFilterProp="children"
                  mode="multiple"
                  allowClear={true}
                  onChange={(value) => handleInputStocks(el.value_name, value)}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  {el.select_options &&
                    el.select_options.map((elem) => (
                      <Select.Option value={elem}>{elem}</Select.Option>
                    ))}
                </Select>
              </div>
            );
          } else {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>
                <Select
                  name={el.value_name}
                  showSearch
                  size="large"
                  defaultValue={[]}
                  optionFilterProp="children"
                  mode="multiple"
                  allowClear={true}
                  onChange={(value) => handleInputStocks(el.value_name, value)}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  {el.value_default &&
                    el.value_default.map((elem) => (
                      <Select.Option value={elem}>{elem}</Select.Option>
                    ))}
                </Select>
              </div>
            );
          }
        } else if (el.input_type === 'Date') {
          return (
            <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
              <div className="labelDiv">
                <label>{el.name}</label>
              </div>
              <RangePicker
                onChange={(val) => datepickersvalues(el.value_name, val)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              />
            </div>
          );
        } else if (el.input_type === 'Toggle') {
          return (
            <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
              <div className="labelDiv">
                <label>{el.name}</label>
              </div>
              <label className="switch button">
                <input
                  type="checkbox"
                  checked={checked[el.value_name]}
                  onChange={(e) => {
                    calcTopGL(el.value_name, e.target.checked);
                  }}
                />
                <span className="slider"></span>
              </label>
            </div>
          );
        } else if (el.input_type === 'Time') {
          return (
            <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
              <div className="labelDiv">
                <label>{el.name}</label>
              </div>
              <TimePicker
                defaultValue={moment(el.value_default, timeFormat)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                format={timeFormat}
                onChange={(e) => handleInputFormtime(e, el.value_name)}
              />
            </div>
          );
        } else {
          if (el.value_type === 'array') {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>

                <Input
                  type={el.input_type}
                  name={el.value_name}
                  step={el.step ? el.step : ''}
                  min={el.min ? el.min : ''}
                  max={el.max ? el.max : ''}
                  defaultValue={el.value_default}
                  onChange={(e) => handelChangeInputs(e, el.value_type)}
                />
              </div>
            );
          } else {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                <div className="labelDiv">
                  <label>{el.name}</label>
                </div>
                <Input
                  type={el.input_type}
                  name={el.value_name}
                  step={el.step ? el.step : ''}
                  min={el.min ? el.min : ''}
                  max={el.max ? el.max : ''}
                  defaultValue={el.value_default}
                  onChange={(e) => handelChangeInputs(e, el.value_type)}
                />
              </div>
            );
          }
        }
      })}
    </div>
  );
};
export default ModalInputFields;
