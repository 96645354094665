import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { BsInfoCircle } from '../assets/Icon';
import { Tooltip } from '@mui/material';

function BrokerCard({
  image,
  name,
  description,
  showModal,
  id,
  getBroker,
  instruct,
}) {
  ///console.log(name, description);
  let history = useHistory();
  return (
    <div className="brockerCard__body">
      <div className="brocker__img">
        <img src={image} alt="brocker img" />
      </div>
      <div className="brocker__info">
        <p className="brocker__name">{name}</p>
        <p className="brocker__description">{description}</p>
      </div>
      <div className="connect__button">
        {instruct ? (
          <button
            className="button"
            onClick={() => {
              if (name.toUpperCase() === 'Alice Blue'.toUpperCase()) {
                history.push('/broker/instructions/aliceBlue');
              } else if (name.toUpperCase() === 'Angle One'.toUpperCase()) {
                history.push('/broker/instructions/angelone');
              } else if (name.toUpperCase() === '5Paisa'.toUpperCase()) {
                history.push('/broker/instructions/paisa');
              } else if (name.toUpperCase() === 'Zerodha'.toUpperCase()) {
                history.push('/broker/instructions/zerodha');
              } else if (name.toUpperCase() === 'Flat Trade'.toUpperCase()) {
                history.push('/broker/instructions/flatTrade');
              } else if (name.toUpperCase() === 'Dhan'.toUpperCase()) {
                history.push('/broker/instructions/dhan');
              } else if (name.toUpperCase() === 'Fyers'.toUpperCase()) {
                history.push('/broker/instructions/fyres');
              }
            }}
          >
            Learn More
          </button>
        ) : (
          <>
            <div className="d-flex gap-2 position-relative w-100">
              <button
                className="button"
                onClick={() => {
                  getBroker(id, name);
                  showModal();
                }}
              >
                Connect
              </button>
              <Tooltip placement="top" title={name}>
                <Link
                  to={
                    name.toUpperCase() === 'Alice Blue'.toUpperCase()
                      ? '/broker/instructions/aliceBlue'
                      : name.toUpperCase() === 'Angle One'.toUpperCase()
                      ? '/broker/instructions/angelone'
                      : name.toUpperCase() === '5Paisa'.toUpperCase()
                      ? '/broker/instructions/paisa'
                      : name.toUpperCase() === 'Zerodha'.toUpperCase()
                      ? '/broker/instructions/zerodha'
                      : name.toUpperCase() === 'Flat Trade'.toUpperCase()
                      ? '/broker/instructions/flatTrade'
                      : name.toUpperCase() === 'Dhan'.toUpperCase()
                      ? '/broker/instructions/dhan'
                      : name.toUpperCase() === 'Fyers'.toUpperCase()
                      ? '/broker/instructions/fyres'
                      : ''
                  }
                  className="position-absolute topPx-7 fsize-12 text-muted ms-3 fweight-4 hoverLink textBColor"
                  style={{ right: '-25px' }}
                >
                  <BsInfoCircle />
                </Link>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BrokerCard;
