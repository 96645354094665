import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import angelOne1 from "../../../../assets/images/angelOne1.png";
import angelOne2 from "../../../../assets/images/angelOne2.png";
import angelOne3 from "../../../../assets/images/angelOne3.png";
import angelOne4 from "../../../../assets/images/angelOne4.png";
import angelOne5 from "../../../../assets/images/angelOne5.png";
import Header from "../../Header";

function AngelInstructions() {
  document.querySelector("body").style.overflow = "hidden";
  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-Angelone-API-key"
                      role="tab"
                      aria-controls="v-pills-Angelone-API-key"
                      aria-selected="true"
                    >
                      Steps to get Angelone API key
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-Token"
                      role="tab"
                      aria-controls="v-pills-Token"
                      aria-selected="false"
                    >
                      Token Generation Steps
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex ">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-Angelone-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get Angelone API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p>Log in to your account on our website.</p>
                            <p>
                              Url :{" "}
                              <a
                                href="https://smartapi.angelbroking.com/signin"
                                target="_blank"
                              >
                                angelbroking
                              </a>
                            </p>
                            <div className="mt-4 mb-4">
                              <img
                                src={angelOne1}
                                alt="Login Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Move your cursor to the My Profile section and click
                            on the Create an app option.
                            <div className="mt-4 mb-4">
                              <img
                                src={angelOne2}
                                alt="profile Section Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            This will take you to a form, where you can select
                            the type of API you want to set up.
                            <div className="mt-4 mb-4">
                              <img
                                src={angelOne3}
                                alt="create app Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Once you have selected the type, you can simply give
                            a name to your API, and enter the redirect and
                            postback URL.
                            <div className="mt-4 mb-4">
                              <img
                                src={angelOne4}
                                alt="API Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            After entering the details, click the Create App
                            button.
                            <div className="mt-4 mb-4">
                              <img
                                src={angelOne5}
                                alt="Key Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-Token"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="mb-4">
                      <h4>Token Generation Steps </h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p>Open the Authorization URL</p>
                            <p>
                              URL=
                              <a href="https://smartapi.angelbroking.com/publisher-login?api_key=xxx">
                                Angelbroking
                              </a>
                            </p>
                          </li>
                          <li>
                            <p>
                              Enter your clientcode, Password,TOTP and submit
                            </p>
                          </li>
                          <li>
                            <p>
                              After successful login, user gets redirected to
                              the URL specified under MyApps. With the URL we
                              pass auth_token & feed_token as query parameters.
                            </p>
                            <p>
                              Example:
                              <a href="https://www.google.co.in/?auth_token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IlQxOTA5OTAiLCJyb2xlcyI6MCwidXNlcnR5cGUiOiJVU0VSIiwiaWF0IjoxNjcxNjIxMzg0LCJleHAiOjE3NTgwMjEzODR9.DL2lf6PxB8Blx4ehh2-vkmfTJNqwbf6AslgUCIgsMsY5jQPloEV75pZTu_CtQLsrZeJWHbq3hJ64Luc7sMMXQg&feed_token=0890470832&refresh_token=eyJhbGciOiJIUzUxMiJ9.eyJ0b2tlbiI6IlJFRlJFU0gtVE9LRU4iLCJpYXQiOjE2NzE2MjEzODR9.p9niOmygrjBa_Q5AatDAT4J3JKT0y6droetNkCo0ypDHUAfDPaMSgaPlfjo_D6McaBrAHT3TTmmbzWL-mol7lg">
                                https://www.google.co.in/?auth_token=Token&feed_token=feed_token
                              </a>
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AngelInstructions;
