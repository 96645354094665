import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library';
import Datafeed from '../../datafeed';
import moment from 'moment';
import { chartSocket } from '../../context/socket';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: 'NSE:NIFTY BANK',
    interval: '1',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    has_intraday: true,
    timeframe: '1D',
    enabled_features: ['custom_resolutions', 'show_spread_operators'],
    time_frames: [
      //   { text: '6m', resolution: '1D', description: '3 Years' },
      //   { text: '8m', resolution: '1D', description: '8 Month' },
      { text: '3y', resolution: '1D', description: 'All', title: 'All' },

      { text: '3d', resolution: '1D', description: '3 Days' },
    ],
  };

  tvWidget = null;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const widgetOptions = {
      symbol: this.props.symbol,
      datafeed: Datafeed,
      interval: this.props.interval,
      timezone: 'Asia/Kolkata',
      container: this.ref.current,
      library_path: this.props.libraryPath,
      session: '0915-1530',
      period: 'D',
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      enabled_features: this.props.enabled_features,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      study_count_limit: 5,
      timeframe: this.props.timeframe,
      time_frames: this.props.time_frames,
      showSymbolLogo: false,
      hide_left_toolbar_by_default: true,

      // time_scale: {
      //   min_bar_spacing: 20,
      // },
      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: 'Equity',
            metainfo: {
              _metainfoVersion: 51,
              id: 'Equity@tv-basicstudies-1',
              description: 'Equity',
              shortDescription: 'Equity',
              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: 'price',
                // Precision is set to one digit, e.g. 777.7
                precision: 1,
              },

              plots: [{ id: 'plot_0', type: 'line' }],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,

                    // Make the line thinner
                    linewidth: 1,

                    // Plot type is Line
                    plottype: 2,

                    // Show price line
                    trackPrice: true,

                    // Set the plotted line color to dark red
                    color: '#880000',
                  },
                },

                inputs: {},
              },
              styles: {
                plot_0: {
                  // Output name will be displayed in the Style window
                  title: 'Equity value',
                  histogramBase: 0,
                },
              },
              inputs: [],
            },

            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                var symbol = '#EQUITY';
                console.log(PineJS.Std.period(this._context));
                this._context.new_sym(symbol, 'D');
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                var v = PineJS.Std.close(this._context);
                return [v];
              };
            },
          },
          {
            name: 'Gann Angles',
            metainfo: {
              _metainfoVersion: 51,
              id: 'GannAngles@tv-basicstudies-1',
              scriptIdPart: '',
              description: 'Gann Angles',
              shortDescription: 'Gann Angles',
              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              plots: [
                {
                  id: 'plot_0',
                  type: 'line',
                },
                {
                  id: 'plot_1',
                  type: 'line',
                },
                {
                  id: 'plot_2',
                  type: 'line',
                },
                {
                  id: 'plot_3',
                  type: 'chars',
                },
                {
                  id: 'plot_4',
                  type: 'chars',
                },

                {
                  id: 'plot_5',
                  type: 'line',
                },
                {
                  id: 'plot_6',
                  type: 'line',
                },
                {
                  id: 'plot_7',
                  type: 'line',
                },
                {
                  id: 'plot_8',
                  type: 'line',
                },
                {
                  id: 'plot_9',
                  type: 'line',
                },
                {
                  id: 'plot_10',
                  type: 'line',
                },
                {
                  id: 'plot_11',
                  type: 'line',
                },
                {
                  id: 'plot_12',
                  type: 'line',
                },
                {
                  id: 'plot_13',
                  type: 'line',
                },
                {
                  id: 'plot_14',
                  type: 'line',
                },
                {
                  id: 'plot_15',
                  type: 'line',
                },
                {
                  id: 'plot_16',
                  type: 'line',
                },
                {
                  id: 'plot_17',
                  type: 'shapes',
                },
                {
                  id: 'plot_18',
                  type: 'shapes',
                },
                {
                  id: 'plot_19',
                  type: 'shapes',
                },
                {
                  id: 'plot_20',
                  type: 'shapes',
                },
                {
                  id: 'plot_21',
                  type: 'shapes',
                },
                {
                  id: 'plot_22',
                  type: 'shapes',
                },
                {
                  id: 'plot_23',
                  type: 'shapes',
                },
                {
                  id: 'plot_24',
                  type: 'shapes',
                },
                {
                  id: 'plot_25',
                  type: 'shapes',
                },
                {
                  id: 'plot_26',
                  type: 'shapes',
                },
                {
                  id: 'plot_27',
                  type: 'shapes',
                },
                {
                  id: 'plot_28',
                  type: 'shapes',
                },
                {
                  id: 'plot_29',
                  type: 'shapes',
                },
                {
                  id: 'plot_30',
                  type: 'shapes',
                },
              ],
              palettes: {
                paletteId1: {
                  colors: {
                    0: {
                      name: 'First color',
                    },
                    1: {
                      name: 'Second color',
                    },
                    2: {
                      name: 'Third color',
                    },
                    3: {
                      name: 'fourth color',
                    },
                    4: {
                      name: 'Fifth color',
                    },
                    5: {
                      name: 'sixth color',
                    },
                    6: {
                      name: 'seventh color',
                    },
                    7: {
                      name: 'eighth color',
                    },
                    8: {
                      name: 'nineth color',
                    },
                    9: {
                      name: 'Tenth color',
                    },
                    10: {
                      name: 'eleventh color',
                    },
                    11: {
                      name: 'twelveth color',
                    },
                    12: {
                      name: 'thirteenth color',
                    },
                    13: {
                      name: 'fourteen color',
                    },
                    14: {
                      name: 'fifteen color',
                    },
                    15: {
                      name: 'sixteen color',
                    },
                    16: {
                      name: 'seventeen color',
                    },
                    17: {
                      name: 'eighteen color',
                    },
                    18: {
                      name: 'nineteen color',
                    },
                    19: {
                      name: 'twenty color',
                    },
                    20: {
                      name: 'twentyone color',
                    },
                    21: {
                      name: 'twentytwo color',
                    },
                    22: {
                      name: 'twentythree color',
                    },
                    23: {
                      name: 'twentyfour color',
                    },
                    24: {
                      name: 'twentyfive color',
                    },

                    25: {
                      name: 'twentysix color',
                    },
                    26: {
                      name: 'twentyseven color',
                    },
                    27: {
                      name: 'twentyeight color',
                    },
                    28: {
                      name: 'twentynine color',
                    },
                    29: {
                      name: 'thirty color',
                    },
                    30: {
                      name: 'thirtyone color',
                    },
                  },
                },
              },
              defaults: {
                palettes: {
                  paletteId1: {
                    colors: {
                      0: {
                        color: 'blue',
                        width: 2,
                        style: 0,
                      },
                      1: {
                        color: 'green',
                        width: 2,
                        style: 1,
                      },
                      2: {
                        color: 'red',
                        width: 2,
                        style: 1,
                      },
                      3: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      4: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      5: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      6: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      7: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      8: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      9: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      10: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      11: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      12: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      13: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      14: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      15: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      16: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      17: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      18: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      19: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      20: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      21: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      22: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      23: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      24: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      25: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      26: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      27: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      28: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                      29: {
                        color: 'green',
                        width: 1,
                        style: 1,
                      },
                      30: {
                        color: 'red',
                        width: 1,
                        style: 1,
                      },
                    },
                  },
                },
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    trackPrice: true,
                  },
                },
                precision: 4,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: 'Blue line',
                  histogramBase: 0,
                  color: 'blue',
                },
                plot_1: {
                  title: 'Green line',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_2: {
                  title: 'Red line',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_3: {
                  title: 'LongStepSize',
                  color: 'green',
                  textColor: 'green',
                  char: '',
                  location: 'Top',
                  offset: 20,
                },
                plot_4: {
                  title: 'ShortStepSize',
                  color: 'red',
                  textColor: 'red',
                  char: '',
                  location: 'Top',
                  offset: 20,
                },
                plot_5: {
                  title: 'LONG T1',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_6: {
                  title: 'LONG T2',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_7: {
                  title: 'LONG T3',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_8: {
                  title: 'LONG T4',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_9: {
                  title: 'LONG T5',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_10: {
                  title: 'LONG T6',
                  histogramBase: 0,
                  color: 'green',
                },
                plot_11: {
                  title: 'SHORT T1',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_12: {
                  title: 'SHORT T2',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_13: {
                  title: 'SHORT T3',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_14: {
                  title: 'SHORT T4',
                  histogramBase: 0,
                  color: 'red',
                  size: 'large',
                },
                plot_15: {
                  title: 'SHORT T5',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_16: {
                  title: 'SHORT T6',
                  histogramBase: 0,
                  color: 'red',
                },
                plot_17: {
                  isHidden: false,
                  location: 'Absolute',
                  plottype: 'shape_circle',
                  size: 'tiny',
                  text: 'GBase',
                  title: 'Label GreenLine',
                  color: 'green',
                  textColor: 'green',
                  visible: false,
                  offset: 20,
                },
                plot_18: {
                  isHidden: false,
                  title: 'Label RedLine',
                  plottype: 'shape_circle',
                  color: 'red',
                  size: 'tiny',
                  text: 'RBase',
                  location: 'Absolute',
                  textColor: 'red',
                  visible: false,
                  offset: 20,
                },
                plot_19: {
                  isHidden: false,

                  title: 'Label LT1',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T1',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: true,
                  offset: 20,
                },
                plot_20: {
                  isHidden: false,

                  title: 'Label LT2',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T2',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: false,
                  offset: 20,
                },
                plot_21: {
                  isHidden: false,

                  title: 'Label LT3',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T3',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: true,
                  offset: 20,
                },
                plot_22: {
                  isHidden: false,

                  title: 'Label LT4',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T4',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: false,
                  offset: 20,
                },
                plot_23: {
                  isHidden: false,
                  title: 'Label LT5',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T5',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: true,
                  offset: 20,
                },
                plot_24: {
                  isHidden: false,

                  title: 'Label LT6',
                  plottype: 'shape_circle',
                  color: 'green',
                  size: 'tiny',
                  text: 'T6',
                  location: 'Absolute',
                  textColor: 'green',
                  visible: false,
                  offset: 20,
                },
                plot_25: {
                  isHidden: false,

                  title: 'Label ST1',
                  plottype: 'shape_circle',
                  color: 'red',
                  size: 'tiny',
                  text: 'T1',
                  location: 'Absolute',
                  textColor: 'red',
                  visible: true,
                  offset: 20,
                },
                plot_26: {
                  isHidden: false,

                  title: 'Label ST2',
                  plottype: 'shape_circle',
                  color: 'red',
                  size: 'tiny',
                  text: 'T2',
                  textColor: 'red',

                  location: 'Absolute',
                  visible: false,
                  offset: 20,
                },
                plot_27: {
                  isHidden: false,
                  title: 'Label ST3',
                  plottype: 'shape_circle',
                  color: 'red',
                  size: 'tiny',
                  text: 'T3',
                  location: 'Absolute',
                  textColor: 'red',
                  visible: true,
                  offset: 20,
                },
                plot_28: {
                  isHidden: false,
                  title: 'Label ST4',
                  plottype: 'shape_circle',
                  color: 'red',
                  textColor: 'red',
                  size: 'tiny',
                  text: 'T4',
                  location: 'Absolute',
                  visible: false,
                  offset: 20,
                },
                plot_29: {
                  isHidden: false,
                  title: 'Label ST5',
                  plottype: 'shape_circle',
                  color: 'red',
                  textColor: 'red',
                  size: 'tiny',
                  text: 'T5',
                  location: 'Absolute',
                  visible: true,
                  offset: 20,
                },
                plot_30: {
                  isHidden: false,
                  title: 'Label ST6',
                  plottype: 'shape_circle',
                  color: 'red',
                  textColor: 'red',
                  size: 'tiny',
                  text: 'T6',
                  location: 'Absolute',
                  visible: false,
                  offset: 20,
                },
              },
              inputs: [],
              format: {
                type: 'price',
                precision: 4,
              },
            },
            constructor: function () {
              var points = [0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1.0];
              var angles = [180, 135, 90, 45, 45, 315, 270, 225];
              var val_count = 0;
              var session_open = 0;
              var Green_line = 0;
              var red_line = 0;
              var long_step_size = 0;
              var short_step_size = 0;
              var short_sl = 0.0;
              var long_sl = 0.0;
              var values_list = [];
              var values = [];
              var values_angle = [];
              var values_quadrant = [];
              var values_index = [];
              var long_targets = [];
              var short_targets = [];
              var symbol = '';

              function func_find_targets(
                target_array,
                _entry_price,
                diff_price,
                n_targets = 7,
              ) {
                let entry_price = _entry_price;
                for (let i = 1; i < n_targets; i++) {
                  entry_price = PineJS.Std.round(entry_price + diff_price, 2);
                  target_array.push(entry_price);
                }
                return target_array;
              }

              function CalculateValues(session_open) {
                var base_points = [
                  parseInt(Math.sqrt(session_open)) - 1,
                  parseInt(Math.sqrt(session_open)),
                  parseInt(Math.sqrt(session_open)) + 1,
                ];
                //console.log(base_points);

                for (let i = 0; i < base_points.length; i++) {
                  if (i === 0) {
                    values.push(Math.pow(base_points[i], 2));
                    values_angle.push(NaN);
                    values_quadrant.push(NaN);
                    values_index.push(NaN);
                    values_list.push(Math.pow(base_points[i], 2));
                  }

                  for (let j = 0; j < points.length; j++) {
                    values.push(
                      PineJS.Std.round(
                        Math.pow(base_points[i] + points[j], 2),
                        2,
                      ),
                    );
                    values_angle.push(angles[j]);
                    values_quadrant.push(i);
                    values_index.push(val_count);
                    values_list.push(
                      PineJS.Std.round(
                        Math.pow(base_points[i] + points[j], 2),
                        2,
                      ),
                    );
                    val_count++;
                  }
                }
                // console.log(
                //   values,
                //   values_quadrant,
                //   values_index,
                //   values_list,
                // );

                for (let i = 0; i < values_list.length; i++) {
                  if (i >= 1) {
                    if (
                      values_list[i - 1] <= session_open &&
                      session_open < values_list[i]
                    ) {
                      var buy_value_index = values_index[i];
                      var sell_value_index = values_index[i - 1];
                      var buy_t1 = 0;
                      var buy_t2 = 0;

                      for (
                        let value_idx = 0;
                        value_idx < values.length;
                        value_idx++
                      ) {
                        if (value_idx >= buy_value_index + 1) {
                          if (
                            values_angle[value_idx] !== 0 ||
                            values_angle[value_idx] !== 90 ||
                            values_angle[value_idx] !== 180 ||
                            values_angle[value_idx] !== 270
                          ) {
                            buy_t1 = values[value_idx];
                            buy_t2 =
                              values[
                                PineJS.Std.round(values_index[value_idx] + 3)
                              ];
                            break;
                          }
                        }
                      }

                      let buy_diff = PineJS.Std.round(buy_t2 - buy_t1, 2);
                      let sell_t1 = 0;
                      let sell_t2 = 0;

                      let copy_values = values.slice(
                        0,
                        parseInt(sell_value_index + 2),
                      );
                      let copy_values_angle = values_angle.slice(
                        0,
                        parseInt(sell_value_index + 2),
                      );
                      let copy_values_index = values_index.slice(
                        0,
                        parseInt(sell_value_index + 2),
                      );

                      copy_values.reverse();
                      copy_values_angle.reverse();
                      copy_values_index.reverse();

                      // console.log(
                      //   copy_values,
                      //   copy_values_angle,
                      //   copy_values_index,
                      // );

                      for (
                        let value_idx = 0;
                        value_idx < copy_values.length;
                        value_idx++
                      ) {
                        if (
                          copy_values_angle[value_idx] !== 0 ||
                          copy_values_angle[value_idx] !== 90 ||
                          copy_values_angle[value_idx] !== 180 ||
                          copy_values_angle[value_idx] !== 270
                        ) {
                          sell_t1 = copy_values[value_idx];
                          sell_t2 =
                            values[
                              PineJS.Std.round(
                                copy_values_index[value_idx] + 1 - 2,
                              )
                            ];
                          break;
                        }
                      }

                      let sell_diff = PineJS.Std.round(sell_t2 - sell_t1, 2);
                      Green_line = session_open + buy_diff;
                      red_line = session_open + sell_diff;

                      //find targets
                      long_targets = func_find_targets(
                        long_targets,
                        Green_line,
                        buy_diff,
                      );
                      short_targets = func_find_targets(
                        short_targets,
                        red_line,
                        sell_diff,
                      );

                      long_step_size = buy_diff;
                      short_step_size = sell_diff;
                      short_sl = session_open;
                      long_sl = session_open;
                      break;
                    }
                  }
                }
              }

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                // console.log(this._input);
                console.log(this._context);

                // console.log(PineJS);

                if (symbol !== this._context.symbol.ticker.split(':')[1]) {
                  session_open = 0;
                  val_count = 0;
                  Green_line = 0;
                  red_line = 0;
                  long_step_size = 0;
                  short_step_size = 0;
                  short_sl = 0.0;
                  long_sl = 0.0;
                  values_list = [];
                  values = [];
                  values_angle = [];
                  values_quadrant = [];
                  values_index = [];
                  long_targets = [];
                  short_targets = [];
                }

                let time = moment(this._context.symbol.time).format('hh:mm a');

                // console.log(
                //   moment(this._context.symbol.time).format('DD-MM-YYYY hh-mm'),
                //   //moment('9:15am', 'h:mma'),
                //   moment(time, 'h:mma').isAfter(moment('9:15am', 'h:mma')),
                // );
                if (
                  session_open === 0 &&
                  moment(time, 'h:mma').isAfter(moment('9:15am', 'h:mma'))
                ) {
                  session_open = this._context.symbol.open;
                  CalculateValues(session_open);
                }
                var start_calculation = false;

                if (
                  moment(this._context.symbol.time).format('hh-mm') === '09-15'
                ) {
                  session_open = this._context.symbol.open;
                  start_calculation = true;

                  val_count = 0;
                  Green_line = 0;
                  red_line = 0;
                  long_step_size = 0;
                  short_step_size = 0;
                  short_sl = 0.0;
                  long_sl = 0.0;
                  values_list = [];
                  values = [];
                  values_angle = [];
                  values_quadrant = [];
                  values_index = [];
                  long_targets = [];
                  short_targets = [];
                }

                if (start_calculation) {
                  CalculateValues(session_open);
                }
                // moment(this._context.symbol.time).format('hh-mm') === '09-15'
                //   ? (value = this._context.symbol.open + 500)
                //   : (value = this._context.symbol.open);

                //  Green_line = value + 100;
                //  red_line = value - 100;
                var long_target_len = long_targets.length;
                var short_target_len = short_targets.length;
                // console.log(
                //   session_open,
                //   Green_line,
                //   red_line,
                //   long_targets,
                //   short_targets,
                //   long_step_size,
                //   short_step_size,
                // );
                symbol = this._context.symbol.ticker.split(':')[1];

                return [
                  session_open,
                  Green_line,
                  red_line,
                  long_step_size,
                  short_step_size,
                  long_target_len >= 1 ? long_targets[0] : NaN,
                  long_target_len >= 1 ? long_targets[1] : NaN,
                  long_target_len >= 1 ? long_targets[2] : NaN,
                  long_target_len >= 1 ? long_targets[3] : NaN,
                  long_target_len >= 1 ? long_targets[4] : NaN,
                  long_target_len >= 1 ? long_targets[5] : NaN,
                  short_target_len >= 1 ? short_targets[0] : NaN,
                  short_target_len >= 1 ? short_targets[1] : NaN,
                  short_target_len >= 1 ? short_targets[2] : NaN,
                  short_target_len >= 1 ? short_targets[3] : NaN,
                  short_target_len >= 1 ? short_targets[4] : NaN,
                  short_target_len >= 1 ? short_targets[5] : NaN,
                  start_calculation ? Green_line : NaN,
                  start_calculation ? red_line : NaN,
                  start_calculation ? long_targets[0] : NaN,
                  start_calculation ? long_targets[1] : NaN,
                  start_calculation ? long_targets[2] : NaN,
                  start_calculation ? long_targets[3] : NaN,
                  start_calculation ? long_targets[4] : NaN,
                  start_calculation ? long_targets[5] : NaN,
                  start_calculation ? short_targets[0] : NaN,
                  start_calculation ? short_targets[1] : NaN,
                  start_calculation ? short_targets[2] : NaN,
                  start_calculation ? short_targets[3] : NaN,
                  start_calculation ? short_targets[4] : NaN,
                  start_calculation ? short_targets[5] : NaN,
                ];
              };
            },
          },
        ]);
      },
    };

    const tvWidget = new widget(widgetOptions);

    // console.log(widget);
    //console.log(tvWidget);
    //tvWidget.setInterval(15, 'min');
    this.tvWidget = tvWidget;
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    // tvWidget.onChartReady(() => {
    // 	tvWidget.headerReady().then(() => {
    // 		const button = tvWidget.createButton();
    // 		button.setAttribute('title', 'Click to show a notification popup');
    // 		button.classList.add('apply-common-tooltip');
    // 		button.addEventListener('click', () => tvWidget.showNoticeDialog({
    // 			title: 'Notification',
    // 			body: 'TradingView Charting Library API works correctly',
    // 			callback: () => {
    // 				console.log('Noticed!');
    // 			},
    // 		}));

    // 		// button.innerHTML = 'Check API';
    // 	});
    // });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
    chartSocket.disconnect();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    chartSocket.disconnect();
    // event.returnValue = 'Are you sure you want to leave?';
  };

  render() {
    return <div ref={this.ref} className={'TVChartContainer'} />;
  }
}
