import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BrockerCard from '../../../../utils/BrokerCard';
import Header from '../../Header';
import Skeleton from '@mui/material/Skeleton';
import config from '../../../../Config/config';

function SetupInstruction() {
  const [Brokers, setBrokers] = useState([]);
  let tokenExist = localStorage.getItem('token');

  const getBrokerList = () => {
    //console.log("here");
    axios
      .get(`${config.base_url}${config.Brokers}`, {
        headers: { Authorization: `Bearer ${tokenExist}` },
      })
      .then((res) => {
        //console.log("Response Data", res);
        setBrokers(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    getBrokerList();
  }, []);

  return (
    <>
      <Header />
      <div className="mt-5">
        <Container>
          <Row className="mb-4">
            <Col>
              <h5 className="text-muted text-capitalize">Setup Instructions</h5>
            </Col>
          </Row>
        </Container>
        {Brokers && Brokers.length > 0 ? (
          <section className="mbPx-100">
            <Container>
              <Row
                className="row-cols-2 row-cols-md-3 row-cols-xl-5 row-col
              s-xxl-6"
              >
                {Brokers.map((el) => {
                  return (
                    <Col key={el._id} className="mb-3">
                      <BrockerCard
                        name={el.name}
                        description={el.description}
                        image={el.logo_img}
                        //showModal={showModal}
                        //getBroker={getBroker}
                        instruct={true}
                        id={el._id}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
        ) : (
          <section className="mbPx-100">
            <Container>
              <Row className="row-cols-2 row-cols-md-3 row-cols-xl-5 row-cols-xxl-6">
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </div>
    </>
  );
}

export default SetupInstruction;
