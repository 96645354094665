import React, { useEffect, useState } from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function PortfolioDataCards({
  toggleMore,
  toggleShow,
  overAllPnL,
  totalInvestment,
  tableDate,
  // totalBrokerage,
  // todayPnL,
  // totalTrades,
  // profitTrades,
  // lossTrades,
}) {
  const [selectedDateRange, setSelectedDateRange] = useState('');
  useEffect(() => {
    if (
      tableDate[0] !== '' &&
      tableDate[1] !== '' &&
      tableDate[0] !== undefined &&
      tableDate[1] !== undefined
    ) {
      setSelectedDateRange(
        tableDate
          .map((dateString) => dayjs(dateString).format('DD/MM/YY'))
          .join('-'),
      );
    } else {
      setSelectedDateRange('Today');
    }
  }, [tableDate]);
  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-2 my-lg-3 ">
        {toggleMore === 0 ? (
          <span className="show_more" onClick={() => toggleShow(1)}>
            Show Less{' '}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 256 256"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
            </svg>
          </span>
        ) : (
          <span className="show_more" onClick={() => toggleShow(0)}>
            Show More{' '}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 256 256"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
            </svg>
          </span>
        )}
      </div>
      <div className="row portfolio__list__card mt-0 rounded p-2 p-lg-3">
        <div className="col sep__line mob rem__line__tp">
          <div>
            <h3>Overall P&L</h3>
            {/* <span>30 Sept 2023</span> */}
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div
              className={
                overAllPnL?.totalPnl < 0
                  ? `me-2 primary__red`
                  : `me-2 primary__green`
              }
            >
              {overAllPnL?.totalPnl < 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  />
                </svg>
              ) : overAllPnL?.totalPnl > 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              ) : null}
            </div>

            <div
              className={
                overAllPnL?.totalPnl < 0
                  ? `primary__red fw-semibold`
                  : `primary__green fw-semibold`
              }
            >
              {overAllPnL?.totalPnl}
            </div>
          </div>
        </div>
        <div className="col sep__line mob rem_ln rem__line__tp">
          <div>
            <h3>Overall Brokerage</h3>
            {/* <span>26 Sept 2023</span> */}
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            {/* <div className="fw-semibold">{totalBrokerage}</div> */}
            <div className="fw-semibold">{overAllPnL?.totalBrokerage}</div>
          </div>
        </div>

        <div className="col sep__line mob rem__line__tp">
          <div>
            <h3>Total Investment</h3>
            <span class="badge__blue me-2 text-white px-2 py-1" style={{fontSize:'11px'}}>Today</span>
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div className="primary__red fw-semibold">
              {totalInvestment.toFixed(2)}
            </div>
          </div>
        </div>
        <div className="col sep__line mob rem_ln rem__line__tp">
          <div>
            <h3>Total Brokerage</h3>
            <span class="badge__blue me-2 text-white px-2 py-1" style={{fontSize:'11px'}}>{selectedDateRange}</span>
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            {/* <div className="fw-semibold">{totalBrokerage}</div> */}
            <div className="fw-semibold">{overAllPnL?.filterBrokerage}</div>
          </div>
        </div>
        <div className="col sep__line mob mob__hide">
          <div>
            <h3>Total P&L</h3>
            <span class="badge__blue me-2 text-white px-2 py-1" style={{fontSize:'11px'}}>{selectedDateRange}</span>
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div
              className={
                overAllPnL?.filterPnl < 0
                  ? `me-2 primary__red`
                  : `me-2 primary__green`
              }
            >
              {overAllPnL?.filterPnl < 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  />
                </svg>
              ) : overAllPnL?.filterPnl > 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              ) : null}
            </div>
            <div
              className={
                overAllPnL?.filterPnl < 0
                  ? `primary__red fw-semibold`
                  : `primary__green fw-semibold`
              }
            >
              {overAllPnL?.filterPnl}
            </div>
          </div>
        </div>
        <div className="col sep__line mob mob__hide">
          <div>
            <h3>Trades</h3>
            {/* <span>26 Sept 2023</span> */}
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div className="fw-semibold">{overAllPnL?.totalTrades}</div>
          </div>
        </div>
        <div className="col sep__line mob rem_ln mob__hide">
          <div>
            <h3>Profit Trades</h3>
            {/* <span>26 Sept 2023</span> */}
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div className="primary__green fw-semibold">
              <BsArrowUp /> {overAllPnL?.profitTrade}
            </div>
          </div>
        </div>
        <div className="col sep__line__last mob__hide">
          <div>
            <h3>Loss Trades</h3>
            {/* <span>26 Sept 2023</span> */}
          </div>
          <div className="d-flex d-flex align-items-center mt-2">
            <div className="primary__red fw-semibold">
              <BsArrowDown />
              {overAllPnL?.lossTrade}
            </div>
          </div>
        </div>

        {toggleMore === 0 ? (
          <div className="mob_sect">
            <div className="d-flex flex-column">
              <div className="col sep__line mob">
                <div>
                  <h3>Total P&L</h3>
                  <span class="badge__blue me-2 text-white px-2 py-1" style={{fontSize:'11px'}}>{selectedDateRange}</span>
                </div>
                <div className="d-flex d-flex align-items-center mt-2">
                  <div
                    className={
                      overAllPnL?.filterPnl < 0
                        ? `me-2 primary__red`
                        : `me-2 primary__green`
                    }
                  >
                    {overAllPnL?.filterPnl < 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    ) : overAllPnL?.filterPnl > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    ) : null}
                  </div>
                  <div
                    className={
                      overAllPnL?.filterPnl < 0
                        ? `primary__red fw-semibold`
                        : `primary__green fw-semibold`
                    }
                  >
                    {overAllPnL?.filterPnl}
                  </div>
                </div>
              </div>
              <div className="col sep__line mob">
                <div>
                  <h3>Trades</h3>
                  {/* <span>26 Sept 2023</span> */}
                </div>
                <div className="d-flex d-flex align-items-center mt-2">
                  <div className="fw-semibold">{overAllPnL?.totalTrades}</div>
                </div>
              </div>
              <div className="col sep__line mob rem_ln">
                <div>
                  <h3>Profit Trades</h3>
                  {/* <span>26 Sept 2023</span> */}
                </div>
                <div className="d-flex d-flex align-items-center mt-2">
                  <div className="primary__green fw-semibold">
                    <BsArrowUp />
                    {overAllPnL?.profitTrade}
                  </div>
                </div>
              </div>
              <div className="col sep__line__last mob">
                <div>
                  <h3>Loss Trades</h3>
                  {/* <span>26 Sept 2023</span> */}
                </div>
                <div className="d-flex d-flex align-items-center mt-2">
                  <div className="primary__red fw-semibold">
                    <BsArrowDown />
                    {overAllPnL?.lossTrade}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default React.memo(PortfolioDataCards);
