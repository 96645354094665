import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import alice1 from "../../../../assets/images/alice1.png";
import alice1a from "../../../../assets/images/alice1a.png";
import alice2 from "../../../../assets/images/alice2.png";
import Header from "../../Header";

function AliceInstructions() {
  document.querySelector("body").style.overflow = "hidden";
  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>
                  <div
                    class="nav flex-column nav-pills navIsehd"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-Aliceblue-API-key"
                      role="tab"
                      aria-controls="v-pills-Aliceblue-API-key"
                      aria-selected="true"
                    >
                      Steps to get Aliceblue API key
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-Token"
                      role="tab"
                      aria-controls="v-pills-Token"
                      aria-selected="false"
                    >
                      Token Generation Steps
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex ">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-Aliceblue-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get Aliceblue API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p className="mb-2">
                              Login to the Aliceblue ANT web application.
                            </p>
                            <p>
                              Url :{" "}
                              <a
                                href="https://ant.aliceblueonline.com/"
                                target="_blank"
                              >
                                Aliceblue ANT web application
                              </a>
                            </p>
                            <div className="mt-4 mb-4">
                              <img
                                src={alice1}
                                alt="login Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                            <div className="mt-4 mb-4">
                              <img
                                src={alice1a}
                                alt="login Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>Click on Create App.</li>
                          <li>
                            <p>
                              Navigate to APPS {"-->"} API key. Users can
                              generate API key via the below button.
                            </p>
                            <p>
                              Users will be asked to accept the 'Terms and
                              Conditions'.
                            </p>
                            <p>
                              After accepting the 'Terms and Conditions', the
                              generated API key will be sent to the User's
                              registered mail.
                            </p>
                            <div className="mt-4 mb-4">
                              <img
                                src={alice2}
                                alt="API Key Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            For authentication and authorization workflow, use
                            that API key.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-Token"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="mb-4">
                      <h4>Token Generation Steps </h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            After receiving the encryption key, user has to
                            generate SHA-256 encrypted key to get User Session
                            ID.
                          </li>
                          <li>
                            To achieve this, the user has to encrypt the
                            combination of User iD, API key and Encryption Key.{" "}
                          </li>
                          <li>
                            Sending this to the API will provide a valid Session
                            ID.{" "}
                          </li>
                          <li>
                            Use the session ID in authorization header for all
                            further API calls
                          </li>
                          <li>
                            {" "}
                            If there are any issues found, like unauthorized
                            access or invalid session, user can re-generate
                            session id by using the same set of process
                            described above.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AliceInstructions;
