import React, { useState, useEffect } from 'react';
import { Tooltip, Space, DatePicker } from 'antd';
import Dropdown from 'react-bootstrap/Dropdown';
import dayjs from 'dayjs';
import ExitTradeApi from '../API/OrderExit';

const ReportTabs = ({
  setToggleState,
  toggleState,
  lastOrder,
  HandleDateChange,
  tab,
  brokerID,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { RangePicker } = DatePicker;
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  //Trade Exit Function Call
  const handleExitOrder = (status) => {
    if (status === 'order-exit') {
      setIsChecked(true);
      setTimeout(() => {
        setIsChecked(false);
      }, 500);
    }
    let data = [...lastOrder];
    let payload = {
      exchange: data[0]?.exchange,
      request_id: data[0]?.request_id,
      user_id: data[0]?.user_id,
      strategy_id: data[0]?.strategy_id,
      option_type: data[0]?.option_type,
      strike_price: data[0]?.strike_price,
      lot_size: data[0]?.lot_size,
      order_type: data[0]?.order_type,
      tradingsymbol: data[0]?.tradingsymbol,
      market_type: null,
      expiry: '',
      position_type: data[0]?.position_type,
      quantity_left: data[0]?.quantity,
      transaction_type: data[0]?.transaction_type,
      trade_action: data[0]?.trade_action
        ? data[0]?.trade_action
        : data[0]?.trade_type,
      trade_type: data[0]?.trade_action
        ? data[0]?.trade_action
        : data[0]?.trade_type,
    };

    if (status === 'day-exit') {
      payload.day_status = false;
    }

    if (tab === 'VT') {
      ExitTradeApi(payload, tab === 'LT' ? 'live' : 'virtual');
    } else {
      payload.credential_id = brokerID;
      ExitTradeApi(payload, tab === 'LT' ? 'live' : 'virtual');
    }
    // console.log(payload);
  };
  const dropDownCall = () => {
    if (toggleState === 'openOrder') {
      return <span>Open Order</span>;
    } else if (toggleState === 'closeOrder') {
      return <span>Closed Order</span>;
    } else if (toggleState === 'trades') {
      return <span>Trades</span>;
    } else if (toggleState === 'p&l') {
      return <span>PNL</span>;
    }
  };
  return (
    <>
      <div className="col-5 col-lg-6 ps-0">
        <ul className="d-flex strtgy__tbl">
          <li
            onClick={() => setToggleState('openOrder')}
            className={toggleState === 'openOrder' ? 'active' : 'tabs'}
          >
            <span>Open Order</span>
          </li>
          <li
            onClick={() => setToggleState('closeOrder')}
            className={toggleState === 'closeOrder' ? 'active' : 'tabs'}
          >
            <span>Closed Order</span>
          </li>
          <li
            onClick={() => setToggleState('trades')}
            className={toggleState === 'trades' ? 'active' : 'tabs'}
          >
            <span>Trades</span>
          </li>
          <li
            onClick={() => setToggleState('p&l')}
            className={toggleState === 'p&l' ? 'active' : 'tabs'}
          >
            <span>P&L</span>
          </li>
        </ul>
        <div className="strtgy__table__drpdwn">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              {dropDownCall()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setToggleState('openOrder')}
                className={toggleState === 'openOrder' ? 'active' : 'tabs'}
              >
                Open Order
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setToggleState('closeOrder')}
                className={toggleState === 'closeOrder' ? 'active' : 'tabs'}
              >
                Closed Order
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setToggleState('trades')}
                className={toggleState === 'trades' ? 'active' : 'tabs'}
              >
                Trades
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setToggleState('p&l')}
                className={toggleState === 'p&l' ? 'active' : 'tabs'}
              >
                PNL
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center pe-0">
        {toggleState === 'openOrder' && (
          <>
            <div
              style={{
                display: `${lastOrder.length === 0 ? 'none' : 'block'}`,
              }}
            >
              <div
                className={`d-flex btn_swipe_exit mx-2 ${
                  lastOrder.length === 0 ? 'opacity-50' : ''
                }`}
              >
                <div className="button r" id="button-1">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isChecked}
                    disabled={lastOrder.length === 0}
                    onChange={() => handleExitOrder('order-exit')}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: `${lastOrder.length === 0 ? 'none' : 'block'}`,
              }}
            >
              <button
                className="d-flex btn__exit mx-2 "
                onClick={() => handleExitOrder('day-exit')}
              >
                <Tooltip title="Kill Switch" placement="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-power"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg>
                </Tooltip>
              </button>
            </div>
          </>
        )}
        {toggleState === 'closeOrder' && (
          <div className="d-flex cstm__dt__pick ms-2">
            <Space direction="vertical">
              <RangePicker
                onChange={HandleDateChange}
                placeholder={['Today', '']}
                disabledDate={(current) => {
                  return dayjs().add(0, 'days') <= current;
                }}
              />
            </Space>
          </div>
        )}
        {toggleState === 'trades' && (
          <div className="d-flex cstm__dt__pick ms-2">
            <Space direction="vertical">
              <RangePicker
                onChange={HandleDateChange}
                placeholder={['Today', '']}
                disabledDate={(current) => {
                  return dayjs().add(0, 'days') <= current;
                }}
              />
            </Space>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ReportTabs);
