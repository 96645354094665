import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Form, Input, Modal, Switch } from 'antd';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import mydecodedTokenFunction from './decodetoken';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { EditableBttn } from '../assets/Icon';
// import { socket } from '../context/socket';
import config from '../Config/config';
import gmail from '../img/gmail.png';
import message from '../img/message.png';
import slack from '../img/slack.png';
import telegram from '../img/telegram.png';
import { Tabs, Card } from 'antd';

function UserProfile({ visible, invisible, menuTab }) {
  const { TabPane } = Tabs;
  let history = useHistory();
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  let currentPassword = secureLocalStorage.getItem('User_Details');
  const [tab, setTab] = useState('profile');
  const [auth, setAuth] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [drawVisible, setDrawVisible] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [activeLogs, setActivelogs] = useState();
  const [emailLogs, setEmailLogs] = useState();
  const [loading1, setloading1] = useState(false);
  const [authStatus, setAuthStatus] = useState();
  const [toggle, settoggle] = useState(false);
  const [payload, setpayload] = useState();
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [lastNameloader, setLastnameLoader] = useState(false);
  const [oldPassError, setOldpassError] = useState('');
  const [contact, setContact] = useState('');
  const [datas, setdatas] = useState({
    first_name: '',
    last_name: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [updateEmail, setUpdateEmail] = useState({
    old_email: '',
    new_email: '',
    user_id: userid.id,
  });
  const [updatedPassword, setUpdatedPassword] = useState({
    verify_password: '',
    old_password: '',
    new_password: '',
    user_id: userid.id,
  });
  const [profileUpdateLoading, setProfileUpdateLoading] = useState(false);
  const [alertConfigureModal, setAlertConfigureModal] = useState(false);

  const handleOpenModal = () => {
    setAlertConfigureModal(true);
  };

  const handleCloseModal = () => {
    setAlertConfigureModal(false);
  };
  const [activeTab, setActiveTab] = useState(null);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // const [channelId, setChannelId] = useState('');
  // const [alertType, setAlertType] = useState('');
  // const [status, setStatus] = useState(false);

  // // Event handler for form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault(); // Prevent the default form submission behavior
  //   // You can perform any necessary actions with the form data here
  //   console.log('Channel ID:', channelId);
  //   console.log('Alert Type:', alertType);
  //   console.log('Status:', status);
  //   setChannelId('');
  //   setAlertType('');
  //   setStatus(false);
  // };

  //console.log(payload);
  const token = localStorage.getItem('token');
  useEffect(() => {
    // Update the document title using the browser API
    setDrawVisible(visible);
  });

  //console.log(loading);

  useEffect(() => {
    getUser();
  }, []);

  //API to getUser by id

  const getUser = () => {
    axios
      .get(`${config.base_url}${config.user_by_id}/${userid.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log('Response Data', res.data);
        setUserData(res.data.data[0]);
        setdatas({
          first_name: res.data.data[0].first_name,
          last_name: res.data.data[0].last_name,
        });
        setActivelogs(res.data.data[0].activity_log);
        setEmailLogs(res.data.data[0].email_activity_log);
        setAuthStatus(res.data.data[0].auth_2fa);
        setLoading(true);
        if (res.data.data[0].auth_2fa === true) {
          setAuth('enable');
        } else {
          setAuth('Disabled');
        }
        setpayload({
          email: res.data.data[0].email,
          first_name: res.data.data[0].first_name,
          last_name: res.data.data[0].last_name,
          phone_no: res.data.data[0].phone_no,
          activity_log: res.data.data[0].activity_log,
          email_activity_log: res.data.data[0].email_activity_log,
          auth_2fa: res.data.data[0].auth_2fa,
        });
        if (
          res.data.data[0].first_name !== undefined &&
          res.data.data[0].last_name !== undefined
        ) {
          res.data.data[0].name
            ? localStorage.setItem(
                'name',
                JSON.stringify(res.data.data[0].name),
              )
            : localStorage.setItem(
                'name',
                JSON.stringify(
                  `${res.data.data[0].first_name} ${res.data.data[0].last_name}`,
                ),
              );
        } else {
          localStorage.setItem(
            'name',
            JSON.stringify(res.data.data[0].email[0]),
          );
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  //Function to get name
  //console.log(userData);

  const getname = () => {
    if (userData.first_name !== undefined && userData.last_name !== undefined) {
      return userData.name
        ? userData.name
        : `${userData.first_name} ${userData.last_name}`;
    } else {
      return userData.email[0];
    }
  };

  //API to update profile.

  const updateProfile = () => {
    //console.log("here");
    // console.log(activeLogs);
    // console.log(emailLogs);
    //console.log(authStatus);
    if (loading) {
      let payload = {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_no: userData.phone_no,
        activity_log: activeLogs,
        email_activity_log: emailLogs,
        auth_2fa: authStatus,
      };
      //console.log(payload);
      setButtonLoader(true);
      axios
        .put(`${config.base_url}${config.update_user}/${userid.id}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          settoggle(false);
          setButtonLoader(false);
          console.log('Updated Data', res.data);
          if (res.data.message === 'User data update successfully.') {
            return toast.success('updated successfully.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
        })
        .catch((error) => {
          setButtonLoader(false);
          console.log(error);
          return toast.error('something went wrong server error', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    }
  };

  // Function used to set Activelogs,EmailLogs & AuthStatus

  const onChange = (checked, label) => {
    //console.log(`switch to ${label} ${checked}`);
    loading1 ? setloading1(false) : setloading1(true);
    settoggle(true);

    if (label === 'activeLogs') {
      setActivelogs(checked);
    } else if (label === 'emailLogs') {
      setEmailLogs(checked);
    } else {
      //console.log(checked);
      if (checked === 'enable') {
        setAuthStatus(false);
      } else {
        setAuthStatus(true);
      }
    }
  };

  useEffect(() => {
    //console.log(loading);
    if (loading && toggle) {
      updateProfile();
    }
  }, [loading1]);

  // Debouncing Functionality

  //Function used to update the name

  const handleChangeName = (e) => {
    setdatas({ ...datas, [e.target.name]: e.target.value });
  };

  // API for updating the particular user.

  const updateData = () => {
    if (
      payload.first_name === datas.first_name &&
      payload.last_name === datas.last_name
    ) {
      return;
    }
    let temp = { ...payload, ...datas };
    console.log(temp);
    setProfileUpdateLoading(true);
    axios
      .put(`${config.base_url}${config.update_user}/${userid.id}`, temp, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getUser();
        setProfileUpdateLoading(false);
        if (res.data.message === 'User data update successfully.') {
          return toast.success('updated successfully.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          return toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setProfileUpdateLoading(false);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  //Change Email ID

  const onChangeEmail = (e) => {
    setUpdateEmail({ ...updateEmail, [e.target.name]: e.target.value });
  };

  // API for updating the particular user email id.

  const updateEmailID = () => {
    //console.log(updateEmail);

    if (updateEmail.new_email === '' || updateEmail.old_email === '') {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setButtonLoader(true);
    axios
      .post(`${config.base_url}${config.change_user_email}`, updateEmail, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        setButtonLoader(false);
        setIsEmailModalVisible(false);
        console.log('Updated Data', res.data);
        setTimeout(() => {
          history('/');
        }, 3000);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        setButtonLoader(false);
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  //Change Password

  const onChangePassword = (e) => {
    let list = { ...updatedPassword, [e.target.name]: e.target.value };
    CheckpasswordError(list);
    setUpdatedPassword({ ...updatedPassword, [e.target.name]: e.target.value });
  };

  const CheckpasswordError = (list) => {
    if (list.old_password !== currentPassword.password) {
      return setOldpassError('Invalid password');
    } else {
      setOldpassError('');
    }
  };

  // API for updating the particular user password.

  const updatedPass = () => {
    if (updatedPassword.new_password !== updatedPassword.verify_password) {
      return;
    }
    // console.log(updatedPassword);

    if (updatedPassword.old_password !== currentPassword.password) {
      return setOldpassError('Invalid password');
    } else {
      setOldpassError('');
    }

    if (
      updatedPassword.old_password === '' ||
      updatedPassword.new_password === ''
    ) {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

    var minMaxLength = /^[\s\S]{8,16}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/;

    if (
      minMaxLength.test(updatedPassword.new_password) &&
      upper.test(updatedPassword.new_password) &&
      lower.test(updatedPassword.new_password) &&
      number.test(updatedPassword.new_password)
    ) {
      setPasswordError('');
    } else {
      return setPasswordError(
        'Password Should contain atleast one Uppercase, Lowercase and Numeric',
      );
    }

    let payload = {
      old_password: updatedPassword.old_password,
      new_password: updatedPassword.new_password,
      user_id: updatedPassword.user_id,
    };

    setButtonLoader(true);

    axios
      .post(`${config.base_url}${config.change_user_pass}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        setButtonLoader(true);
        setIsModalVisible(false);
        secureLocalStorage.setItem('User_Details', {
          ...currentPassword,
          password: updatedPassword.new_password,
        });
        setTimeout(() => {
          history('/');
        }, 3000);
        console.log('Updated Data', res.data);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        setButtonLoader(true);
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const imageTabs = [
    {
      key: 'telegram',
      imageSrc: telegram,
      content: 'Content for Telegram',
    },
    {
      key: 'slack',
      imageSrc: slack,
      content: 'Content for Slack',
    },
    {
      key: 'gmail',
      imageSrc: gmail,
      content: 'Content for Email',
    },
    {
      key: 'message',
      imageSrc: message,
      content: 'Content for SMS',
    },
  ];

  return (
    <>
      <Drawer
        placement={'right'}
        width={'40%'}
        open={drawVisible}
        className="leftDrawers cstm"
        closable={false}
      >
        <div className={tab === 'profile' ? 'scrollingSv' : ''}>
          <div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="drawerHeader text-start">
                  <CloseOutlined
                    className="closeIcon"
                    onClick={() => invisible('Profile')}
                  />
                </div>
              </div>

              <div className="col-11 mt-4">
                <div className="d-flex justify-content-between align-items-center">
                  {menuTab === 'EditProfile' ? (
                    <>
                      <Button
                        className="btn btnPrimary w-auto min-auto h-auto fsize-14 rounded"
                        onClick={() => {
                          updateData();
                        }}
                        loading={profileUpdateLoading}
                      >
                        <EditableBttn className={'me-2'} />
                        <span>Update Profile</span>
                      </Button>

                      <button
                        className="btn btnSecondary d-flex align-items-center w-auto min-auto h-auto fsize-14 rounded"
                        onClick={() => {
                          invisible('Profile');
                          setdatas({
                            first_name: userData.first_name,
                            last_name: userData.last_name,
                          });
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  {menuTab === 'ViewProfile' && (
                    <>
                      <div></div>
                      {/* <button
                        className="btn btnPrimary w-auto min-auto h-auto fsize-14 rounded btn__logout"
                        style={{ margin: 'unset' }}
                        onClick={() => {
                          localStorage.removeItem('token');
                          history.push('/');
                        }}
                      >
                        Logout
                      </button> */}
                    </>
                  )}{' '}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              {loading ? (
                <div className="row justify-content-center">
                  <div className="col-11 mt-2">
                    <div className="profileInf">
                      <div className="d-flex align-items-center">
                        <div className="userImageWrppr">
                          <img
                            src={
                              'https://ui-avatars.com/api/?&rounded=false&font-size=0.38&background=EFEFEF&color=4074f9&name=' +
                              getname()
                            }
                            width="40px"
                            className="userImage"
                            alt="userImage"
                          />
                        </div>
                        <div className="ms-3">
                          <>
                            <h5>
                              {userData.first_name !== undefined
                                ? userData.name
                                  ? userData.name
                                  : `${userData.first_name} ${userData.last_name}`
                                : '-'}
                            </h5>
                          </>
                          <p>{userData.email ? userData.email : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row justify-content-center">
                <div className="col-11 mt-2">
                  <div className="d-flex">
                    <div
                      className={
                        tab === 'profile'
                          ? 'profileSettingTab active'
                          : 'profileSettingTab'
                      }
                      onClick={() => setTab('profile')}
                    >
                      Personal Details
                    </div>
                    <div
                      className={
                        tab === 'security'
                          ? 'profileSettingTab active'
                          : 'profileSettingTab'
                      }
                      onClick={() => setTab('security')}
                    >
                      Security Setting
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <div
                  className={
                    tab === 'profile'
                      ? 'personalDetails'
                      : 'personalDetails d-none'
                  }
                >
                  <div className="row justify-content-center">
                    <div className="col-11 mt-3">
                      <div>
                        {/* <p style={{ color: "#9B8E8E" }}>
                          Basic info, like your name and address
                        </p> */}
                        <div>
                          <Form className="mt-4" layout="vertical">
                            <Form.Item
                              label="First Name"
                              name="first_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Name is required',
                                },
                              ]}
                            >
                              <div className="d-flex position-relative">
                                <Input
                                  className="form-control"
                                  placeholder="Enter name"
                                  value={
                                    datas.first_name
                                      ? datas.name
                                        ? datas.name
                                        : `${datas.first_name}`
                                      : '-'
                                  }
                                  readOnly={
                                    menuTab === 'ViewProfile' ? true : false
                                  }
                                  name="first_name"
                                  onChange={(e) => handleChangeName(e)}
                                />
                              </div>
                            </Form.Item>

                            <Form.Item
                              label="Last Name"
                              name="last_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Name is required',
                                },
                              ]}
                            >
                              <div className="d-flex position-relative">
                                <Input
                                  className="form-control"
                                  placeholder="Enter name"
                                  value={
                                    datas.last_name
                                      ? datas.name
                                        ? datas.name
                                        : `${datas.last_name}`
                                      : '-'
                                  }
                                  readOnly={
                                    menuTab === 'ViewProfile' ? true : false
                                  }
                                  name="last_name"
                                  onChange={(e) => handleChangeName(e)}
                                />
                              </div>
                            </Form.Item>

                            <Form.Item
                              label="Email Id"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: 'Email is required',
                                },
                              ]}
                            >
                              <Input
                                className="form-control"
                                placeholder="Enter email"
                                defaultValue={
                                  userData.email ? userData.email : '-'
                                }
                                name="email"
                                readOnly={true}
                                disabled={
                                  menuTab === 'ViewProfile' ? false : true
                                }
                                //onChange={(e) => updateprofileEmail(e)}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Phone Number"
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: 'Phone Number is required',
                                },
                              ]}
                            >
                              <div>
                                <Input
                                  className="form-control"
                                  placeholder="Enter Registered Phone Number"
                                  defaultValue={
                                    userData.phone_no ? userData.phone_no : '-'
                                  }
                                  name="phone"
                                  readOnly={true}
                                  disabled={
                                    menuTab === 'ViewProfile' ? false : true
                                  }
                                  //onChange={(e) => updateprofileEmail(e)}
                                />
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="additionalDetails">
                    <p style={{ fontSize: '12px' }}>Additional</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="idw">User Id:</p>
                        <p className="ded">{userid.id}</p>
                      </div>
                      <div>
                        <p className="idw">Last Login:</p>
                        <p className="ded">
                          {moment(userData?.last_login).format('DD MMM, YYYY')}
                        </p>
                      </div>
                      <div>
                        <p className="idw">Register At:</p>
                        <p className="ded">
                          {moment(userData?.created_at).format('DD MMM, YYYY')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === 'security'
                      ? 'personalDetails securities'
                      : 'personalDetails securities d-none'
                  }
                >
                  <div className="row justify-content-center">
                    <div className="col-11 mt-3">
                      <div>
                        <p style={{ color: '#9B8E8E' }}>
                          These settings are helps you keep your account Secure.
                        </p>

                        <div className="mt-4">
                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">Save My Activity Logs</h5>
                              <p className="sePara">
                                Save your all activity logs includong unsual
                                activity detected
                              </p>
                            </div>
                            <div>
                              <Switch
                                defaultChecked={activeLogs}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                                onChange={(checked) => {
                                  return onChange(checked, 'activeLogs');
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                Email me if encounter unusual activity
                              </h5>
                              <p className="sePara">
                                You will get email notification whenever
                                encounter invalid login activity
                              </p>
                            </div>
                            <div>
                              <Switch
                                defaultChecked={emailLogs}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                                onChange={(checked) => {
                                  return onChange(checked, 'emailLogs');
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                {menuTab === 'ViewProfile'
                                  ? `Current Email: ${userData.email}`
                                  : 'Change Email Address'}
                              </h5>
                              <p className="sePara">
                                Update your current email address to new email
                                address.
                              </p>
                            </div>
                            <div>
                              <Button
                                className="securtyBttn"
                                onClick={() => setIsEmailModalVisible(true)}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                              >
                                Change Email
                              </Button>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">Change Password</h5>
                              <p className="sePara">
                                Set a unique password to protect your account.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: '#9B8E8E',
                                  fontStyle: 'italic',
                                }}
                              >
                                Last Change N/A
                              </span>
                              <Button
                                className="securtyBttn ms-3"
                                onClick={() => setIsModalVisible(true)}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                              >
                                Change Password
                              </Button>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                2FA Authentication{' '}
                                <Button
                                  className={
                                    auth === 'enable'
                                      ? 'authenticationStatus enabled'
                                      : 'authenticationStatus'
                                  }
                                  disabled={
                                    menuTab === 'ViewProfile' ? true : false
                                  }
                                >
                                  {auth === 'enable' ? 'Enabled' : 'Disabled'}
                                </Button>
                              </h5>
                              <p className="sePara">
                                Secure your account with 2FA security. When it
                                is activated you will need to enter not only
                                password, but also a special code using your
                                mobile
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="securtyBttn ms-3"
                                onClick={() => {
                                  if (auth === 'enable') {
                                    setAuth('Disabled');
                                  } else {
                                    setAuth('enable');
                                  }
                                  return onChange(auth, 'Auth');
                                }}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                                loading={ButtonLoader}
                              >
                                {auth === 'enable' ? 'Disable' : 'Enable'}
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                Configure Alert{' '}
                                {/* <Button
                                  className={
                                    auth === 'enable'
                                      ? 'authenticationStatus enabled'
                                      : 'authenticationStatus'
                                  }
                                  disabled={
                                    menuTab === 'ViewProfile' ? true : false
                                  }
                                >
                                  {auth === 'enable' ? 'Enabled' : 'Disabled'}
                                </Button> */}
                              </h5>
                              <p className="sePara">
                                Secure your account with 2FA security. When it
                                is activated you will need to enter not only
                                password, but also a special code using your
                                mobile
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* <Button
                                className="securtyBttn ms-3"
                                onClick={() => {
                                  if (auth === 'enable') {
                                    setAuth('Disabled');
                                  } else {
                                    setAuth('enable');
                                  }
                                  return onChange(auth, 'Auth');
                                }}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                                loading={ButtonLoader}
                              >
                                {auth === 'enable' ? 'Disable' : 'Enable'}
                              </Button> */}
                              <Button
                                className="securtyBttn ms-3"
                                onClick={handleOpenModal}
                                disabled={
                                  menuTab === 'ViewProfile' ? true : false
                                }
                                loading={ButtonLoader}
                              >
                                {auth === 'enable' ? 'Disable' : 'Enable'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Drawer>

      {/* Change password modal */}
      <Modal
        footer={null}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="row justify-content-center mt-4">
          <div className="col-11">
            <h5>Change Password</h5>
          </div>
          <div className="col-11">
            <Form className="mt-3" layout="vertical">
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: 'Password is required',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter current password"
                  name="old_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              {oldPassError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: '0px' }}
                    >
                      {oldPassError}
                    </div>
                  </div>
                </>
              ) : null}

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'New Password is required',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  name="new_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              {passwordError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: '0px' }}
                    >
                      {passwordError}
                    </div>
                  </div>
                </>
              ) : null}
              <Form.Item
                label="Verify Password"
                name="verifyPassword"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Password field required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Enter verify password"
                  name="verify_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              <div className="d-flex align-items-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="allBtns me-3"
                  onClick={updatedPass}
                >
                  Update Password
                </Button>
                <a
                  onClick={() => setIsModalVisible(false)}
                  style={{ color: '#8C7E7E' }}
                >
                  Cancel
                </a>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      {/* Change Email id password */}

      <Modal
        footer={null}
        open={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
      >
        <div className="row justify-content-center mt-4">
          <div className="col-11">
            <h5>Enter Your Valid Email Address</h5>
          </div>
          <div className="col-11">
            <Form className="mt-3" layout="vertical">
              <Form.Item
                label="Current Email Address"
                name="currentEmail"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ]}
              >
                <Input
                  className="form-control"
                  placeholder="Enter Current Email"
                  name="old_email"
                  onChange={onChangeEmail}
                />
              </Form.Item>

              <Form.Item
                label="New Email Address"
                name="newEmailAddress"
                rules={[
                  {
                    required: true,
                    message: 'New Email is required',
                  },
                ]}
              >
                <Input
                  className="form-control"
                  placeholder="Enter new email"
                  name="new_email"
                  onChange={onChangeEmail}
                />
              </Form.Item>

              <div className="d-flex align-items-center mt-4">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="allBtns me-3"
                  onClick={updateEmailID}
                  loading={ButtonLoader}
                >
                  Send Verification Email
                </Button>
                <a
                  onClick={() => setIsEmailModalVisible(false)}
                  style={{ color: '#8C7E7E' }}
                >
                  Cancel
                </a>
              </div>
            </Form>
            <small
              className="mt-4 d-block"
              style={{ color: 'red', fontSize: '9px' }}
            >
              {' '}
              <InfoCircleOutlined className="me-2" />
              Wether you verify your email or not, from nect login you have to
              use your new email address.
            </small>
          </div>
        </div>
      </Modal>
      {/* <LogoutModal open={open} close={close} type={type} /> */}
      <Modal
        visible={alertConfigureModal}
        style={{ borderRadius: '17px' }}
        footer={null}
        centered
        width={615}
        height={458}
        onCancel={handleCloseModal}
      >
        <div style={{ borderRadius: '17px' }}>
          <div>
            <h6>Alert Configure</h6>
          </div>
          <hr></hr>
          <p>Available Channels</p>
          <div className="row justify-content-center">
            <div className="d-flex row justify-content-between mt-3 mb-3">
              {imageTabs.map((tab) => (
                <div
                  className="col-3 d-flex flex-column align-items-center"
                  key={tab.key}
                >
                  <Card
                    style={{ border: 'none' }}
                    // className={` ${activeTab === tab.key ? 'active' : ''}`}
                    onClick={() => handleTabChange(tab.key)}
                  >
                    <img
                      className={`border p-1 ${
                        activeTab === tab.key ? 'active' : ''
                      }`}
                      // className="border p-1"
                      style={{
                        width: '94px',
                        height: '94px',
                        borderRadius: '50px',
                        cursor: 'pointer',
                      }}
                      src={tab.imageSrc}
                      alt={tab.key}
                      // style={{ width: '100%', height: '100%' }}
                    />
                  </Card>
                </div>
              ))}
            </div>
            <div className="col-12 mt-3">
              {activeTab && (
                <Card style={{ border: 'none' }}>
                  <p>
                    {imageTabs.find((tab) => tab.key === activeTab).content}
                  </p>
                </Card>
              )}
            </div>
            {/* <div className="d-flex row justify-content-between mt-3 mb-3">
              <div className="col-3 d-flex flex-column align-items-center">
                <img
                  className="border p-1"
                  src={telegram}
                  alt="gmail"
                  style={{
                    width: '94px',
                    height: '94px',
                    borderRadius: '50px',
                  }}
                />
                <p>Telegram</p>
              </div>
              <div className="col-3 d-flex flex-column align-items-center">
                <img
                  className="border p-1"
                  src={slack}
                  alt="gmail"
                  style={{
                    width: '94px',
                    height: '94px',
                    borderRadius: '50px',
                  }}
                />
                <p>Slack</p>
              </div>
              <div className="col-3 d-flex flex-column align-items-center ">
                <img
                  className="border p-3"
                  src={gmail}
                  alt="gmail"
                  style={{
                    width: '94px',
                    height: '94px',
                    borderRadius: '50px',
                  }}
                />
                <p>Email</p>
              </div>
              <div className="col-3 d-flex flex-column align-items-center">
                <img
                  className="border p-1"
                  src={message}
                  alt="gmail"
                  style={{
                    width: '94px',
                    height: '94px',
                    borderRadius: '50px',
                  }}
                />
                <p>sms</p>
              </div>
            </div>
            <div>
              <hr></hr>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-4">
                  <label className="font-weight-bold">Channel ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ID"
                    value={channelId}
                    onChange={(e) => setChannelId(e.target.value)}
                  />
                </div>
                <div className="form-group col-5">
                  <label className="font-weight-bold">Alert Type</label>
                  <select
                    className="form-select form-select-md mb-3"
                    aria-label=".form-select-md example"
                    value={alertType}
                    onChange={(e) => setAlertType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="back_testing">Back Testing</option>
                    <option value="virtual_trade">Virtual Trade</option>
                    <option value="live_trade">Live Trade</option>
                  </select>
                </div>
                <div className="form-group col-3 d-flex flex-column">
                  <label className="font-weight-bold">Status</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status}
                      onChange={() => setStatus(!status)}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="row justify-content-end align-items-center">
                <div className="col-2">
                  <a
                    href="#"
                    style={{ color: 'black' }}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </a>
                </div>
                <div className="col-3">
                  <button
                    style={{
                      padding: '4px',
                      borderRadius: '6.324px',
                      border: '1.054px solid #DCDCDC',
                      background: '#E2E2E2',
                    }}
                    type="submit"
                    onClick={handleCloseModal}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form> */}
            <div className="row justify-content-end align-items-center">
              <div className="col-2">
                <a
                  href="#"
                  style={{ color: 'black' }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </a>
              </div>
              <div className="col-3">
                <button
                  style={{
                    padding: '4px',
                    borderRadius: '6.324px',
                    border: '1.054px solid #DCDCDC',
                    background: '#E2E2E2',
                  }}
                  type="submit"
                  onClick={handleCloseModal}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserProfile;
