import "../UI/Button.css";
const Button = (props) => {
  // const clickHandler = (event) => {
  //   event.preventDefault();
  // };
  return (
    <button
      // onClick={clickHandler}
      className={` button ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
