import React, { useState, useEffect } from 'react';
import '../UI/Header.css';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FuturisticWhiteLogo from '../../img/futuristic_white_logo.png';
import FuturisticWhiteLogoSmall from '../../img/futuristic_icon.png';
import { NavLink, Link } from 'react-router-dom';
import {
  chartSocket,
  priceSocket,
  tradeSocket,
  valueSocket,
} from '../../context/socket';
import UserProfile from '../../utils/UserProfile';

var menuTab = '';

const Sidebar = (props) => {
  const route = window.location.pathname;

  const [profileVisible, setProfileVisibility] = useState(false);

  const closeModal = (Component) => {
    profileVisible ? setProfileVisibility(false) : setProfileVisibility(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const [anchorElNew, setAnchorElNew] = useState(null);
  // const [anchorElNew1, setAnchorElNew1] = useState(null);

  const openNew = Boolean(anchorElNew);
  // const openMenu = Boolean(anchorElNew1);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleCloseNew = () => {
    setAnchorElNew(null);
  };

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const matchIncludes = ['/strategies', '/strategy', '/view-report'];
  const isActiveFunc = () => {
    return matchIncludes.some((path) =>
      window.location.pathname.startsWith(path),
    );
  };
  return (
    <>
      {isMobile == false ? (
        <div></div>
      ) : (
        <div className="d-flex row mob__top__bar">
          <div className="col-6">
            {/* <Link to={'/strategies'}> */}
            <img
              style={{ width: '60px', height: '60px' }}
              src={FuturisticWhiteLogoSmall}
              alt="logo"
            />
            {/* </Link> */}
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end top__nv__cntrl">
            <Tooltip title="Profile" placement="right">
              <IconButton
                id="basic-button"
                aria-controls={openNew ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openNew ? 'true' : undefined}
                onClick={handleClickNew}
                sx={{ p: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#4074f9"
                  className="bi bi-person"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                </svg>
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorElNew}
              open={openNew}
              onClose={handleCloseNew}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  menuTab = 'ViewProfile';
                  handleCloseNew();
                  setProfileVisibility(true);
                }}
              >
                View Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  menuTab = 'EditProfile';
                  handleCloseNew();
                  setProfileVisibility(true);
                }}
              >
                Edit Profile
              </MenuItem>
            </Menu>

            <Tooltip title="Logout" placement="right">
              <IconButton
                onClick={() => {
                  // socket.disconnect();
                  priceSocket.disconnect();
                  chartSocket.disconnect();
                  tradeSocket.disconnect();
                  valueSocket.disconnect();
                  // handleCloseNew();
                  localStorage.removeItem('token');
                  // history.push('/');
                  window.location.href = '/';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#4074f9"
                  className="bi bi-power"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.5 1v7h1V1h-1z" />
                  <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="">
        {isMobile == false ? (
          <div
            className="position-fixed w-10 rounded sidebar"
            style={{ background: '#fff', left: '10px' }}
          >
            <div className="sidebar__area">
              <img
                style={{ width: '50px', height: '50px' }}
                src={FuturisticWhiteLogoSmall}
                alt=""
              />

              <ul className="d-flex flex-column justify-content-center align-items-center mt-5">
                <li>
                  <Tooltip title="Virtual Trade" placement="right">
                    <NavLink
                      to={'/portfolio/virtual'}
                      exact
                      // activeStyle={{
                      //   color: '#4074F9',
                      //   fontWeight: '500',
                      // }}
                      sx={{ my: 0, color: 'white', display: 'block' }}
                      // activeClassName="admin-link-active"
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="16 16 12 12 8 16"></polyline>
                        <line x1="12" y1="12" x2="12" y2="21"></line>
                        <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                        <polyline points="16 16 12 12 8 16"></polyline>
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Live Trade" placement="right">
                    <NavLink
                      to={'/portfolio/live'}
                      // exact
                      // activeStyle={{
                      //   color: '#4074F9',
                      //   fontWeight: '500',
                      // }}
                      // sx={{ my: 0, color: 'white', display: 'block' }}
                      // activeClassName="admin-link-active"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-broadcast"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Strategy" placement="right">
                    <NavLink
                      to={'/strategies'}
                      isActive={isActiveFunc}
                      // exact
                      // activeStyle={{
                      //   color: '#4074F9',
                      //   fontWeight: '500',
                      // }}
                      // sx={{ my: 0, color: 'white', display: 'block' }}
                      // activeClassName="admin-link-active"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 256 256"
                        height="22px"
                        width="22px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Chart" placement="right">
                    <NavLink
                      to={'/chart'}
                      // exact
                      // activeStyle={{
                      //   color: '#4074F9',
                      //   fontWeight: '500',
                      // }}
                      // sx={{ my: 0, color: 'white', display: 'block' }}
                      // activeClassName="admin-link-active"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-graph-up-arrow"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                        />
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Broker" placement="right">
                    <NavLink
                      to={'/broker'}
                      // exact
                      // activeStyle={{
                      //   color: '#4074F9',
                      //   fontWeight: '500',
                      // }}
                      // sx={{ my: 0, color: 'white', display: 'block' }}
                      // activeClassName="admin-link-active"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-person-gear"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
              </ul>

              <ul className="sidebar__bottom__area d-flex flex-column justify-content-center align-items-center mt-5 position-absolute">
                <li>
                  <Tooltip title="Profile" placement="right">
                    <IconButton
                      id="basic-button"
                      aria-controls={openNew ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openNew ? 'true' : undefined}
                      onClick={handleClickNew}
                      sx={{ p: 0 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-person"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElNew}
                    open={openNew}
                    onClose={handleCloseNew}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        menuTab = 'ViewProfile';
                        handleCloseNew();
                        setProfileVisibility(true);
                      }}
                    >
                      View Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        menuTab = 'EditProfile';
                        handleCloseNew();
                        setProfileVisibility(true);
                      }}
                    >
                      Edit Profile
                    </MenuItem>
                  </Menu>
                </li>

                <li>
                  <Tooltip title="Logout" placement="right">
                    <IconButton
                      onClick={() => {
                        // socket.disconnect();
                        priceSocket.disconnect();
                        chartSocket.disconnect();
                        tradeSocket.disconnect();
                        valueSocket.disconnect();
                        // handleCloseNew();
                        localStorage.removeItem('token');
                        // history.push('/');
                        window.location.href = '/';
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-power"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.5 1v7h1V1h-1z" />
                        <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="mobile__sticky__nav">
            <ul>
              <li>
                <NavLink
                  to={'/portfolio/virtual'}
                  exact
                  // activeStyle={{
                  //   color: "#4074F9",
                  //   fontWeight: "500",
                  // }}

                  // sx={{ my: 0, color: 'white', display: 'block' }}
                  // activeClassName="admin-link-active"
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="16 16 12 12 8 16"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                    <polyline points="16 16 12 12 8 16"></polyline>
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink to={'/portfolio/live'} exact>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="currentColor"
                    className="bi bi-broadcast"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={'/strategies'}
                  isActive={isActiveFunc}
                  // activeStyle={{
                  //   color: "#4074F9",
                  //   fontWeight: "500",
                  // }}

                  // sx={{ my: 0, color: 'white', display: 'block' }}
                  // activeClassName="admin-link-active"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 256 256"
                    height="22px"
                    width="22px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                  </svg>
                </NavLink>
              </li>
              <li>
                <Tooltip title="Chart" placement="right">
                  <NavLink
                    to={'/chart'}
                    // exact
                    // activeStyle={{
                    //   color: '#4074F9',
                    //   fontWeight: '500',
                    // }}
                    // sx={{ my: 0, color: 'white', display: 'block' }}
                    // activeClassName="admin-link-active"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-graph-up-arrow"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                      />
                    </svg>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <NavLink
                  to={'/broker'}
                  // exact
                  // activeStyle={{
                  //   color: '#4074F9',
                  //   fontWeight: '500',
                  // }}
                  // sx={{ my: 0, color: 'white', display: 'block' }}
                  // activeClassName="admin-link-active"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="currentColor"
                    className="bi bi-person-gear"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                  </svg>
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
      <UserProfile
        visible={profileVisible}
        invisible={closeModal}
        close={closeModal}
        menuTab={menuTab}
      />
    </>
  );
};

export default Sidebar;
