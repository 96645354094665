import React, { useState } from 'react';
import { Space, Switch, Table, Tag, Dropdown, Checkbox, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ModelDelete from '../../Common/DeleteConfirm';

import dayjs from 'dayjs';
import axios from 'axios';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import config from '../../../Config/config';
import { toast } from 'react-toastify';

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function StrategyDesktopTable({
  tradeOrder,
  toggleState,
  getVirtualTradeData,
}) {
  let token = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(token);
  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedKeys([...selectedKeys, id]);
    } else {
      setSelectedKeys(selectedKeys.filter((selectedId) => selectedId !== id));
    }
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link
          to={`/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${
            record._id
          }`}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => Link}>
        Print
      </Menu.Item>
      <Menu.Item key="3" onClick={() => deleteRequestReports(record._id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Select',
      dataIndex: '_id',
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(record._id, e.target.checked)}
        />
      ),
      width: '10%',
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      render: (text, record) => (
        <Link
          to={`/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${
            record._id
          }`}
          className="primary__blue"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      render: (text) => dayjs(text).format('DD MMM YY'),
    },
    {
      title: 'Max Cap',
      dataIndex: 'max_cap',
    },
    {
      title: 'No of order',
      dataIndex: 'Order',
    },
    {
      title: 'Status',
      key: 'tag',
      dataIndex: 'current_status',
    },
    {
      title: 'Mode',
      dataIndex: 'Mode',
      render: (text, record) => (
        <Space size="middle">
          <Switch
            checked={record?.status}
            onChange={(checked) =>
              handleUpdate(checked, record?._id, record?.strategy_id)
            }
          />
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown overlay={menu(record)} placement="bottomRight">
            <MoreOutlined style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [removeModel, setRemoveModel] = useState(false);
  const [reportId, setReportId] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const updateReportOnDel = (value) => {
    setDeleteLoading(value);
  };
  const deleteRequestReports = (value) => {
    // console.log(value);
    setRemoveModel(true);
    setReportId(value);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setRemoveModel(false);
  };

  //Update VT trade
  const handleUpdate = (status, id, strategy_id) => {
    console.log(status, id);
    let payload = {
      vt_id: id,
      user_id: userid.id,
      strategy_id: strategy_id,
      status: status,
    };
    let ltPayload = {
      lt_id: id,
      user_id: userid.id,
      strategy_id: strategy_id,
      status: status,
    };
    axios
      .put(
        toggleState === 'live'
          ? `${config.base_url}${config.live_trade}`
          : `${config.base_url}${config.virtual_trade}`,
        toggleState === 'live' ? ltPayload : payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        console.log('Response Data', res.data);
        getVirtualTradeData();
        if (res.data.status) {
          return toast.success(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(function (status) {});
  };

  return (
    <div>
      <div className="strategy__tbl__data">
        <Table columns={columns} dataSource={tradeOrder} rowKey="_id" />
      </div>
      <ModelDelete
        from={toggleState === 'live' ? 'live' : 'virtual'}
        showModal={removeModel}
        hideModal={hideConfirmationModal}
        id={reportId}
        updateReportOnDel={updateReportOnDel}
        refetch={getVirtualTradeData}
        loading={deleteLoading}
      />
    </div>
  );
}

export default StrategyDesktopTable;
