import React, { useState, useEffect } from 'react';
import Tooltip from 'antd/es/tooltip';
import axios from 'axios';
import config from '../Config/config';
import { toast } from 'react-toastify';

const ReportIcon = ({
  setTradeSettingSwitch,
  reportStatus,
  getuserBots,
  playPauseID,
  getPauseIcon,
}) => {
  const [showPlayButton, setShowPlayButton] = useState(true);
  //To get Status of play-pause
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${config.Kill_trade_base_url}${config.play}?request_id=${playPauseID}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );
        const result = response.data.data.play;
        setShowPlayButton(result);
      } catch (error) {
        console.log(error);
      }
    }
    if (playPauseID !== '') {
      fetchData();
    }
  }, [playPauseID]);
  //Play-Pause call
  const handlePlayPause = async (status) => {
    try {
      const response = await axios.get(
        `${config.Kill_trade_base_url}${config.play}?request_id=${playPauseID}`,
        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      );
      if (response?.data?.data?.play) {
        setShowPlayButton(response?.data?.data?.play);
      }
      if (response.data.data.day_status && response?.data?.data?.market_hours) {
        const payload = {
          request_id: playPauseID,
          play: status,
        };
        axios
          .post(`${config.Kill_trade_base_url}${config.play}`, payload, {
            headers: { Authorization: `Bearer ${getuserBots}` },
          })
          .then((res) => {
            console.log(res);
            if (status) {
              return toast.success('Continue the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            } else {
              return toast.success('Pause the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
        setShowPlayButton(status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="col-lg-2 order-2 col-6 order-lg-3 pe-0 pe-lg-3">
      <ul className="strategy__sml__btns">
        <li>
          <button className="btn_info">
            <Tooltip title="Config" placement="bottom">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
              </svg>
            </Tooltip>
          </button>
        </li>
        <li>
          <button
            className="btn_setting"
            onClick={() => setTradeSettingSwitch(true)}
          >
            <Tooltip title="Order Setting" placement="bottom">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z"></path>
              </svg>
            </Tooltip>
          </button>
        </li>
        <li>
          {reportStatus && (
            <div>
              {showPlayButton ? (
                <Tooltip placement="bottom" title="Pause">
                  <button
                    className="btn_alert"
                    disabled={getPauseIcon()}
                    onClick={() => {
                      handlePlayPause(false);
                    }}
                  >
                    <svg
                      stroke="#4074F9"
                      fill="#4074F9"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"></path>
                    </svg>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip placement="bottom" title="Play">
                  <button
                    className="btn_alert"
                    onClick={() => {
                      handlePlayPause(true);
                    }}
                    disabled={getPauseIcon()}
                  >
                    <svg
                      stroke="#4074F9"
                      fill="#4074F9"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
                    </svg>
                  </button>
                </Tooltip>
              )}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ReportIcon);
