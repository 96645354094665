import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Tooltip from '@mui/material/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import dayjs from 'dayjs';

const ClosedMobileCards = ({ closedTrades, paginationCall, tab, hasMore }) => {
  let history = useHistory();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  console.log('callewd');

  return (
    <div
      id="cardsDiv"
      style={{
        height: 700,
        overflow: 'auto',
        display: 'flex',
        // flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        scrollableTarget="cardsDiv"
        dataLength={closedTrades.length}
        next={paginationCall}
        hasMore={true}
        loader={<h4>...Loading</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {closedTrades?.map((item, index) => (
          <div key={index}>
            <div className="tbl__hd mobile close__order virtaul">
              <div className="coll text-truncate">Report Name</div>
              <div className="coll">Symbol</div>
              <div className="coll text-center">QTY</div>
              <div className="coll text-truncate">Trade Action</div>
              <div className="coll text-truncate">Trigger Price</div>
            </div>
            <Accordion className="close__order">
              <Accordion.Item eventKey={item?._id}>
                <Accordion.Header>
                  <div className="tbl__body close__order virtaul">
                    <div
                      className="coll text-truncate"
                      onClick={() =>
                        history.push(
                          `/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
                            item?.request_id
                          }`,
                        )
                      }
                    >
                      <span className="text-primary">
                        {item?.vt_requests[0].vt_inputs.report_name}
                      </span>
                    </div>
                    <Tooltip title={item?.tradingsymbol} placement="top">
                      <div className="coll text-truncate">
                        {item?.tradingsymbol}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.quantity}
                    </div>
                    <div className="coll text-truncate">
                      {item?.trade_action}
                    </div>
                    <div className="coll text-truncate">
                      {item?.trigger_price}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbl__body__inner close__order">
                    <div className="coll text-truncate">Strategy</div>
                    <div className="coll text-truncate">Order Time</div>
                    <div className="coll text-center text-truncate">
                      Lot Size
                    </div>
                    <div className="coll text-center text-truncate">
                      Exit Type
                    </div>
                    <div className="coll text-center text-truncate">
                      Transaction Type
                    </div>
                  </div>

                  <div className="tbl__body__inner__data close__order">
                    <Tooltip title={item?.strategys[0].name} placement="top">
                      <div
                        className="coll text-truncate"
                        onClick={() =>
                          history.push(`/strategy/${item?.strategy_id}`)
                        }
                      >
                        <span className="text-primary">
                          {item?.strategys[0].name}
                        </span>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={dayjs
                        .utc(item?.order_timestamp)
                        .format('DD-MM-YYYY hh:mm:ss a')}
                      placement="top"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.order_timestamp)
                          .format('DD-MM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.lot_size}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.exit_type ? item?.exit_type : '-'}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.transaction_type}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
        {/* {hasMore || closedTrades.length === 0 ? (
          // Show a message when there's no more data to load
          <p>No more data to load</p>
        ) : null} */}
      </InfiniteScroll>
    </div>
  );
};

export default React.memo(ClosedMobileCards);
