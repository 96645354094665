import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Accordion from 'react-bootstrap/Accordion';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const ReportClosedTable = ({ paginationCall, mobileClosedOrders }) => {
  return (
    <div
      id="ordersDiv"
      style={{
        height: 700,
        overflow: 'auto',
        display: 'flex',
        // flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        scrollableTarget="ordersDiv"
        dataLength={mobileClosedOrders.length}
        next={paginationCall}
        hasMore={true}
        loader={<h4>No Data Available</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {mobileClosedOrders?.map((item) => (
          <div key={item._id}>
            <div className="tbl__hd mobile close__order">
              <div className="coll">Symbol</div>
              <div className="coll text-center">QTY</div>
              <div className="coll">Trigger Price</div>
              <div className="coll">Trade Action</div>
            </div>
            <Accordion className="close__order">
              <Accordion.Item eventKey={item._id}>
                <Accordion.Header>
                  <div className="tbl__body close__order vt">
                    <Tooltip title={item?.tradingsymbol} placement="right">
                      <div className="coll text-truncate">
                        {item?.tradingsymbol}
                      </div>
                    </Tooltip>
                    <div className="coll text-center">{item?.quantity}</div>
                    <div className="coll text-truncate">
                      {item?.trigger_price}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.trade_action}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbl__body__inner close__order report">
                    <div className="coll text-truncate">Order Time</div>
                    <div className="coll text-center text-truncate">
                      Lot Size
                    </div>
                    <div className="coll text-center text-truncate">
                      Transaction Type
                    </div>
                    <div className="coll text-center text-truncate">
                      Exit Type
                    </div>
                  </div>

                  <div className="tbl__body__inner__data close__order report">
                    <Tooltip
                      title={dayjs
                        .utc(item?.order_timestamp)
                        .format('DD-MMM-YYYY hh:mm:ss a')}
                      placement="right"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.order_timestamp)
                          .format('DD-MMM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.lot_size}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.transaction_type}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.exit_type ? item?.exit_type : '-'}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ReportClosedTable;
