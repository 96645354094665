import React, { useState, useEffect } from 'react';
import { mydecodedTokenFunction } from '../../../utils/decodetoken';

import {
  Card,
  Col,
  Container,
  Form,
  Modal,
  NavLink,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Delete, PlusIcon, CrossIcon, CheckLine } from '../../../assets/Icon';
import axios from 'axios';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Header from '../Header';
import { useHistory } from 'react-router-dom';
import PermissionModal from '../../Common/permissionModal';
import ShowPermission from '../../Common/ShowPermission';
import config from '../../../Config/config';
import Sidebar from '../Sidebar';
// import { socket } from '../../../context/socket';

let message = '';
function Strategy() {
  let getuserBots = localStorage.getItem('token');
  let stAccess = JSON.parse(localStorage.getItem('st_access'));
  let requested = JSON.parse(localStorage.getItem('Requested_st_access')) || [];
  const tokenexist = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  let history = useHistory();
  const [permissionModal, setPermissionModal] = useState(false);
  const [permissionDetails, setPermissionDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [addStrategyModal, setAddStrategyModal] = useState(false);
  const [addStrategyModalNew, setAddStrategyModalNew] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [publicStrategy, setPublicStrategy] = useState([]);
  const [premiumStrategy, setPremiumStrategy] = useState([]);
  const [CustomStrategy, setCustomStrategy] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getID, setID] = useState('');
  //console.log(getID);
  const [addStrategy, setAddStrategy] = useState({
    name: '',
    description: '',
    added_by: 'user',
    added_by_id: userid.id,
    type: 'Document',
    condition: ['data1'],
    script_path: 'here',
    status: false,
    is_public: false,
  });
  //console.log(addStrategy);
  const [addpublicStrategy, setAddPublicStrategy] = useState(false);
  const [StrategyModal, setStrategyModal] = useState('');
  const [StrategySelectorModal, setStrategySelectorModal] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState({
    type: 'Document',
  });
  const [form_data, setForm_data] = useState([]);
  //console.log(form_data, form_data.length);
  //console.log("strategyData", strategyData);

  useEffect(() => {
    let cancel = false;

    getStrategy(cancel);

    return () => {
      console.log('here');
      cancel = true;
    };
  }, []);

  //Get strategy for (Popular, Custom and Premium) API

  const getStrategy = (cancel) => {
    //console.log("here");
    setLoading(true);
    axios
      .get(`${config.base_url}${config.get_strategy}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        // console.log('Response Data', res);
        if (cancel) return;

        let pubStrategy = res.data.data.filter(
          (el) => el.access_type === 'popular',
        );
        let CusStrategy = res.data.data.filter(
          (el) => el.access_type === 'custom',
        );
        let preStrategy = res.data.data.filter(
          (el) => el.access_type === 'premium',
        );
        //console.log(pubStrategy);
        setLoading(false);
        setPublicStrategy(pubStrategy);
        setPremiumStrategy(preStrategy);
        setCustomStrategy(CusStrategy);
      })
      .catch(function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  //getPremium

  const getPremium = (id) => {
    setPermissionModal(false);
    setOpen(true);
    setLoadingPermission(true);
    axios
      .post(
        `${config.base_url}${config.strategy_permission}`,
        {
          user_id: userid.id,
          st_id: getID ? getID : id,
        },
        {
          headers: { Authorization: `Bearer ${tokenexist}` },
        },
      )
      .then((res) => {
        console.log('Response Data', res);
        if (res.data.message === 'Permission request accept.') {
          localStorage.setItem(
            'st_access',
            JSON.stringify(res.data.st_access ? res.data.st_access : []),
          );
          history.push(`/strategy/${id}`);
        }
        if (res?.data?.message === 'Insert successfully.') {
          localStorage.setItem(
            'Requested_st_access',
            JSON.stringify([...requested, getID]),
          );
        }
        if (Object.keys(res?.data?.data).length > 0) {
          if (
            res?.data?.data?.permission_status === 'pending' ||
            res?.data?.data?.permission_status === 'decline'
          )
            localStorage.setItem(
              'Requested_st_access',
              JSON.stringify([...requested, getID]),
            );
        }
        setPermissionDetails(res.data);
        setLoadingPermission(false);
        setID('');
        //setStrategyData(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  //  Function to catch input change.
  const HandelChange = (e) => {
    //console.log(e.target.name);
    setAddStrategy({
      ...addStrategy,
      [e.target.name]:
        e.target.value === 'public'
          ? true
          : e.target.value === 'private'
          ? false
          : e.target.value,
    });
  };

  //  Function to set particular strategy

  const HandelChangePopular = (e) => {
    setSelectedStrategy({
      ...selectedStrategy,
      [e.target.name]:
        e.target.value === 'public'
          ? true
          : e.target.value === 'private'
          ? false
          : e.target.value,
    });
  };

  // addNotification({
  //   title: 'Warning',
  //   subtitle: 'This is a subtitle',
  //   message: 'This is a very long message',
  //   theme: 'darkblue',
  //   native: true, // when using native, your OS will handle theming.
  // });

  // console.log(selectedStrategy);

  // Function to change JSON to form Data.

  const createFormData = (data, selected) => {
    const formData = new FormData();
    //console.log(data);
    formData.append('added_by', data.added_by);
    formData.append('added_by_id', data.added_by_id);
    formData.append('condition', JSON.stringify(data.condition));
    formData.append('description', JSON.stringify(data.description));
    formData.append('doc_file', form_data[0]);
    formData.append(
      'is_public',
      selected === 'custom' ? addpublicStrategy : data.is_public,
    );
    formData.append('name', data.name);
    formData.append('script_path', data.script_path);
    formData.append('status', data.status);
    formData.append(
      'min_amount_required',
      selected === 'custom' ? '10000' : data.min_amount_required,
    );
    formData.append(
      'access_type',
      selected === 'custom' ? 'custom' : data.access_type,
    );
    formData.append(
      'position_type',
      selected === 'custom'
        ? JSON.stringify(['intraday'])
        : JSON.stringify(data.position_type),
    );
    formData.append(
      'risk_factor',
      selected === 'custom' ? '0' : data.risk_factor,
    );
    formData.append(
      'growth_factor',
      selected === 'custom' ? '0' : data.growth_factor,
    );
    formData.append('type', data.type);

    return formData;
  };

  // API to submit the strategy.

  const handelSubmit = (e, strategy) => {
    e.preventDefault();
    console.log(strategy);
    if (strategy === 'custom') {
      console.log('here');
      var formData = createFormData(addStrategy, 'custom');
    } else {
      console.log('here1');
      var formData = createFormData(selectedStrategy, 'popular');
    }

    axios
      .post(`${config.base_url}${config.get_strategy}`, formData, {
        headers: {
          Authorization: `Bearer ${getuserBots}`,
          'Content-type': 'multipart/form-date',
        },
      })
      .then((res) => {
        console.log('Response', res);
        getStrategy();
        message = res.data.message;
        setAddStrategyModalNew(false);
        setAddStrategyModal(false);
        setSuccessModalShow(true);
        setForm_data([]);
      })
      .catch(function (err) {
        setAddStrategyModal(false);
        console.log(err);
      });
  };

  // API to Delete the strategy.

  const handelDelete = () => {
    //console.log(_id);

    axios
      .delete(
        `${config.base_url}${config.get_strategy}/${getID}`,

        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      )
      .then((res) => {
        console.log('Response', res);
        getStrategy();
        setConfirmDelete(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <>
      {/* <Header /> */}
      <Sidebar />
      <div className="mt-0">
        <Container>
          {premiumStrategy && premiumStrategy.length > 0 ? (
            <section className="mbPx-0 cstm__tp__space">
              <Row className="mb-0 lg-mb-4 justify-content-between align-items-center">
                <Col>
                  <h5 className="text-muted text-capitalize">
                    Premium Strategy
                  </h5>
                </Col>
              </Row>
              <div className="overflow-auto w-100 scrollCss">
                <div>
                  <div className="d-flex justify-content-start gap-3">
                    {premiumStrategy.map((el, index) => {
                      return (
                        <div key={index}>
                          <Card
                            className="border-0 round-15 mbPx-24 position-relative"
                            style={{ maxWidth: '350px', minWidth: '300px' }}
                          >
                            <div
                              className="position-absolute rightPx-20 topPx-10"
                              style={{ display: 'none' }}
                              onClick={() => {
                                setID(el._id);
                                setConfirmDelete(true);
                              }}
                            >
                              <Delete
                                ondel={setID}
                                id={el._id}
                                confirmDelete={setConfirmDelete}
                                className={'text-danger'}
                              />
                            </div>
                            <Card.Body className="px-0">
                              <div className="px-3">
                                <Card.Title className="text-capitalize mt-0 lg-mt-3 truncate truncateLine-1 fs-5">
                                  {el.name ? el.name : ''}
                                </Card.Title>
                              </div>
                              <div className="px-3">
                                <Card.Subtitle className="mb-4 text-muted overflow-hidden fw-normal fsize-14 mt-2">
                                  {el.created_at
                                    ? moment(el.created_at).format(
                                        'DD MMM, YYYY h:mm a',
                                      )
                                    : ''}
                                </Card.Subtitle>
                              </div>
                              <div className="px-3">
                                <Card.Text className="text-muted fsize-10 hpx-14 truncate truncateLine-4 fw-normal">
                                  {el.position_type !== '' &&
                                  el.position_type !== null &&
                                  el.position_type !== undefined &&
                                  Array.isArray(el.position_type)
                                    ? el.position_type &&
                                      el.position_type.length > 0
                                      ? el.position_type.map((elem, index) => {
                                          if (index < 3) {
                                            if (
                                              index ===
                                              el.position_type.length - 1
                                            ) {
                                              return (
                                                <span key={index}>{elem}</span>
                                              );
                                            } else {
                                              return (
                                                <span key={index}>
                                                  {elem} |{' '}
                                                </span>
                                              );
                                            }
                                          }
                                        })
                                      : ''
                                    : ''}
                                </Card.Text>
                              </div>
                              <div className="mt-4 py-2 d-flex px-0 secondaryBttn">
                                <div
                                  className="text-center"
                                  style={{ flex: '1 0' }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Capital Required
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.min_amount_required &&
                                    el.min_amount_required !== '' &&
                                    el.min_amount_required !== null &&
                                    el.min_amount_required !== undefined
                                      ? `₹ ${el.min_amount_required}`
                                      : '-'}
                                  </Card.Text>
                                </div>
                                <div className="artcDividervertical"></div>
                                <div
                                  className="text-center"
                                  style={{ flex: '1 0' }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Hit Ratio
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.growth_factor &&
                                    el.growth_factor !== '' &&
                                    el.growth_factor !== null &&
                                    el.growth_factor !== undefined
                                      ? el.growth_factor
                                      : '-'}
                                  </Card.Text>
                                </div>
                                <div className="artcDividervertical"></div>
                                <div
                                  className="text-center"
                                  style={{ flex: '1 0' }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Risk
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.risk_factor &&
                                    el.risk_factor !== '' &&
                                    el.risk_factor !== null &&
                                    el.risk_factor !== undefined
                                      ? el.risk_factor
                                      : '-'}
                                  </Card.Text>
                                </div>
                              </div>
                              <div className="px-3">
                                <Link
                                  to={'#'}
                                  onClick={() => {
                                    let access =
                                      stAccess !== null &&
                                      stAccess !== undefined
                                        ? stAccess.filter(
                                            (elem) => elem === el._id,
                                          )
                                        : [];
                                    if (access.length > 0) {
                                      //history.push(`/strategy/${el._id}`);
                                      history.push(`/strategy/${el._id}`);
                                    } else {
                                      let request = requested.filter(
                                        (elem) => elem === el._id,
                                      );

                                      if (request.length > 0) {
                                        getPremium(el._id);
                                        setOpen(true);
                                      } else {
                                        setID(el._id);
                                        setPermissionModal(true);
                                      }
                                    }
                                  }}
                                  className="btn round-50 hpx-40 fsize-16 d-flex justify-content-center fw-semibold align-items-center btnPrimary processing mt-3"
                                >
                                  {el.status === true
                                    ? 'View Strategy'
                                    : 'Processing...'}
                                </Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            loading && (
              <>
                <section className="mbPx-100">
                  <Row className="mb-4 justify-content-between align-items-center">
                    <Col>
                      <h5 className="text-muted text-capitalize">
                        Premium Strategy
                      </h5>
                    </Col>
                  </Row>
                  <Row className="row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                  </Row>
                </section>
              </>
            )
          )}

          {publicStrategy && publicStrategy.length > 0 ? (
            <section className="mbPx-100">
              <Row className="mb-2 lg-mb-4">
                <Col>
                  <h5 className="text-muted text-capitalize">
                    Popular Strategy
                  </h5>
                </Col>
              </Row>
              <div className="overflow-auto w-100 scrollCss">
                <div>
                  <div className="d-flex justify-content-start gap-3">
                    {publicStrategy.map((el, index) => {
                      return (
                        <div key={index}>
                          <Card
                            className="border-0 round-15 mbPx-24"
                            style={{ maxWidth: '350px', minWidth: '300px' }}
                          >
                            <Card.Body className="px-0">
                              <div className="px-3">
                                <Card.Title className="text-capitalize mt-0 lg-mt-3 truncate truncateLine-1 fs-5">
                                  {el.name ? el.name : ''}
                                </Card.Title>
                              </div>
                              <div className="px-3">
                                <Card.Subtitle className="mb-4 text-muted overflow-hidden fw-normal fsize-14 mt-2">
                                  {/* 14 Dec, 2022 4:30 PM */}
                                  {el.created_at
                                    ? moment(el.created_at).format(
                                        'DD MMM, YYYY h:mm a',
                                      )
                                    : ''}
                                </Card.Subtitle>
                              </div>
                              <div className="px-3">
                                <Card.Text className="text-muted hpx-14  fsize-12 truncate truncateLine-4 fw-normal">
                                  {el.position_type !== '' &&
                                  el.position_type !== null &&
                                  el.position_type !== undefined &&
                                  Array.isArray(el.position_type)
                                    ? el.position_type &&
                                      el.position_type.length > 0
                                      ? el.position_type.map((elem, index) => {
                                          if (index < 3) {
                                            if (
                                              index ===
                                              el.position_type.length - 1
                                            ) {
                                              return (
                                                <span key={index}>{elem}</span>
                                              );
                                            } else {
                                              return (
                                                <span key={index}>
                                                  {elem} |{' '}
                                                </span>
                                              );
                                            }
                                          }
                                        })
                                      : ''
                                    : ''}
                                </Card.Text>
                              </div>
                              <div className="mt-4 py-2 d-flex px-0 secondaryBttn">
                                <div
                                  className="text-center"
                                  style={{
                                    flex: '1 0',
                                  }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Capital Required
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.min_amount_required &&
                                    el.min_amount_required !== '' &&
                                    el.min_amount_required !== null &&
                                    el.min_amount_required !== undefined
                                      ? `₹ ${el.min_amount_required}`
                                      : '-'}
                                  </Card.Text>
                                </div>
                                <div className="artcDividervertical"></div>
                                <div
                                  className="text-center"
                                  style={{
                                    flex: '1 0',
                                  }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Hit Ratio
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.growth_factor &&
                                    el.growth_factor !== '' &&
                                    el.growth_factor !== null &&
                                    el.growth_factor !== undefined
                                      ? el.growth_factor
                                      : '-'}
                                  </Card.Text>
                                </div>
                                <div className="artcDividervertical"></div>
                                <div
                                  className="text-center"
                                  style={{
                                    flex: '1 0',
                                  }}
                                >
                                  <Card.Text className="text-primary fsize-10 truncate truncateLine-4 fw-normal">
                                    Risk
                                  </Card.Text>
                                  <Card.Text className="text-muted fsize-9 truncate truncateLine-4 fw-normal">
                                    {el.risk_factor &&
                                    el.risk_factor !== '' &&
                                    el.risk_factor !== null &&
                                    el.risk_factor !== undefined
                                      ? el.risk_factor
                                      : '-'}
                                  </Card.Text>
                                </div>
                              </div>
                              <div className="px-3">
                                <Link
                                  // to={`/strategy/${el._id}`}
                                  to={`/strategy/${el._id}`}
                                  className="btn round-50 hpx-40 fsize-16 d-flex justify-content-center fw-semibold align-items-center btnPrimary mt-3"
                                >
                                  {el.status === true
                                    ? 'View Strategy'
                                    : 'Processing...'}
                                </Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            loading && (
              <>
                <section className="mbPx-100">
                  <Row className="mb-0 lg-mb-4 justify-content-between align-items-center">
                    <Col>
                      <h5 className="text-muted text-capitalize">
                        Popular Strategy
                      </h5>
                    </Col>
                  </Row>
                  <Row className="row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                    <Col>
                      <Skeleton
                        height="200px"
                        variant="rectangular"
                        style={{ borderRadius: '10px', marginTop: '0px' }}
                      />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Col>
                  </Row>
                </section>
              </>
            )
          )}
        </Container>

        <Modal
          show={addStrategyModal}
          size={'lg'}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="position-relative">
            <div className="text-end mb-4">
              <span
                onClick={() => setAddStrategyModal(false)}
                style={{ cursor: 'pointer' }}
              >
                <CrossIcon className="fsize-25" />
              </span>
            </div>

            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Strategy Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Strategy Name"
                      name="name"
                      onChange={HandelChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Strategy Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Strategy Description"
                      name="description"
                      onChange={HandelChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Strategy Type</Form.Label>
                    <Form.Select onChange={HandelChange} name="type">
                      <option>Document</option>
                      <option>Script Path</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>What Do Yo Want?</Form.Label>
                    <div className="d-flex align-items-center">
                      <div>
                        <label for="private" className="customRadioButton">
                          <input
                            type="radio"
                            name="is_public"
                            value="private"
                            hidden
                            className="customRadioInput"
                            id="private"
                            onChange={HandelChange}
                          />
                          <span className="radioMark"></span>
                          Private
                        </label>
                      </div>
                      <div>
                        <label for="public" className="customRadioButton">
                          <input
                            type="radio"
                            hidden
                            name="is_public"
                            value="public"
                            className="customRadioInput"
                            id="public"
                            onChange={HandelChange}
                          />
                          <span className="radioMark"></span>
                          Public
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Document</Form.Label>
                    {form_data && form_data.length === 0 && (
                      <div className="d-flex justify-content-center p-5">
                        <label
                          className="Upload px-4 py-3 round-10"
                          for="uploadFile"
                        >
                          <PlusIcon className="fsize-30" />
                        </label>
                        <input
                          type="file"
                          id="uploadFile"
                          accept={
                            addStrategy.type === 'Document'
                              ? '.doc,.docx,.pdf,.txt'
                              : ''
                          }
                          name="form_data"
                          hidden
                          onChange={(e) => setForm_data([e.target.files[0]])}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* 
              {console.log(form_data)} */}
              {form_data && form_data.length > 0 ? (
                <div className="ant-upload-list-item-container mb-2">
                  <div className="ant-upload-list-item ant-upload-list-item-done d-flex align-items-center">
                    <span
                      className="ant-upload-list-item-name text-success"
                      style={{ paddingLeft: '0px' }}
                    >
                      {form_data[0].name ? form_data[0].name : '-'}
                    </span>
                    <span>
                      <DeleteOutlined onClick={() => setForm_data([])} />
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <Row>
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      className="textBColor fsize-14"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setAddStrategyModal(false);
                        setAddStrategyModalNew(true);
                      }}
                    >
                      Cancel
                    </p>
                    <button
                      className="textBColor w-25 ms-auto btn py-2 round-50 hpx-40 fsize-16 fw-semibold secondaryBttn"
                      onClick={(e) => handelSubmit(e, StrategySelectorModal)}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={addStrategyModalNew}
          size={'lg'}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="position-relative">
            {!StrategyModal && (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Add Strategy</h5>
                  <div className="text-end mb-4">
                    <span
                      onClick={() => {
                        setAddStrategyModalNew(false);
                        setStrategyModal(false);
                        setStrategySelectorModal('');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <CrossIcon className="fsize-25" />
                    </span>
                  </div>
                </div>

                <div className="text-center">
                  <p>Choose your option wisely</p>
                  <div className="mt-5">
                    <input
                      type="radio"
                      hidden
                      name="strategy_type"
                      value="predefined"
                      id="predefined"
                      className="stratery_input"
                    />
                    <label
                      for="predefined"
                      className="w-50 round-5 border p-2 fsize-14 fweight-6 stratery_label"
                      onClick={() => {
                        //console.log("here");
                        setStrategySelectorModal('popular');
                      }}
                    >
                      Popular Strategy
                    </label>
                    <h6 className="mt-4 mb-4">Or</h6>
                    <input
                      type="radio"
                      hidden
                      name="strategy_type"
                      value="custom"
                      className="stratery_input"
                      id="custom"
                    />
                    <label
                      for="custom"
                      className="w-50 round-5 border p-2 fsize-14 fweight-6 stratery_label"
                      onClick={() => {
                        //console.log("here");
                        setStrategySelectorModal('custom');
                      }}
                    >
                      Create Custom Strategy
                    </label>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-5">
                  <Button
                    variant="default"
                    className="fsize-16"
                    onClick={() => {
                      setAddStrategyModalNew(false);
                      setStrategyModal(false);
                      setStrategySelectorModal('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="default"
                    className="btn border round-10 px-5 fweight-6 fsize-16 secondaryBttn"
                    disabled={StrategySelectorModal === '' ? true : false}
                    onClick={() => {
                      console.log('here');
                      if (StrategySelectorModal === 'custom') {
                        setAddStrategyModalNew(false);
                        setAddStrategyModal(true);
                      } else {
                        setStrategyModal(true);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {/* {console.log("StrategyModal", StrategyModal)} */}
            {StrategyModal === true && (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5>
                    {StrategySelectorModal === 'popular'
                      ? 'Popular Strategy'
                      : ''}
                  </h5>
                  <div className="text-end mb-4">
                    <span
                      onClick={() => {
                        setAddStrategyModalNew(false);
                        setStrategyModal(false);
                        setStrategySelectorModal('');
                        setSelectedStrategy({});
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <CrossIcon className="fsize-25" />
                    </span>
                  </div>
                </div>
                {StrategySelectorModal === 'popular' && (
                  <>
                    <Row>
                      {publicStrategy.map((el, index) => {
                        if (index < 4) {
                          return (
                            <Col key={index}>
                              <div className="border round-20 hpx-130 position-relative p-2">
                                <input
                                  type="radio"
                                  key={el}
                                  name="strategy"
                                  className="predefinedInput"
                                  value={el._id}
                                  hidden
                                  id={el._id}
                                  onChange={(event) => {
                                    let strategy = publicStrategy.filter(
                                      (el) => event.target.value === el._id,
                                    );
                                    console.log(strategy);
                                    setSelectedStrategy(
                                      strategy
                                        ? { ...strategy[0], type: 'Document' }
                                        : {},
                                    );
                                    //console.log("popular", event.target.value);
                                  }}
                                />
                                <label
                                  for={el._id}
                                  className="predefinedLabel d-flex align-items-center justify-content-center"
                                >
                                  <CheckLine className={'d-none checkIcon'} />
                                </label>
                                <p className="mt-4 fsize-14 fweight-6">
                                  {el.name ? el.name : ''}
                                </p>
                                <small className="text-muted fsize-12">
                                  {el.created_at
                                    ? moment(el.created_at).format(
                                        'DD MMM, YYYY h:mm a',
                                      )
                                    : ''}
                                </small>
                                <div>
                                  <small className="fsize-12">
                                    {el.position_type !== '' &&
                                    el.position_type !== null &&
                                    el.position_type !== undefined &&
                                    Array.isArray(el.position_type)
                                      ? el.position_type &&
                                        el.position_type.length > 0
                                        ? el.position_type.map(
                                            (elem, index) => {
                                              if (index < 3) {
                                                if (
                                                  index ===
                                                  el.position_type.length - 1
                                                ) {
                                                  return (
                                                    <span key={index}>
                                                      {elem}
                                                    </span>
                                                  );
                                                } else {
                                                  return (
                                                    <span key={index}>
                                                      {elem} |{' '}
                                                    </span>
                                                  );
                                                }
                                              }
                                            },
                                          )
                                        : ''
                                      : ''}
                                  </small>
                                </div>
                              </div>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </>
                )}
                {selectedStrategy &&
                  Object.keys(selectedStrategy).length > 0 && (
                    <>
                      <Row className="mt-4">
                        <Col>
                          <h6>Strategy Details</h6>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Strategy Name</Form.Label>

                            <Form.Control
                              type="text"
                              placeholder="Strategy Name"
                              name="name"
                              value={
                                selectedStrategy &&
                                Object.keys(selectedStrategy).length > 0
                                  ? selectedStrategy.name
                                  : ''
                              }
                              onChange={HandelChangePopular}
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Strategy Descripition</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Strategy Description"
                              name="description"
                              value={
                                selectedStrategy &&
                                Object.keys(selectedStrategy).length > 0
                                  ? selectedStrategy.description
                                  : ''
                              }
                              onChange={HandelChangePopular}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Strategy Type</Form.Label>
                            <Form.Select
                              onChange={HandelChangePopular}
                              name="type"
                            >
                              <option>Document</option>
                              <option>Script Path</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>What Do Yo Want?</Form.Label>
                            <div className="d-flex align-items-center">
                              <div>
                                <label
                                  for="private"
                                  className="customRadioButton"
                                >
                                  <input
                                    type="radio"
                                    name="is_public"
                                    value="private"
                                    checked={
                                      selectedStrategy &&
                                      Object.keys(selectedStrategy).length > 0
                                        ? selectedStrategy.is_public !== true &&
                                          true
                                        : ''
                                    }
                                    hidden
                                    className="customRadioInput"
                                    id="private"
                                    onChange={HandelChangePopular}
                                  />
                                  <span className="radioMark"></span>
                                  Private
                                </label>
                              </div>
                              <div>
                                <label
                                  for="public"
                                  className="customRadioButton"
                                >
                                  <input
                                    type="radio"
                                    hidden
                                    name="is_public"
                                    value="public"
                                    checked={
                                      selectedStrategy &&
                                      Object.keys(selectedStrategy).length > 0
                                        ? selectedStrategy.is_public === true &&
                                          true
                                        : ''
                                    }
                                    className="customRadioInput"
                                    id="public"
                                    onChange={HandelChangePopular}
                                  />
                                  <span className="radioMark"></span>
                                  Public
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Upload Document</Form.Label>
                            {form_data && form_data.length === 0 && (
                              <div className="d-flex justify-content-center p-5">
                                <label
                                  className="Upload px-4 py-3 round-10"
                                  for="uploadFile"
                                >
                                  <PlusIcon className="fsize-30" />
                                </label>
                                <input
                                  type="file"
                                  id="uploadFile"
                                  name="form_data"
                                  accept={
                                    selectedStrategy.type === 'Document'
                                      ? '.doc,.docx,.pdf,.txt'
                                      : ''
                                  }
                                  hidden
                                  onChange={(e) =>
                                    setForm_data([e.target.files[0]])
                                  }
                                />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      {form_data && form_data.length > 0 ? (
                        <div className="ant-upload-list-item-container mb-2">
                          <div className="ant-upload-list-item ant-upload-list-item-done d-flex align-items-center">
                            <span
                              className="ant-upload-list-item-name text-success"
                              style={{ paddingLeft: '0px' }}
                            >
                              {form_data[0].name ? form_data[0].name : '-'}
                            </span>
                            <span>
                              <DeleteOutlined
                                onClick={() => setForm_data([])}
                              />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center justify-content-between">
                            <p
                              className="textBColor fsize-14"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setStrategyModal(false);
                                setStrategySelectorModal('');
                                setSelectedStrategy({});
                              }}
                            >
                              Cancel
                            </p>
                            <button
                              className="textBColor w-25 ms-auto btn py-2 round-50 hpx-40 fsize-16 fw-semibold secondaryBttn"
                              onClick={(e) =>
                                handelSubmit(e, StrategySelectorModal)
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={successModalShow}
          size={'lg'}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="position-relative">
            <div className="text-end mb-4">
              <span
                onClick={() => setSuccessModalShow(false)}
                style={{ cursor: 'pointer' }}
              >
                <CrossIcon className="fsize-25" />
              </span>
            </div>
            {message === 'Insert successfully.' ? (
              <div className="text-center">
                <h5>Thank you for Submitting your Strategy</h5>
                <div
                  className="mt-4 mb-4 m-auto p-4 round-10"
                  style={{ maxWidth: '50%', background: '#F6F9FF' }}
                >
                  <h6 className="fsize-16">
                    {addStrategy.name !== ''
                      ? addStrategy.name
                      : selectedStrategy.name}
                  </h6>
                  <p className="fsize-14">
                    {addStrategy.description !== ''
                      ? addStrategy.description
                      : selectedStrategy.description}
                  </p>
                </div>
                <p>Your request will be Process in the next 24 hours</p>
                <button
                  onClick={() => {
                    setSuccessModalShow(false);
                    setAddStrategyModalNew(false);
                    setAddStrategyModal(false);
                    setSelectedStrategy({});
                    setForm_data([]);
                  }}
                  className="mt-4 mb-4 textBColor w-25 ms-auto btn py-2 round-50 hpx-40 fsize-16 fw-semibold secondaryBttn"
                >
                  Okay, Got It
                </button>
              </div>
            ) : (
              <p>{message}</p>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={confirmDelete}
          onHide={() => setConfirmDelete(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <Modal.Title>Delete Confirmation</Modal.Title>
              <div className="text-end mb-4">
                <span
                  onClick={() => setConfirmDelete(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <CrossIcon className="fsize-25" />
                </span>
              </div>
            </div>
            <div className="alert alert-danger mt-4">
              Are you sure you want to delete?
            </div>
            <div className="d-flex align-items-center justify-content-end mt-4">
              <Button variant="default" onClick={() => setConfirmDelete(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handelDelete}>
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <PermissionModal
          showModal={permissionModal}
          hideModal={setPermissionModal}
          fetch={getPremium}
        />
        <ShowPermission
          showModal={open}
          hideModal={setOpen}
          loading={loadingPermission}
          permissionDetails={permissionDetails}
        />
      </div>
    </>
  );
}

export default Strategy;
