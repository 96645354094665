import React, { useState, useEffect } from "react";
import mydecodedTokenFunction from "../../utils/decodetoken";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function authGuard(ComposedComponent) {
  const AuthenticationCheck = (props) => {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
      const tokenexist = localStorage.getItem("token");
      const _decodedToken = mydecodedTokenFunction(
        localStorage.getItem("token")
      );
      if (tokenexist && _decodedToken) {
        setIsAuth(true);
      } else {
        props.history.push("/login");
      }
    }, [props.history]);

    if (!isAuth) {
      return (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return <ComposedComponent {...props} />;
    }
  };

  return AuthenticationCheck;
}
