import { parseFullSymbol } from './helpers.js';
import { chartSocket } from './context/socket';
import React, { useEffect } from 'react';
import config from './Config/config.js';

const channelToSubscription = new Map();

function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) {
  // chartSocket.on('connect', () => {
  //   console.log('[socket] Connected');
  // });
  chartSocket.connect();

  chartSocket.on('get_data_by_redis', (event) => {
    // console.log('[socket] data', event);
    let data = event.data;
    // console.log(data);
    //   if (parseInt(eventTypeStr) !== 0) {
    //     // skip all non-TRADE events
    //     return;
    //   }

    const tradePrice = parseFloat(data?.last_price);
    // const tradeTime = parseInt(data?.timestamp);
    if (config.base_url === 'https://api.winraylabs.com') {
      var tradeTime = parseInt((data?.timestamp / 1000 - 19800) * 1000); //winray
    } else {
      var tradeTime = parseInt(data?.timestamp);
    }
    // console.log('time', tradePrice);
    const channelString = `0~${data?.exchange}~${data?.symbol}~${resolution}`;
    //console.log(channelString);
    const subscriptionItem = channelToSubscription.get(channelString);
    //console.log(subscriptionItem);

    if (subscriptionItem === undefined) {
      return;
    }
    const lastDailyBar = subscriptionItem.lastDailyBar;
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

    if (resolution !== '1D') {
      let bar;
      if (tradeTime >= lastDailyBar.time) {
        bar = {
          time: tradeTime,
          open: tradePrice,
          high: tradePrice,
          low: tradePrice,
          close: tradePrice,
        };
        //console.log('asdasdas', tradePrice);
      } else {
        bar = {
          ...lastDailyBar,
          high: Math.max(lastDailyBar.high, tradePrice),
          low: Math.min(lastDailyBar.low, tradePrice),
          close: tradePrice,
        };
        //console.log(tradePrice);
      }
      //console.log(bar);

      subscriptionItem.lastDailyBar = bar;

      // send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    } else {
      let bar;
      bar = {
        ...lastDailyBar,
        high: Math.max(lastDailyBar.high, tradePrice),
        low: Math.min(lastDailyBar.low, tradePrice),
        close: tradePrice,
      };
      //console.log(tradePrice);
      subscriptionItem.lastDailyBar = bar;

      // send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    }
  });

  const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
  //console.log(symbolInfo);
  //console.log(parsedSymbol);
  const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.symbol}~${resolution}`;
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  //console.log(channelToSubscription);
  console.log(
    '[subscribeBars]: Subscribe to streaming. Channel:',
    channelString,
  );

  chartSocket.emit('chat_data_by_redis', {
    stock: parsedSymbol.symbol,
    exchange: parsedSymbol.exchange,
  });
  console.log('here');
}

export function unsubscribeFromStream(subscriberUID) {
  // find a subscription with id === subscriberUID
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID,
    );

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler
        console.log(
          '[unsubscribeBars]: Unsubscribe from streaming. Channel:',
          channelString,
        );
        //socket.emit('SubRemove', { subs: [channelString] });
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
