// Make requests to get stock data's
export async function makeApiRequest(path, timeframe, exchange, page) {
  try {
    const response = await fetch(
      `https://api.charts.sysstra.com/get_continuous_chart_data?exchange=${exchange}&symbol=${path}&timeframe=${timeframe}&page=${page}`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.status);
  }
}

// Make requests to get NFO data's
export async function makeApiRequestForNFO(path, timeframe) {
  try {
    const response = await fetch(
      `https://api.charts.sysstra.com/get_option_chart_data?symbol=${path}&timeframe=${timeframe}`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.status);
  }
}

// Make requests to get symbols
export async function makeApiRequestForSymbol() {
  try {
    const response = await fetch(
      `https://api.charts.sysstra.com/get_all_symbols`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.status);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  //console.log(fullSymbol);
  const match = fullSymbol.split(':');
  //console.log(match);

  if (!match) {
    return null;
  }

  return {
    exchange: match[0],
    symbol: match[1],
  };
}
