import React, { useEffect, useState } from 'react';

import '../Backtesting.css';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  TimePicker,
  Tooltip,
  Alert,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import './Heatmap.css';
import ReactTooltip from 'react-tooltip';
import Header from './Header';
import BarChartIcon from '@mui/icons-material/BarChart';

import config from '../../Config/config';

const { RangePicker } = DatePicker;

const { Option } = Select;
const Heatmap = ({
  from,
  originalData,
  filteredData,
  overallPNL,
  mapData,
  totalTrades,
  profitTrades,
  lossTrades,
}) => {
  let getuserBots = localStorage.getItem('token');
  const [data, setData] = useState();
  let startDate = new Date();
  let endDate = new Date();
  let ordersData = originalData;

  startDate.setFullYear(startDate.getFullYear() - 1);

  // API to get P & L list

  useEffect(() => {
    //console.log(moment(startDate).format("YYYY-MM-DD"));
    let payload = {
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    axios
      .post(`${config.base_url}${config.heatmap}`, payload, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch(function (status) {});
  }, []);
  // console.log(data);

  // Function to calculate P & L value

  const Profit_Loss_Calculation = (value) => {
    var sum = 0;
    //console.log(value);
    const unique = [...new Set(value.map((item) => item.tradingsymbol))];
    var data = {};
    for (let i = 0; i < unique.length; i++) {
      data[unique[i]] = '';
    }
    //console.log(data);
    for (let i = value.length - 1; i >= 0; i--) {
      if (
        value[i].transaction_type === 'BUY' &&
        value[i].status === 'COMPLETE'
      ) {
        let temp = value[i];
        data[temp['tradingsymbol']] = [...data[temp['tradingsymbol']], temp];
      } else if (
        value[i].transaction_type === 'SELL' &&
        value[i].status === 'COMPLETE'
      ) {
        let temp = value[i];
        data[temp['tradingsymbol']] = [...data[temp['tradingsymbol']], temp];
      }
    }
    //console.log(data);
    let profit_loss_data = [];
    let stockData = [];
    var EntryPrice = [];
    for (let obj in data) {
      //console.log(data[obj]);
      var entry = [];
      let type = [];
      let exit = [];
      let quantity = [];

      for (let i = 0; i < data[obj].length; i++) {
        let temp = data[obj];

        if (temp[i].position_type === 'ENTRY') {
          //console.log("here");
          if (temp[i].transaction_type === 'BUY') {
            type.push(temp[i].transaction_type);
            entry.push(temp[i].average_price);
            EntryPrice.push(temp[i].average_price);
            quantity.push(temp[i].quantity);
          } else {
            type.push(temp[i].transaction_type);
            entry.push(temp[i].average_price);
            EntryPrice.push(temp[i].average_price);
            quantity.push(temp[i].quantity);
          }
        } else {
          //console.log("here1");
          exit.push(temp[i].average_price);
          //quantity.push(temp[i].quantity);
        }
      }
      //console.log(type, entry, exit, stock);

      if (entry !== undefined && exit !== undefined) {
        if (exit.length === 1 && entry.length === 1) {
          if (type[0] === 'SELL') {
            if (exit[0] - entry[0] < 0) {
              profit_loss_data.push(
                -(exit[0] - entry[0]).toFixed(2) * quantity[0],
              );
            } else {
              profit_loss_data.push(
                -(exit[0] - entry[0]).toFixed(2) * quantity[0],
              );
            }
          } else {
            profit_loss_data.push(
              (exit[0] - entry[0]).toFixed(2) * quantity[0],
            );
          }
        } else {
          for (let i = 0; i < entry.length; i++) {
            if (type[i] === 'SELL') {
              if (exit[i] - entry[i] < 0) {
                profit_loss_data.push(
                  -(exit[i] - entry[i]).toFixed(2) * quantity[i],
                );
              } else {
                profit_loss_data.push(
                  -(exit[i] - entry[i]).toFixed(2) * quantity[i],
                );
              }
            } else {
              profit_loss_data.push(
                (exit[i] - entry[i]).toFixed(2) * quantity[i],
              );
            }
          }
        }
      }
    }
    // console.log(profit_loss_data);
    // console.log(stockData);
    //console.log(EntryPrice);

    for (let i = 0; i < profit_loss_data.length; i++) {
      if (profit_loss_data[i]) {
        sum += parseFloat(profit_loss_data[i]);
      }
      //console.log(invidualPNL);
    }
    //setPNLvalue(sum.toFixed(2));
    //console.log(invidualPNL);
    //console.log(sum);
    if (!sum) {
      //sum = 0.0;
      return 0.0;
    } else {
      //setChartData(invidualPNL);
      return sum.toFixed(2);
    }
  };

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push({ date: new Date(dt), count: 0 });
    }
    return arr;
  };

  const updatedData = mapData.map((item) => ({
    date: item.date,
    count: item.pnl,
  }));

  //console.log(getDaysArray(startDate, endDate));
  if (data || ordersData) {
    //console.log(ordersData);
    var sum = 0;
    var Profit_trades = 0;
    var loss_trades = 0;
    var max = 0;
    var min = -1;
    var PnL = [...getDaysArray(startDate, endDate), ...updatedData];

    // (from !== 'strategy' ? data : ordersData)?.map((item) => {
    //   let temp = {};
    //   temp['date'] = item[item.length - 1].date;
    //   let pnlArray = [];
    //   for (let i = 0; i < item.length - 1; i++) {
    //     pnlArray.push(item[i]);
    //   }

    //   //console.log(pnl);
    //   // console.log(pnlArray);

    //   let pnl =
    //     from !== 'strategy'
    //       ? Profit_Loss_Calculation(pnlArray.reverse())
    //       : trade_pnl(pnlArray, {}, '', 'strategy');
    //   //console.log(typeof pnl);
    //   console.log(pnl);

    //   if (parseFloat(min) > pnl) {
    //     min = pnl;
    //   }
    //   if (parseFloat(max) < pnl) {
    //     max = pnl;
    //   }
    //   if (pnl < 0) {
    //     loss_trades += 1;
    //   } else if (pnl > 0) {
    //     Profit_trades += 1;
    //   }
    //   sum += parseFloat(pnl);
    //   temp['count'] = pnl;
    //   PnL.push(temp);
    // });
    // overallPNL(sum);
  }
  const pnlValues = updatedData.map((item) => item.count);

  // Find the minimum and maximum values
  min = Math.min(...pnlValues);
  max = Math.max(...pnlValues);

  var total_trades = Profit_trades + loss_trades;

  // if (filteredData) {
  //   var filteredPnL = [...getDaysArray(startDate, endDate)];
  //   filteredData?.map((item) => {
  //     let temp = {};
  //     temp['date'] = item[item.length - 1].date;
  //     let pnlArray = [];
  //     for (let i = 0; i < item.length - 1; i++) {
  //       pnlArray.push(item[i]);
  //     }
  //     let pnl = trade_pnl(pnlArray, {}, '', 'strategy');
  //     if (parseFloat(min) > pnl) {
  //       min = pnl;
  //     }
  //     if (parseFloat(max) < pnl) {
  //       max = pnl;
  //     }
  //     temp['count'] = pnl;
  //     filteredPnL.push(temp);
  //   });
  // }
  // console.log('filteredPnL', filteredPnL);
  // console.log('PnL', PnL);

  // Setting colors
  const colors = [];
  colors.push({ count: 1, color: '#66ff33' });
  colors.push({ count: 2, color: '#ff0000' });
  // console.log(startDate);
  // console.log(PnL);
  // console.log('filteredPnL', filteredData);

  return (
    <>
      {/* {from !== 'strategy' && <Header />} */}

      <div
        // className="pt-4"
        style={{ width: '98%', margin: 'auto' }}
      >
        <div
          style={{
            width: '100%',
            margin: 'auto',
            padding: '10px',
            border: '1px solid rgb(210,210,210)',
            borderRadius: '10px',
            background: '#fff',
          }}
        >
          {from !== 'portfolio' && (
            <h3
              style={{
                borderBottom: '1px solid rgb(210,210,210)',
                paddingBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <BarChartIcon />
                P&L
              </div>
              <div>
                <Button
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '10px',
                  }}
                >
                  Total Trades:
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-success ml-2">{totalTrades}</span>
                  ) : (
                    0
                  )}
                </Button>
                <Button
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '10px',
                  }}
                >
                  Profit Trades:{' '}
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-success ml-2">
                      {profitTrades}
                      {/* {(() =>
                        Math.round((Profit_trades / total_trades) * 100))()} */}
                      {'%'}
                    </span>
                  ) : (
                    0
                  )}
                </Button>
                <Button
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '10px',
                  }}
                >
                  Loss Trades:{' '}
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-danger ml-2">
                      {lossTrades}
                      {/* {(() => Math.round((loss_trades / total_trades) * 100))()} */}
                      {'%'}
                    </span>
                  ) : (
                    0
                  )}
                </Button>
                {/* <Button
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '10px',
                  }}
                >
                  Overall PnL:
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    sum < 0 ? (
                      <span className="text-danger ml-2">
                        {Number(sum).toFixed(2)}
                      </span>
                    ) : (
                      <span className="text-success ml-2">
                        {Number(sum).toFixed(2)}
                      </span>
                    )
                  ) : (
                    0
                  )}
                </Button> */}
              </div>
            </h3>
          )}
          {/* <div className="d-flex mt-4 ">
          <div className="mr-3" style={{ width: "25%" }}>
            <div className="labelDiv">
              <label>Seqment</label>
            </div>
            <Select
              size="large"
              className="mr-3"
              defaultValue="Equity"
              bordered={false}
              //onChange={handleInputFormStrategyExit}
              required
            >
              <Select.Option value="Equity">Equity</Select.Option>
              <Select.Option value="GANNSWING">GANNSWING</Select.Option>
              <Select.Option value="GANN 50%">GANN 50%</Select.Option>
            </Select>
          </div>

          <div className="mr-3" style={{ width: "25%" }}>
            <div className="labelDiv">
              <label>P&L</label>
            </div>

            <Select
              size="large"
              className="mr-3"
              defaultValue="Combined"
              bordered={false}
              //onChange={handleInputFormStrategyExit}
              required
            >
              <Select.Option value="Combined">Combined</Select.Option>
              <Select.Option value="GANNSWING">GANNSWING</Select.Option>
              <Select.Option value="GANN 50%">GANN 50%</Select.Option>
            </Select>
          </div>
          <div className="mr-3" style={{ width: "25%" }}>
            <div className="labelDiv">
              <label>Symbol</label>
            </div>
            <Input placeholder="Symbol" className="mr-3" />
          </div>
          <div className="mr-3" style={{ width: "25%" }}>
            <div className="labelDiv">
              <label>Date Range</label>
            </div>
            <RangePicker onChange={(val) => setValue(val)} className="mr-3" />
          </div>

          <div className="mr-3" style={{ width: "25%" }}>
            <div className="labelDiv">
              <label>Tags</label>
            </div>
            <Select
              className="mr-3"
              showSearch
              size="large"
              defaultValue={[]}
              optionFilterProp="children"
              mode="multiple"
              allowClear={true}
              bordered={false}
              // onChange={handleInputStocks}
              placeholder="Filter By Tags"
            >
              <Option value="Combined">Combined</Option>
            </Select>
          </div>
          <div className="mr-3" style={{ width: "8%" }}>
            <div className="labelDiv">
              <label style={{ visibility: "hidden" }}>Tags</label>
            </div>
            <Button size="large" type="primary">
              <ArrowRightOutlined />
            </Button>
          </div>
        </div> */}

          <div className="mt-4">
            {PnL && PnL.length !== 0 && PnL !== undefined ? (
              <>
                <CalendarHeatmap
                  startDate={startDate}
                  endDate={endDate}
                  values={PnL}
                  classForValue={(value) => {
                    //console.log(value);
                    if (value) {
                      return value.count > 0
                        ? value.count == 0
                          ? `color-empty`
                          : value.count < max / 4
                          ? 'color-scale-1'
                          : value.count > max / 4 &&
                            value.count < max / 4 + max / 4
                          ? 'color-scale-2'
                          : value.count > max / 4 + max / 4 &&
                            value.count < max / 4 + max / 4 + max / 4
                          ? 'color-scale-3'
                          : value.count > max / 4 + max / 4 + max / 4 &&
                            value.count <= max &&
                            'color-scale-4'
                        : value.count == 0
                        ? `color-empty`
                        : Math.abs(value.count) < Math.abs(min) / 4
                        ? 'color-scale-5'
                        : Math.abs(value.count) > Math.abs(min) / 4 &&
                          Math.abs(value.count) <
                            Math.abs(min) / 4 + Math.abs(min) / 4
                        ? 'color-scale-6'
                        : Math.abs(value.count) >
                            Math.abs(min) / 4 + Math.abs(min) / 4 &&
                          Math.abs(value.count) <
                            Math.abs(min) / 4 +
                              Math.abs(min) / 4 +
                              Math.abs(min) / 4
                        ? 'color-scale-7'
                        : Math.abs(value.count) >
                            Math.abs(min) / 4 +
                              Math.abs(min) / 4 +
                              Math.abs(min) / 4 &&
                          Math.abs(value.count) <= Math.abs(min) &&
                          'color-scale-8';
                    }
                  }}
                  tooltipDataAttrs={(value) => {
                    return {
                      'data-tip': `${moment(value.date).format(
                        'DD/MM',
                      )} PnL : ${value.count.toFixed(2)}`,
                    };
                  }}
                  //showOutOfRangeDays={true}
                  showWeekdayLabels={true}
                  addGap={true}
                ></CalendarHeatmap>
                <ReactTooltip />
              </>
            ) : (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <div className="d-flex">
              <h6 className="mr-4 d-flex align-items-center">
                Profit:{' '}
                <Tooltip title={'Profit : 1'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#9fc891',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 2'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#71c655',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 3'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#4cac29',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 4'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#329b0c',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
              </h6>
              <h6 className="mr-4 d-flex align-items-center">
                Loss:{' '}
                <Tooltip title={'Loss : 1'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#cf8c8c',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 2'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#db5757',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 3'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#dd3434',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 4'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#c60707',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Heatmap);
