import {AUTOMATION_SELECTION_STOCK} from "../types/hometypes"

const getstocklistReducer = (state = [], { type, payload }) => {
    switch (type) {
      case AUTOMATION_SELECTION_STOCK:
        return {
          ...state,
          stock_list: payload,
        };
      default:
        return state;
    }
  };
  
  export default getstocklistReducer;