import axios from 'axios';
import React from 'react';
import config from '../Config/config';
import { toast } from 'react-toastify'; // Assuming you have a toast library installed

// Load the token from localStorage
let getuserBots = localStorage.getItem('token');

const ExitTradeApi = (payload, tab) => {
  console.log(payload, tab);

  axios
    .post(
      `${config.Kill_trade_base_url}${
        tab === 'live' ? config.exit_LT : config.exit_VT
      }`,
      payload,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    )
    .then((res) => {
      if (res) {
        toast.success('Exited Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    })
    .catch(function (err) {
      console.log(err);
    });
};

export default ExitTradeApi;
