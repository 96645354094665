import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const PermissionModal = ({ showModal, hideModal, fetch }) => {
  // console.log(id,5555);
  return (
    <>
      <Modal show={showModal} onHide={hideModal} style={{ marginTop: '200px' }}>
        <Modal.Header closeButton>
          <Modal.Title>Ask Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success">
            Are you sure you want to request for permission?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={fetch}>
            Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PermissionModal;
