import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ShowPermission = ({
  showModal,
  hideModal,
  permissionDetails,
  loading,
}) => {
  return (
    <>
      <Modal show={showModal} onHide={hideModal} style={{ marginTop: '200px' }}>
        <Modal.Body>
          <>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ textAlign: 'center', minHeight: '50vh' }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                      size="small"
                    />
                  }
                />
              </div>
            ) : (
              <Result
                style={{
                  margin: 'auto',
                }}
                status="error"
                title="Permission Denied"
                subTitle={[
                  permissionDetails?.message === 'Insert successfully.' ? (
                    <div key={1}>
                      <p>You don't have permission to view this strategy.</p>
                      <p>
                        A request has been sent to the admin for permission.
                      </p>
                    </div>
                  ) : (
                    permissionDetails?.message ===
                      'Permission request already sent.' && (
                      <div key={2}>
                        <p>
                          Your request for permission to view this strategy is
                          pending.
                        </p>
                        <p>Waiting for the admin to accept your request.</p>
                      </div>
                    )
                  ),
                ]}
              ></Result>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={() => hideModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowPermission;
