import { useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import OTP from './components/opt';
import { Provider } from 'react-redux';
import store from './redux/store';
import mydecodedTokenFunction from '../src/utils/decodetoken';
import { Redirect } from 'react-router-dom';
import BackTesting from './components/UI/BackTesting';
// import BacktestingReport from './components/UI/BacktestingReport';
import AuthGuard from './components/hoc/AuthGuard';
import ChartPage from './components/UI/ChartPage';
import HeatMap from '../src/components/UI/Heatmap';
import Strategy from './components/UI/Strategy/Strategy';
import Broker from './components/UI/Broker/Broker';
import UserLogin from '../src/Login/user-Login/Login';
import Signup from './components/SignUP/SignUp';
import SignupSuccess from './components/SignUP/SignUPSuccess';
import ForgotPassword from './Login/user-Login/ForgotPassword';
import ExpiredLink from './Login/user-Login/ExpiredLink_page';
import ResetPassword from './Login/user-Login/ResetPassword';
import SetupInstruction from './components/UI/Broker/Instructions/SetupInstruction';
import ZerodhaInstructions from './components/UI/Broker/Instructions/ZerodhaInstructions';
import AliceInstructions from './components/UI/Broker/Instructions/AliceInstructions';
import AngelInstructions from './components/UI/Broker/Instructions/AngelOneInstructions';
import FlatTradeInstructions from './components/UI/Broker/Instructions/FlatTradeInstructions';
import FyresInstructions from './components/UI/Broker/Instructions/FyresInstructions';
import PaiseInstructions from './components/UI/Broker/Instructions/5PaisaInstructions';
import DhanInstructions from './components/UI/Broker/Instructions/DhanInstructions';
import ViewVTReport from './components/UI/ViewVTReport';
import VirtualPortfolio from './components/UI/Portfolio/VirtualPortfolio';
import StrategyReport from './components/UI/StrategyNew/StrategyReport';

let _decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));

var search = 'MUI X Unlicensed product';
var replacement = ' ';
document.body.innerHTML = document.body.innerHTML
  .split(search)
  .join(replacement);
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      _decodedToken === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);

function App() {
  const [alertNum, setAlertNum] = useState(0);
  const [alertNumOrder, setAlertNumOrder] = useState(0);
  const tokenexist = localStorage.getItem('token');
  // console.log('decoded Token', _decodedToken, tokenexist);

  const onAlertNum = (val) => {
    setAlertNum(val);
  };

  const onAlertNumOrder = (val) => {
    setAlertNumOrder(val);
  };

  return (
    <>
      <Provider store={store}>
        {/* <SocketContext.Provider value={socket}> */}
        <BrowserRouter>
          {/* {_decodedToken && (
              <Header alertNum={alertNum} alertNumOrder={alertNumOrder} />
            )} */}
          <Switch>
            <Route
              path="/broker/instructions/zerodha"
              component={AuthGuard(ZerodhaInstructions)}
            />
            <Route
              path="/broker/instructions/aliceBlue"
              component={AuthGuard(AliceInstructions)}
            />
            <Route
              path="/broker/instructions/angelone"
              component={AuthGuard(AngelInstructions)}
            />
            <Route
              path="/broker/instructions/flatTrade"
              component={AuthGuard(FlatTradeInstructions)}
            />
            <Route
              path="/broker/instructions/fyres"
              component={AuthGuard(FyresInstructions)}
            />
            <Route
              path="/broker/instructions/paisa"
              component={AuthGuard(PaiseInstructions)}
            />
            <Route
              path="/broker/instructions/dhan"
              component={AuthGuard(DhanInstructions)}
            />
            <Route
              path="/broker/instructions"
              component={AuthGuard(SetupInstruction)}
            />

            {/* <Route
              path="/backtesting-report"
              component={AuthGuard(BacktestingReport)}
            /> */}
            <Route
              path="/view-report/:tab/:id"
              component={AuthGuard(ViewVTReport)}
            />

            <Route path="/backtesting" component={AuthGuard(BackTesting)} />

            <Route path="/profit-n-loss" component={AuthGuard(HeatMap)} />

            <Route path="/chart" component={AuthGuard(ChartPage)} />

            {/* <Route path="/engulfing" component={Engulfing} /> */}
            <Route path="/strategies" component={AuthGuard(Strategy)} />
            {/* <Route
                path="/strategy/:id"
                component={AuthGuard(StrategyDetails)}
              /> */}
            <Route path="/strategy/:id" component={AuthGuard(StrategyReport)} />
            <Route path="/broker" component={AuthGuard(Broker)} />

            <Route
              path="/portfolio/live"
              component={AuthGuard(VirtualPortfolio)}
            />
            <Route
              path="/portfolio/virtual"
              component={AuthGuard(VirtualPortfolio)}
            />
            {/* <Route path="/liveportfolio" component={AuthGuard(LivePortfolio)} /> */}
            {/* <Route path="/reportsnew" component={AuthGuard(ReportsNew)} /> */}
            {/* <Route path="/strategynew" component={AuthGuard(StrategyNew)} /> */}

            <Route
              path="/user-reset-password/:token"
              component={ResetPassword}
            />

            <Route path="/otp" component={OTP} />
            <Route path="/login" component={UserLogin} />
            <Route path="/signup" component={Signup} />
            <Route path="/success" component={SignupSuccess} />
            <Route path="/user-forgot-password" component={ForgotPassword} />
            <Route path="/user-expired_link" component={ExpiredLink} />

            <Route
              path="/"
              render={() =>
                tokenexist && _decodedToken ? (
                  <Redirect to="/portfolio/virtual" />
                ) : (
                  <UserLogin />
                )
              }
            />
          </Switch>
        </BrowserRouter>
        {/* </SocketContext.Provider> */}
      </Provider>
    </>
  );
}

export default App;
