import { Button, Checkbox, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import dark_logo from '../../assets/images/logo_black.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../Config/config';

function SignupSuccess() {
  let email = JSON.parse(localStorage.getItem('EmailID'));
  //console.log(email);

  // Resend email verify link
  const ResendLink = () => {
    axios
      .get(`${config.base_url}${config.verify_email}/${email}`)
      .then((res) => {
        console.log('resendlink', res);
        return toast.success(res.data.data, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-6">
                <div className=" text-center mt-5">
                  <h3 className="mb-3">
                    Thank you, a verification Email is on its way
                  </h3>
                  <p className="text-secondary">
                    Please follow last step from your inbox.
                  </p>
                  <p className="mb-3 text-secondary">
                    Verify your email address and continue to Futuristic
                    platform.
                  </p>
                  <p className="text-secondary">
                    Thanks, you can close this window now.
                  </p>
                  <p className="text-secondary">
                    Click on the below button to redirect to Login Page.
                  </p>
                  <div className="mt-4 mb-4 text-center">
                    <Link
                      type="primary"
                      to={'/'}
                      className="allBtns m-auto py-2 textWColor fsize-14"
                    >
                      CLICK HERE TO LOG IN
                    </Link>
                  </div>

                  <p className="text-secondary">
                    Didn't receive the email ?{' '}
                    <a
                      href="#"
                      className="ndHl me-3 text-primary"
                      onClick={() => ResendLink()}
                    >
                      Re-Send Link
                    </a>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignupSuccess;
