import { decodeToken, isExpired } from "react-jwt";
// const userInfo = localStorage.getItem("userInfo");
// const token = userInfo ? JSON.parse(userInfo).token : "";

// console.log(isExpired(token));
//let mydecodedToken = isExpired(token) ? false : decodeToken(token);

export const mydecodedTokenFunction = (token) => {
  const decodedToken = isExpired(token) ? false : decodeToken(token);
  //console.log(decodeToken);
  // mydecodedToken = decodedToken;
  return decodedToken;
};

//console.log(mydecodedToken);

// export { token, mydecodedToken };
export default mydecodedTokenFunction;
