export function Delete({ className, ondel, id, confirmDelete }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 23 28"
      fill="currentColor"
      onClick={() => {
        //console.log("here")
        ondel(id);
        confirmDelete(true);
      }}
    >
      <path
        d="M11.482 0C13.9813 0 16.0184 1.97895 16.1118 4.45538L16.1151 4.63309H21.9568C22.5131 4.63309 22.964 5.08403 22.964 5.64029C22.964 6.16178 22.5677 6.5907 22.0598 6.64228L21.9568 6.64748H21.0778L19.7054 24.8333C19.5706 26.6195 18.082 28 16.2907 28H6.67336C4.88205 28 3.39342 26.6195 3.25861 24.8333L1.88547 6.64748H1.00719C0.485702 6.64748 0.0567784 6.25115 0.00519995 5.74327L0 5.64029C0 5.1188 0.396331 4.68987 0.904215 4.63829L1.00719 4.63309H6.84892C6.84892 2.07431 8.92323 0 11.482 0ZM19.0578 6.64748H3.90622L5.26729 24.6817C5.3228 25.4172 5.93576 25.9856 6.67336 25.9856H16.2907C17.0283 25.9856 17.6412 25.4172 17.6967 24.6817L19.0578 6.64748ZM14.1007 10.8777C14.6222 10.8777 15.0511 11.274 15.1027 11.7819L15.1079 11.8849V20.7482C15.1079 21.3045 14.657 21.7554 14.1007 21.7554C13.5792 21.7554 13.1503 21.3591 13.0987 20.8512L13.0935 20.7482V11.8849C13.0935 11.3286 13.5445 10.8777 14.1007 10.8777ZM8.86331 10.8777C9.3848 10.8777 9.81372 11.274 9.8653 11.7819L9.8705 11.8849V20.7482C9.8705 21.3045 9.41957 21.7554 8.86331 21.7554C8.34182 21.7554 7.91289 21.3591 7.86132 20.8512L7.85612 20.7482V11.8849C7.85612 11.3286 8.30705 10.8777 8.86331 10.8777ZM11.482 2.01439C10.0856 2.01439 8.94449 3.10736 8.86745 4.48449L8.86331 4.63309H14.1007C14.1007 3.18682 12.9283 2.01439 11.482 2.01439Z"
        // fill="#D8D7D7"
      />
    </svg>
  );
}

export function PlusIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
    </svg>
  );
}

export function CrossIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
    </svg>
  );
}

export function RightArrow({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
    </svg>
  );
}

export function Calendar({ className }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38.8999 2.8H33.9999V0.7C33.9999 0.3129 33.687 0 33.2999 0H28.3999C28.0128 0 27.6999 0.3129 27.6999 0.7V2.8H12.2999V0.7C12.2999 0.3129 11.987 0 11.5999 0H6.6999C6.3128 0 5.9999 0.3129 5.9999 0.7V2.8H1.0999C0.712802 2.8 0.399902 3.1129 0.399902 3.5V11.2V41.3C0.399902 41.6871 0.712802 42 1.0999 42H38.8999C39.287 42 39.5999 41.6871 39.5999 41.3V11.2V3.5C39.5999 3.1129 39.287 2.8 38.8999 2.8ZM29.0999 1.4H32.5999V3.5V5.6H29.0999V3.5V1.4ZM7.3999 1.4H10.8999V3.5V5.6H7.3999V3.5V1.4ZM1.7999 4.2H5.9999V6.3C5.9999 6.6871 6.3128 7 6.6999 7H11.5999C11.987 7 12.2999 6.6871 12.2999 6.3V4.2H27.6999V6.3C27.6999 6.6871 28.0128 7 28.3999 7H33.2999C33.687 7 33.9999 6.6871 33.9999 6.3V4.2H38.1999V10.5H1.7999V4.2ZM1.7999 40.6V11.9H38.1999V40.6H1.7999Z" />
      <path d="M25.6 16.1001H20.7H19.3H14.4H13H6.69995V22.4001V23.8001V28.7001V30.1001V36.4001H13H14.4H19.3H20.7H25.6H27H33.3V30.1001V28.7001V23.8001V22.4001V16.1001H27H25.6ZM20.7 17.5001H25.6V22.4001H20.7V17.5001ZM25.6 28.7001H20.7V23.8001H25.6V28.7001ZM14.4 23.8001H19.3V28.7001H14.4V23.8001ZM14.4 17.5001H19.3V22.4001H14.4V17.5001ZM8.09995 17.5001H13V22.4001H8.09995V17.5001ZM8.09995 23.8001H13V28.7001H8.09995V23.8001ZM13 35.0001H8.09995V30.1001H13V35.0001ZM19.3 35.0001H14.4V30.1001H19.3V35.0001ZM25.6 35.0001H20.7V30.1001H25.6V35.0001ZM31.9 35.0001H27V30.1001H31.9V35.0001ZM31.9 28.7001H27V23.8001H31.9V28.7001ZM31.9 17.5001V22.4001H27V17.5001H31.9Z" />
    </svg>
  );
}

export function UpArrow({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      className={className}
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="17 8 12 3 7 8"></polyline>
      <line x1="12" y1="3" x2="12" y2="15"></line>
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    </svg>
  );
}

export function DownArrow({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      className={className}
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  );
}

export function CheckLine({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className={className}
    >
      <path
        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
        fill="rgba(255,255,255,1)"
      />
    </svg>
  );
}

export function BsInfoCircle({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
    </svg>
  );
}

export function BsBell({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 34 45"
      fill="currentColor"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2418 2.27848C19.2418 1.02012 18.2381 0 17 0C15.7618 0 14.7582 1.02012 14.7582 2.27848V3.98734H13.5086C8.52041 3.98734 4.39191 7.92914 4.08055 12.989L3.58509 21.0399C3.3962 24.1095 2.38225 27.0677 0.653694 29.592C-0.908122 31.8728 0.482287 35.023 3.19695 35.3541L10.835 36.2857V38.7342C10.835 42.1947 13.5951 45 17 45C20.4048 45 23.1649 42.1947 23.1649 38.7342V36.2857L30.803 35.3541C33.5178 35.023 34.9081 31.8728 33.3463 29.592C31.6176 27.0677 30.6037 24.1095 30.4149 21.0399L29.9195 12.989C29.6081 7.92914 25.4796 3.98734 20.4914 3.98734H19.2418V2.27848ZM13.5086 7.40506C10.2961 7.40506 7.63721 9.94368 7.43668 13.2024L6.94124 21.2532C6.71392 24.9471 5.49382 28.5065 3.41377 31.5442C3.3009 31.7092 3.40138 31.9368 3.5976 31.9607L11.9767 32.9826C15.3135 33.3895 18.6865 33.3895 22.0232 32.9826L30.4023 31.9607C30.5985 31.9368 30.6992 31.7092 30.5862 31.5442C28.5062 28.5065 27.286 24.9471 27.0587 21.2532L26.5633 13.2024C26.3626 9.94368 23.7039 7.40506 20.4914 7.40506H13.5086ZM17 41.5823C15.4522 41.5823 14.1977 40.3072 14.1977 38.7342V37.0253H19.8022V38.7342C19.8022 40.3072 18.5477 41.5823 17 41.5823Z"
      />
    </svg>
  );
}

export function EditableBttn({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 22 21"
      fill="currentColor"
      className={className}
    >
      <path d="M9.86084 6.22003L7.82355 8.25732C7.07297 9.0079 6.3181 9.75848 5.57181 10.5176C5.39596 10.6978 5.263 10.938 5.21153 11.1739C4.94132 12.4134 4.67969 13.6529 4.41806 14.8925L4.27223 15.5787C4.20361 15.9132 4.29368 16.2478 4.521 16.4751C4.69685 16.651 4.94132 16.7496 5.19866 16.7496C5.27158 16.7496 5.34449 16.741 5.41741 16.7282L6.17227 16.5695C7.3732 16.3164 8.57842 16.0591 9.77935 15.8103C10.0581 15.7503 10.294 15.6259 10.4956 15.4243C13.8668 12.0488 17.238 8.67764 20.6092 5.31076C20.8579 5.06199 20.9952 4.76605 21.0295 4.40148C21.0338 4.34143 21.0295 4.28139 21.0166 4.22563C20.9952 4.13985 20.978 4.04978 20.9566 3.964C20.9094 3.74526 20.8579 3.4965 20.755 3.25631C20.1245 1.8152 19.078 0.785828 17.6454 0.206809C17.3581 0.0910052 17.0535 0.0524039 16.7876 0.0180917L16.7147 0.00951359C16.3287 -0.0376657 15.977 0.0910052 15.6725 0.399815C13.7424 2.33846 11.7995 4.28139 9.86084 6.22003ZM16.5732 1.04317C16.5818 1.04317 16.586 1.04317 16.5946 1.04317L16.6675 1.05175C16.8906 1.07748 17.0964 1.10322 17.2594 1.17184C18.426 1.64363 19.2838 2.48428 19.8028 3.66806C19.8629 3.80531 19.9015 3.98116 19.9401 4.16558C19.9529 4.23421 19.9701 4.30283 19.983 4.37146C19.9658 4.44866 19.9358 4.50013 19.8757 4.55589C16.5003 7.92278 13.1291 11.2982 9.7579 14.6694C9.70215 14.7252 9.65068 14.7509 9.57348 14.7681C8.36826 15.0211 7.16733 15.2742 5.96211 15.5272L5.32734 15.6602L5.44743 15.0983C5.70906 13.8631 5.97069 12.6236 6.23661 11.3883C6.24519 11.3497 6.2795 11.2897 6.3181 11.2468C7.06439 10.4919 7.81068 9.74132 8.56126 8.99503L10.5986 6.95774C12.5415 5.01481 14.4844 3.07188 16.4231 1.12466C16.5045 1.05604 16.5432 1.04317 16.5732 1.04317Z" />
      <path d="M1.66843 4.67592H9.15708C9.44873 4.67592 9.68463 4.44002 9.68463 4.14837C9.68463 3.85672 9.44873 3.62082 9.15708 3.62082H1.66843C0.746291 3.62082 0 4.3714 0 5.28925V19.3315C0 20.2537 0.75058 21 1.66843 21H15.7064C16.6286 21 17.3749 20.2494 17.3749 19.3315V12.1474C17.3749 11.8558 17.139 11.6199 16.8473 11.6199C16.5557 11.6199 16.3198 11.8558 16.3198 12.1474V19.3315C16.3198 19.6704 16.041 19.9492 15.7021 19.9492H1.66843C1.3296 19.9492 1.05081 19.6704 1.05081 19.3315V5.29354C1.05081 4.95471 1.3296 4.67592 1.66843 4.67592Z" />
    </svg>
  );
}
