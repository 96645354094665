import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import zerodha1 from "../../../../assets/images/zerodha1.png";
import zerodha2 from "../../../../assets/images/zerodha2.png";
import zerodha3 from "../../../../assets/images/zerodha3.png";
import Header from "../../Header";

function ZerodhaInstructions() {
  document.querySelector("body").style.overflow = "hidden";
  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-Zerodha-API-key"
                      role="tab"
                      aria-controls="v-pills-Zerodha-API-key"
                      aria-selected="true"
                    >
                      Steps to get Zerodha API key
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-TOTP"
                      role="tab"
                      aria-controls="v-pills-TOTP"
                      aria-selected="false"
                    >
                      Step to create TOTP
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-Zerodha-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get Zerodha API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p>
                              Sign up on Signup / Kite Connect developer to
                              create a Kite Connect developer account.
                            </p>
                          </li>
                          <li>
                            <p>
                              Log in to your Kite Connect developer account.
                            </p>
                            <p>
                              Url :{" "}
                              <a
                                href="https://developers.kite.trade/login"
                                target="_blank"
                              >
                                Login / Kite Connect developer
                              </a>
                            </p>
                            <div className="mt-4 mb-4">
                              <img
                                src={zerodha1}
                                alt="Kite Connect Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Click on Create App.
                            <div className="mt-4 mb-4">
                              <img
                                src={zerodha2}
                                alt="create App Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Enter the Zerodha Client ID, Redirect URL, and
                            Postback URL.
                            <div className="mt-4 mb-4">
                              <img
                                src={zerodha3}
                                alt="Api details Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>Give a name to the app and describe it.</li>
                          <li>Click on create.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-TOTP"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="mb-4">
                      <h4>Step to create TOTP:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p>Login to kite.zerodha.com.</p>
                          </li>
                          <li>
                            <p>Click on the Client ID.</p>
                          </li>
                          <li>
                            <p>Click on My profile / Settings.</p>
                          </li>
                          <li>
                            <p>Click on Password & Security.</p>
                          </li>
                          <li>
                            <p>Click on Enable External TOTP.</p>
                          </li>
                          <li>
                            <p>
                              Enter the OTP received on the email and click on
                              Verify.
                            </p>
                          </li>
                          <li>
                            <p>Enter the TOTP and kite login password.</p>
                          </li>
                          <li>
                            <p>Click on Enable.</p>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ZerodhaInstructions;
