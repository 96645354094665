import React, { useState, useEffect } from 'react';
import { Space, Switch, Table, Tag } from 'antd';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function PortfolioDesktopTable({
  tradeOrder,
  pagination,
  handleTableChange,
  from,
  tab,
}) {
  const tradeColumns = [
    {
      title: 'Strategy',
      dataIndex: 'st_name',
      render: (text, record) => (
        <Link to={`/strategy/${record.strategy_id}`} className="primary__blue">
          {text}
        </Link>
      ),
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      render: (text, record) => (
        <Link
          to={`/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
            record.request_id
          }`}
          className="primary__blue"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'stock',
    },
    {
      title: 'Entry Time',
      dataIndex: 'entry_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },

    {
      title: 'Entry Price',
      dataIndex: 'entry_price',
    },

    {
      title: 'Exit Price',
      dataIndex: 'exit_price',
    },
    {
      title: 'Exit Time',
      dataIndex: 'exit_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },

    {
      title: 'Exit Type',
      dataIndex: 'exit_type',
    },
    {
      title: 'QTY.',
      dataIndex: 'quantity',
    },
    {
      title: 'P&L',
      key: 'pnl',
      dataIndex: 'pnl',
      render: (pnl) => (
        <span>
          {pnl > 0 ? (
            <BsArrowUp className="text-success" />
          ) : (
            <BsArrowDown className="text-danger" />
          )}
          {
            <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
              {pnl}
            </span>
          }
        </span>
      ),
    },
    {
      title: 'Brokerage',
      dataIndex: 'brokerage',
    },
  ];

  const closedColumns = [
    {
      title: 'Strategy',
      dataIndex: 'strategys',
      render: (text, record) => (
        <Link to={`/strategy/${record.strategy_id}`} className="primary__blue">
          {text}
        </Link>
      ),
    },
    {
      title: 'Report Name',
      dataIndex: 'vt_requests',
      render: (text, record) => (
        <Link
          to={`/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
            record.request_id
          }`}
          className="primary__blue"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'tradingsymbol',
    },
    {
      title: 'Order Time',
      dataIndex: 'order_timestamp',
      render: (text) => dayjs.utc(text).format('DD-MMM-YYYY hh:mm:ss a'),
    },
    {
      title: 'QTY.',
      dataIndex: 'quantity',
    },
    {
      title: 'Lot Size',
      dataIndex: 'lot_size',
    },
    {
      title: 'Trigger Price',
      dataIndex: 'trigger_price',
    },
    {
      title: 'Trade Action',
      dataIndex: 'trade_action',
    },

    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type',
    },
    {
      title: 'Exit Type',
      dataIndex: 'exit_type',
      render: (text) => (text ? text : '-'),
    },
  ];
  // console.log('called');

  return (
    <div>
      <Table
        columns={from === 'close' ? closedColumns : tradeColumns}
        dataSource={tradeOrder}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey={tradeOrder._id}
      />
    </div>
  );
}

export default React.memo(PortfolioDesktopTable);
