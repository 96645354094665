import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import getstocklistReducer from "../redux/reducers/homereducers"

const rootReducers = combineReducers({
    getstocklistReducer
});

const store = createStore(rootReducers, composeWithDevTools());

export default store;
