const config = {
  base_url: process.env.REACT_APP_PRODUCTION_BASEURL,
  Kill_trade_base_url: process.env.REACT_APP_PRODUCTION_ExitTrade_BASEURL,
  Socket_base_url: process.env.REACT_APP_PRODUCTION_SOCKETURL,
  //prod api starts
  //delete api
  virtual_trade_delete: '/virtual-trade',
  live_trade_delete: '/live-trade',
  BT_trade_delete: '/admin/delete-requests',

  //signUP
  sign_up: '/admin/add-user',
  verify_email: '/admin/resend-email-verify',

  //login
  login: '/user/signin',

  //forgot password
  forgot_pass: '/admin/forgot-password-mail',
  resend_email_pass: '/admin/resend-email-forgotpass',

  //reset password
  reset_pass: '/admin/reset-password',

  //user_list
  user_list: '/admin/user-list',
  add_user: '/admin/add-user',
  user_by_id: '/admin/user-by-id',
  update_user: '/admin/update-user',
  change_user_email: '/admin/change-user-email',
  change_user_pass: '/admin/change-user-pass',

  //virtual trade
  virtual_trade: '/virtual-trade',
  list_virtual_trade: '/virtual-trade/list?page=',

  //backtesting
  add_request: '/admin/add-requests',

  //live trade
  live_trade: '/live-trade',
  list_live_trade: '/live-trade/list?page=',

  //all stocks
  all_stocks: '/user/all-stock-list',

  //get request list
  get_request_list: '/admin/get-requests-list',
  get_request: '/admin/get-requests',

  //change user password:
  change_user_pass: '/admin/change-user-pass',

  //HEAT MAP p and l list
  heatmap: '/user/p-and-l-list',

  //candles
  b_candels: '/brokers/candles',

  //broker
  broker: '/brokers',
  broker_crendientials: '/brokers/credential',

  //strategy
  get_strategy: '/admin/strategy',
  strategy_permission: '/admin/add-st-permission',

  //Kill trade
  exit_LT: '/kill_live',
  exit_VT: '/kill_virtual',

  //Play Pause
  play: '/request_status',

  //Exit trade
  // exit_trade: 'https://orders.api.winraylabs.com',
};

export default config;
