import React from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import Accordion from 'react-bootstrap/Accordion';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

const ReportOpenTable = ({ tradeOrder }) => {
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  // console.log('tradeOrder', tradeOrder);
  return (
    <div>
      <div className="">
        <div className="tbl__hd mob__strategy report">
          <div className="coll">Symbol</div>
          <div className="coll text-center">QTY</div>
          <div className="coll">P&L</div>
          <div className="coll">Charge</div>
        </div>
      </div>
      <Accordion className="cstm__accordion">
        {tradeOrder?.map((item) => (
          <Accordion.Item eventKey={item?.request_id}>
            <Accordion.Header>
              <div className="tbl__body mob__strategy report">
                <Tooltip title={item?.tradingsymbol} placement="top">
                  <div className="coll text-truncate">
                    {item?.tradingsymbol}
                  </div>
                </Tooltip>
                <div className="coll text-truncate text-center">{item?.quantity_left}</div>
                <div className="coll text-truncate">
                  <span
                    className={item?.pnl > 0 ? 'text-success' : 'text-danger'}
                  >
                    {item?.pnl}
                  </span>
                  <span>
                    {item?.pnl > 0 ? (
                      <BsArrowUp className="text-success" />
                    ) : (
                      <BsArrowDown className="text-danger" />
                    )}
                  </span>
                </div>
                <div className="coll text-truncate">{item?.charges}</div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="tbl__body__inner mob__strategy report">
                <div className="coll">Order Time</div>
                <div className="coll">Lot</div>
                <div className="coll">Avg.</div>
                <div className="coll">LTP</div>
              </div>

              <div className="tbl__body__inner__data mob__strategy report">
                <Tooltip
                  title={dayjs
                    .utc(item?.entryTime)
                    .format('DD-MMM-YYYY hh:mm:ss a')}
                  placement="top"
                >
                  <div className="coll text-truncate">
                    {dayjs
                      .utc(item?.entryTime)
                      .format('DD-MMM-YYYY hh:mm:ss a')}
                  </div>
                </Tooltip>
                <div className="coll text-truncate">{item?.lot_size}</div>
                <div className="coll text-truncate">{item?.entryPrice}</div>
                <div className="coll text-truncate">{item?.livePrice}</div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default ReportOpenTable;
