import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Tooltip, Table, DatePicker } from 'antd';
import { Link } from '@mui/material';
import { useHistory } from 'react-router-dom';
import config from '../../Config/config';
import { valueSocket } from '../../context/socket';
import BackTesting from './BackTesting';
import dayjs from 'dayjs';
import Sidebar from './Sidebar';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { calculateBrokerage } from '../../utils/BrokerCharges';
import ReportOpenTable from '../../utils/ReportOpenTable';
import ReportClosedTable from '../../utils/ReportClosedTable';
import PortfolioClosedTable from '../../utils/PortfolioClosedTable';
import Heatmap from './Heatmap';
import ReportTradesTable from '../../utils/ReportTrades';
import DateTime from '../Common/DateTime';
import ReportIcons from '../ReportIcons';
import ReportTabs from '../ReportTabs';
import ReportCardsData from '../ReportCardsData';

const openColumns = [
  {
    title: 'Symbol',
    dataIndex: 'tradingsymbol',
  },
  {
    title: 'Order Time',
    dataIndex: 'entryTime',
    render: (entryTime) =>
      dayjs.utc(entryTime).format('DD-MMM-YYYY hh:mm:ss a'),
  },
  {
    title: 'Lot Size',
    dataIndex: 'lot_size',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity_left',
  },
  {
    title: 'Avg.',
    dataIndex: 'entryPrice',
  },
  {
    title: 'LTP',
    dataIndex: 'livePrice',
  },
  {
    title: 'P&L',
    key: 'pnl',
    dataIndex: 'pnl',
    render: (pnl) => (
      <span>
        {pnl > 0 ? (
          <BsArrowUp className="text-success" />
        ) : (
          <BsArrowDown className="text-danger" />
        )}
        {
          <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
            {pnl}
          </span>
        }
      </span>
    ),
  },
  {
    title: 'Charge',
    dataIndex: 'charges',
    render: (text) => <span className="pnl_charge">{text}</span>,
  },
];

const ViewVTReport = () => {
  const { RangePicker } = DatePicker;
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  let getuserBots = localStorage.getItem('token');
  let { id, tab } = useParams();
  let history = useHistory();
  const [configData, setConfigData] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [TradeSetting, setTradeSettingSwitch] = useState(false);
  const [vtData, setVTData] = useState();
  const [ltData, setLTData] = useState();
  const [fundBalance, setFundBalance] = useState(null);
  const [strategyName, setStrategyName] = useState('');
  const [overAllPnL, setoverAllPnL] = useState('');
  const [todayPnL, setTodayPnL] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [tableDate, setTableDate] = useState([]);
  const [toggleState, setToggleState] = useState('openOrder');
  const [mobileTrades, setMobileTrades] = useState([]);
  const [currentPage, setCurrentPage] = useState(2);
  const [amountInvested, setAmountInvested] = useState(0);
  const [openOrder, setOpenOrder] = useState([]);
  const [lastOrder, setLastOrder] = useState([]);
  const [playPauseID, setPlayPauseID] = useState('');
  const [strategyData, setStrategyData] = useState([]);
  const [strategyID, setStrategyID] = useState('');
  const [desktopTrades, setDesktopTrades] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [brokerID, setBrokerID] = useState('');
  const [inputID, setInputID] = useState('');
  const [reportStatus, setReportStatus] = useState(false);
  const [newOrder, setNewOrder] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [tradeSymbols, setTradeSymbols] = useState([]);
  const [brokerStatus, setBrokerStatus] = useState('');
  const [heatmapData, setHeatmapData] = useState([]);
  const [totalTrades, setTotalTrades] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [lossPercentage, setLossPercentage] = useState(0);
  const [desktopClosedOrders, setDesktopClosedOrders] = useState([]);
  const [mobileClosedOrders, setMobileClosedOrders] = useState([]);
  const [brokerName, setBrokerName] = useState('');

  //Responsiveness
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  //Calender Date Change
  const HandleDateChange = (date, dateString) => {
    setMobileTrades([]);
    setMobileClosedOrders([]);
    setCurrentPage(2);
    if (dateString[0] !== '' && dateString[1] !== '' && dateString) {
      setTableDate(dateString);
      if (isMobile) {
        if (toggleState === 'trades') {
          callTrades('', dateString, 1);
        }
        if (toggleState === 'closeOrder') {
          callCloseOrders('', dateString, 1);
        }
      } else {
        if (toggleState === 'closeOrder') {
          getClosedOrders('', dateString, 1);
        }
        if (toggleState === 'trades') {
          getTrades('', dateString, 1);
        }
      }
      pnlData('', dateString);
    } else {
      if (isMobile) {
        if (toggleState === 'trades') {
          callTrades('today', '', 1);
        }
        if (toggleState === 'closeOrder') {
          callCloseOrders('today', '', 1);
        }
      } else {
        if (toggleState === 'closeOrder') {
          getClosedOrders('today', '', 1);
        }
        if (toggleState === 'trades') {
          getTrades('today', '', 1);
        }
      }
      pnlData('today', '');
    }
  };

  //Table Pagination
  const handleTableChange = useCallback(
    (newPagination) => {
      if (toggleState === 'trades') {
        if (tableDate.length > 0) {
          getTrades('', tableDate, newPagination.current);
        } else {
          getTrades('today', '', newPagination.current);
        }
      } else {
        if (tableDate.length > 0) {
          getClosedOrders('', tableDate, newPagination.current);
        } else {
          getClosedOrders('today', '', newPagination.current);
        }
      }
    },
    [tableDate],
  );

  //Report Data
  async function ReportData(Date, page) {
    try {
      const response = await axios.get(
        `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/${id}?page=${page}&day=${Date}`,
        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      );
      const data = response.data;
      tab === 'VT' && setVTData(data);
      tab === 'LT' && setLTData(data);
      tab === 'VT' && setConfigData(data?.request_data?.vt_inputs);
      tab === 'LT' && setConfigData(data?.request_data?.lt_inputs);
      tab === 'LT' && setBrokerID(data?.request_data?.credential_id);
      tab === 'LT' && setInputID(data?.request_data?._id);
      setBrokerName(data?.request_data?.broker_name);
      setReportStatus(data?.request_data?.status);
      setPlayPauseID(data?.request_data?._id);
      setStrategyID(data?.request_data?.strategy_id);
      // setOrdersTotal(data?.orders_total);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    ReportData('today', 1);
    handleResize();
  }, []);

  //To Get Overall PNL and Todat Pnl
  async function pnlData(Date, selectedDate) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate?.[0] !== '' &&
        selectedDate?.[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getpnl/${id}?day=custom&start=${selectedDate[0]}&end=${
          selectedDate[1]
        }`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getpnl/${id}?day=${Date}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      setHeatmapData(data?.TotalDayWisePnl);
      setTotalTrades(data?.totalTrades);
      setoverAllPnL(data?.totalPnl);
      setTodayPnL(data?.filterPnl);
      setProfitPercentage(data?.profitPercentage);
      setLossPercentage(data?.lossPercentage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //To Get Trades Data in Desktop View
  async function getTrades(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate?.[0] !== '' &&
        selectedDate?.[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getcloseorders/${id}?page=${page}&day=custom&start=${
          selectedDate[0]
        }&end=${selectedDate[1]}`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getcloseorders/${id}?page=${page}&day=${Date}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      setDesktopTrades(
        data?.orders_data.map((item) => ({
          ...item,
          key: item?._id,
        })),
      );
      setPagination({
        ...pagination,
        current: page,
        total: data?.total_orders,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //To Get Trades Data in Mobile View for Infinite scroll
  async function callTrades(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate?.[0] !== '' &&
        selectedDate?.[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getcloseorders/${id}?page=${page}&day=custom&start=${
          selectedDate[0]
        }&end=${selectedDate[1]}`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/getcloseorders/${id}?page=${page}&day=${Date}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      let finalData = data?.orders_data;
      setMobileTrades((prevmobileTrades) => [
        ...prevmobileTrades,
        ...finalData,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //To Get Closed-Orders Data in Desktop View
  async function getClosedOrders(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate?.[0] !== '' &&
        selectedDate?.[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/get-request-placed-orders/${id}?day=custom&page=${page}&start=${
          selectedDate[0]
        }&end=${selectedDate[1]}`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/get-request-placed-orders/${id}?day=${Date}&page=${page}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      const finalData = response.data.orders_data;
      setDesktopClosedOrders(finalData);

      setPagination({
        ...pagination,
        current: page,
        total: data?.total_orders,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //To Get Closed-Orders Data in Mobile View for Infinite scroll
  async function callCloseOrders(Date, selectedDate, page) {
    try {
      let apiUrl = '';
      if (
        selectedDate !== '' &&
        selectedDate !== undefined &&
        selectedDate !== null &&
        selectedDate?.[0] !== '' &&
        selectedDate?.[1] !== ''
      ) {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/get-request-placed-orders/${id}?day=custom&page=${page}&start=${
          selectedDate[0]
        }&end=${selectedDate[1]}`;
      } else {
        apiUrl = `${config.base_url}${
          tab === 'VT' ? config.virtual_trade : config.live_trade
        }/get-request-placed-orders/${id}?day=${Date}&page=${page}`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });

      const finalData = response.data.orders_data;
      setMobileClosedOrders((prevmobileClosedOrders) => [
        ...prevmobileClosedOrders,
        ...finalData,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //To Get Open-Orders Data
  useEffect(() => {
    const openOrders = async () => {
      try {
        const response = await axios.get(
          `${config.base_url}${
            tab === 'VT' ? config.virtual_trade : config.live_trade
          }/get-request-open-orders/${id}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );
        const ordersData = response?.data?.orders_data;
        const finalOrder = ordersData
          ?.filter((obj) => obj.quantity_left > 0)
          .map((item) => ({
            ...item.vt_entry,
            quantity_left: item.quantity_left,
          })); //filtering the objects quantity_left>0
        // console.log('finalOrder', finalOrder);
        setOpenOrder(
          finalOrder.map((item) => ({
            ...item,
            // strategys: item?.strategys[0]?.name,
            // vt_requests: item?.vt_requests[0]?.vt_inputs.report_name,
            livePrice: 0,
            entryTime: item?.order_timestamp,
            // quantity_left: item?.quantity_left,
            pnl: 0,
            key: 0,
            entryPrice: item?.trigger_price,
            charges: 0,
            entryQuantity: item?.quantity,
          })),
        ); //assign new params to the object
        setTradeSymbols(
          ordersData
            .filter(
              (item) =>
                // item?.vt_entry &&
                // item?.vt_entry.tradingsymbol &&
                item?.quantity_left > 0,
            )
            .map((item) => item.vt_entry.tradingsymbol),
        );
        valueSocket.connect();
        valueSocket.emit('trade_orders', {
          user_id: [id],
        });
      } catch (error) {
        console.log(error);
      }
    };
    openOrders();
    return () => {
      valueSocket.disconnect();
    };
  }, []);

  //To get fund balance
  function getBrokerFund() {
    if (brokerID !== '') {
      axios
        .get(
          `${config.Kill_trade_base_url}/get_broker_fund?credential_id=${brokerID}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        )
        .then((res) => {
          setFundBalance(
            res?.data?.data?.net_balance === null
              ? 0
              : res?.data?.data?.net_balance,
          );
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  //To Assign the liveprice,pnl and charges to the Open-Order
  useEffect(() => {
    const updatedData = openOrder.map((item) => {
      if (item.tradingsymbol === priceData?.optionData?.symbol) {
        item.livePrice = priceData?.optionData?.price;
        item.pnl = calculateBrokerage(
          item?.entryPrice,
          priceData?.optionData?.price,
          item?.lot_size * item?.quantity_left,
          item?.position_type,
          brokerName === 'Kotak' ? 'Kotak' : 'Zerodha',
        )[1].toFixed(2);
        item.key = new Date().getTime();
        item.charges = calculateBrokerage(
          item?.entryPrice,
          priceData?.optionData?.price,
          item?.lot_size * item?.quantity_left,
          item?.position_type,
          brokerName === 'Kotak' ? 'Kotak' : 'Zerodha',
        )[0].toFixed(2);
      }
      return item;
    });
    if (updatedData.length > 0) {
      setLastOrder(updatedData);
    }
  }, [priceData]);

  //valueSocket to emit symbol
  useEffect(() => {
    // setLastOrder(openOrder);
    if (tradeSymbols.length > 0) {
      // valueSocket.emit('option_price', { symbol: tradeSymbols });
    }
    valueSocket.on('get_option_price', (livePriceData) => {
      setPriceData(livePriceData);
    });
  }, [tradeSymbols]);

  useEffect(() => {
    if (
      openOrder[0]?.strike_price !== null &&
      openOrder[0]?.strike_price !== undefined &&
      openOrder[0]?.strike_price !== ''
    ) {
      const resultArray = `${configData?.symbol.replace(/\s/g, '_')}_${
        openOrder[0]?.strike_price
      }_${openOrder[0]?.option_type}`;
      // console.log(resultArray);
      // setEmitTradeSymbol([resultArray]);
      valueSocket.emit('option_price', { symbol: [resultArray] });
    }
  }, [configData, openOrder.length]);

  //Open-Order Trade data comes through the Socket
  useEffect(() => {
    valueSocket.on('get_trade_orders', (Data) => {
      // console.log(Data.orderDataNew);
      setOpenOrder((prevData) => {
        let updatedData = [...prevData];
        const existingObjectIndex = updatedData.findIndex(
          (item) => item.request_id === Data.orderDataNew.request_id,
        ); //finding the new data exits in the open order or not
        if (existingObjectIndex !== -1) {
          if (Data?.orderDataNew?.quantity_left == 0) {
            updatedData.splice(0, 1); //removing the object from open-orders whose quantity_left == 0
            setTradeSymbols([]);
            setLastOrder([]);
            setTimeout(() => {
              pnlData('today', '');
              setBrokerStatus(Data?.orderDataNew);
            }, '2000');
            valueSocket.emit('option_price', { symbol: [] });
          } else {
            updatedData[existingObjectIndex] = {
              ...updatedData[existingObjectIndex],
              ...Data.orderDataNew, //updating the order which already exits
            };
          }
        } else {
          setNewOrder(Data.orderDataNew); //when new trades comes
        }
        return updatedData;
      });
    });
  }, []);

  //To get Strategy Details
  useEffect(() => {
    // let cancel = false;
    if (strategyID !== '') {
      axios
        .get(`${config.base_url}/admin/strategy/${strategyID}`, {
          headers: { Authorization: `Bearer ${getuserBots}` },
        })
        .then((res) => {
          tab === 'VT' && setStrategyData(res.data.data[0]?.vt_inputs);
          tab === 'LT' && setStrategyData(res.data.data[0]?.lt_inputs);
          setStrategyName(res.data.data[0].name);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [strategyID]);

  useEffect(() => {
    getBrokerFund();
  }, [brokerStatus]);

  useEffect(() => {
    setTimeout(() => {
      getBrokerFund();
    }, '2000');

    if (newOrder.trigger_price !== undefined) {
      newOrder.entryPrice = newOrder.trigger_price;
      newOrder.entryQuantity = newOrder.quantity;
      newOrder.entryTime = newOrder.order_timestamp;
      openOrder.push(newOrder);
      setLastOrder(openOrder);
    }
  }, [newOrder]);

  useEffect(() => {
    if (lastOrder.length === 0) {
      setAmountInvested(0);
    }
    if (
      lastOrder.length !== 0 &&
      lastOrder[0]?.entryPrice !== null &&
      lastOrder[0]?.entryPrice !== undefined &&
      lastOrder[0]?.entryPrice !== ''
    ) {
      setAmountInvested(
        lastOrder.map(
          (item) => item.entryPrice * item.entryQuantity * item.lot_size,
        ),
      );
    }
  }, [lastOrder.length]); //for Amount Invested

  //Initial call for funtions and when the state changes
  useEffect(() => {
    setMobileTrades([]);
    setMobileClosedOrders([]);
    setTableDate([]);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    setCurrentPage(2);
    if (isMobile) {
      if (toggleState === 'trades') {
        callTrades('today', '', 1);
      }
      if (toggleState === 'closeOrder') {
        callCloseOrders('today', '', 1);
      }
    } else {
      if (toggleState === 'closeOrder') {
        getClosedOrders('today', '', 1);
      }
      if (toggleState === 'trades') {
        getTrades('today', '', 1);
      }
    }
    pnlData('today', '');
  }, [toggleState]);

  useEffect(() => {
    if (brokerID !== '') {
      getBrokerFund();
    }
  }, [brokerID]);

  //for Inputs Modal
  useEffect(() => {
    const VtModalData = strategyData?.map((strategy) => {
      strategy.value_default = configData[strategy.value_name];
      return strategy;
    });
    setInputs(VtModalData);
  }, [strategyData, configData]);

  //beforeunload event
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  //beforeunload function
  const handleBeforeUnload = (event) => {
    valueSocket.disconnect();
  };

  //for closedorder Infinite scroll
  function paginationCall() {
    if (tableDate.length > 0) {
      if (toggleState === 'closeOrder') {
        callCloseOrders('', tableDate, currentPage);
      }
      if (toggleState === 'trades') {
        callTrades('', tableDate, currentPage);
      }
    } else {
      if (toggleState === 'closeOrder') {
        callCloseOrders('today', '', currentPage);
      }
      if (toggleState === 'trades') {
        callTrades('today', '', currentPage);
      }
    }
    setCurrentPage(currentPage + 1);
  }

  //Tooltip of VT&LT modal
  const getTooltip = () => {
    if (tab === 'VT') {
      if (reportStatus === true) return 'Virtual Trade is Active';
      else return 'Virtual Trade is Deactive';
    }

    if (tab === 'LT') {
      if (reportStatus === true) return 'Live Trade is Active';
      else return 'Live Trade is Deactive';
    }
  };

  //function to make play-pause to be disbaled
  function getPauseIcon() {
    if (lastOrder.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  //function of the Active status
  function getActiveStatus() {
    if (reportStatus === true) {
      return 'Active';
    }
    if (reportStatus === false) return 'Inactive';
  }

  return (
    <>
      <>
        <Sidebar />
        <div className="left__bar">
          <div className="row bg__white p-2 mt-3 rounded align-items-center">
            <div className="col-lg-3 order-1 col-6 ps-0 ps-lg-3 ps-lg-3">
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-2 col-3">
                  <Link
                    onClick={() => {
                      valueSocket.disconnect();
                      history.goBack();
                    }}
                    className="btn__back"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                    </svg>
                  </Link>
                </div>
                <div className="col-lg-10 col-md-10 col-9 pe-0 pe-lg-3">
                  <h2 className="strtgy__ttl">{configData?.report_name}</h2>
                  <span className="badge__green me-2">{getActiveStatus()}</span>
                </div>
                <DateTime />
              </div>
            </div>

            <div className="col-lg-7 order-3 order-lg-2 ps-0 pe-lg-3 ps-lg-3">
              <div className="strategy__sml__details">
                <ul>
                  <li>
                    <span>Strategy Name : </span>
                    <p> {strategyName}</p>
                  </li>
                  <li>
                    <span>Created at : </span>
                    <p>
                      {' '}
                      {dayjs
                        .utc(
                          (tab === 'VT' ? vtData : ltData)?.request_data
                            ?.created_at,
                        )
                        .format('DD MMM, YYYY h:mm a')}
                    </p>
                  </li>
                  <li>
                    <span>Overall PNL : </span>
                    <p className="primary__green">
                      {' '}
                      {overAllPnL < 0 ? (
                        <span className="text-danger ml-2">
                          {Number(overAllPnL).toFixed(2)}
                        </span>
                      ) : (
                        <span className="text-success ml-2">
                          {Number(overAllPnL).toFixed(2)}
                        </span>
                      )}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <ReportIcons
              setTradeSettingSwitch={setTradeSettingSwitch}
              reportStatus={reportStatus}
              playPauseID={playPauseID}
              getuserBots={getuserBots}
              getPauseIcon={getPauseIcon}
            />
          </div>

          <ReportCardsData
            tab={tab}
            fundBalance={fundBalance}
            lastOrder={lastOrder}
            configData={configData}
            amountInvested={amountInvested}
            todayPnL={todayPnL}
          />

          <div className="row strtgy__table__box mt-3">
            <ReportTabs
              setToggleState={setToggleState}
              toggleState={toggleState}
              lastOrder={lastOrder}
              HandleDateChange={HandleDateChange}
              tab={tab}
              brokerID={brokerID}
            />
          </div>

          <div className="tbl_data row">
            <div
              className={
                toggleState === 'openOrder'
                  ? 'tab-content-active'
                  : 'tabs-content'
              }
            >
              <div className="col ps-0 pe-0 mt-3">
                <div>
                  {isMobile == false ? (
                    <Table columns={openColumns} dataSource={lastOrder} />
                  ) : (
                    <ReportOpenTable tradeOrder={lastOrder} />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                toggleState === 'closeOrder'
                  ? 'tab-content-active'
                  : 'tabs-content'
              }
            >
              <div className="col ps-0 pe-0 mt-3">
                <div>
                  {isMobile == false ? (
                    <PortfolioClosedTable
                      from="closed"
                      dataSource={desktopClosedOrders}
                      pagination={pagination}
                      handleTableChange={handleTableChange}
                    />
                  ) : (
                    <ReportClosedTable
                      paginationCall={paginationCall}
                      mobileClosedOrders={mobileClosedOrders}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 'trades' ? 'tab-content-active' : 'tabs-content'
              }
            >
              <div className="col ps-0 pe-0 mt-3">
                <div>
                  {isMobile == false ? (
                    <PortfolioClosedTable
                      from="trade"
                      dataSource={desktopTrades}
                      pagination={pagination}
                      handleTableChange={handleTableChange}
                    />
                  ) : (
                    <ReportTradesTable
                      paginationCall={paginationCall}
                      closedCards={mobileTrades}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 'p&l' ? 'tab-content-active' : 'tabs-content'
              }
            >
              <div className="col ps-0 pe-0 mt-1">
                <div className="heatmap__bg">
                  <Heatmap
                    mapData={heatmapData}
                    from="report"
                    totalTrades={totalTrades}
                    profitTrades={profitPercentage}
                    lossTrades={lossPercentage}
                  />
                </div>
                {/* <div className="close__order__data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#4074f9"
                  className="bi bi-database-exclamation"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5Zm0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                </svg>
                <span>You have no data yet!</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </>

      {/* ===================== TradeSetting Modal  ======================== */}
      <Modal
        show={TradeSetting}
        size={'xl'}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-header" style={{ backgroundColor: '#E0EAFF' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
            }}
          >
            <h4 className="fsize-22 font-weight-bold mb-0">
              {tab === 'LT' ? 'Live Trade Setting' : 'Virtual Trade Setting'}
            </h4>
            <p>
              (
              {tab === 'VT'
                ? vtData?.request_data?.vt_inputs?.report_name
                : ltData?.request_data?.lt_inputs?.report_name}
              )
            </p>
          </div>
          <div className="d-flex justify-content-start">
            <Tooltip placement="top" title={getTooltip()}>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={
                    (tab === 'VT' ? vtData : ltData)?.request_data?.status
                  }
                />
                <span className="slider"></span>
              </label>
            </Tooltip>
          </div>
        </div>
        <Modal.Body className="position-relative tradesetting_tab">
          <BackTesting
            inputs={inputs}
            brokerId={brokerID}
            iT_id={inputID}
            modalData={tab === 'LT' ? 'live' : 'virtual'}
            VTReport={'VTReport'}
            configData={configData}
            BrokerTab={'BrokerTab'}
            strategy_id={strategyID}
            getBroker={ReportData}
            onClose={setTradeSettingSwitch}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewVTReport;
