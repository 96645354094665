import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import config from '../../../Config/config';
import mydecodedTokenFunction from '../../../utils/decodetoken';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function SwitchedStrategies({
  isMobile,
  setStrategyResult,
  tab,
  setAvailableStrategies,
}) {
  let history = useHistory();
  const [subscribeStrategies, setSubscribeStrategies] = useState([]);
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  let getuserBots = localStorage.getItem('token');

  useEffect(() => {
    const getStrategies = async () => {
      try {
        const response = await axios.get(
          `${config.base_url}/strategy/get-user-strategies/${userid.id}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );

        setSubscribeStrategies(response?.data?.data);
        setAvailableStrategies(response?.data?.data);
        const result = [];
        if (tab === 'live') {
          for (const item of response?.data?.data) {
            const stName = item.st_name;
            for (const ltItem of item.ltList) {
              result.push({ ...ltItem, st_name: stName });
            }
          }
        } else {
          for (const item of response?.data?.data) {
            const stName = item.st_name;
            for (const vtItem of item.vtList) {
              result.push({ ...vtItem, st_name: stName });
            }
          }
        }
        setStrategyResult(result);
        // console.log('result', result);
      } catch (error) {
        console.log(error);
      }
    };
    getStrategies();
  }, []);

  return (
    <>
      {isMobile == false ? (
        <div className="row mob_spc">
          <div className="col-12 px-0 mt-3 d-flex justify-content-between sep__ln">
            <h2 className="fs-5 mb-0">Strategy</h2>
            <span
              className="strgy_ttl_box me-3 text-primary fs-6"
              onClick={() => history.push('/strategies')}
            >
              <a>View All</a>
            </span>
          </div>
          <div className="row px-0 mt-3 cstm__scroll">
            {subscribeStrategies.map((item) => (
              <div
                key={item?.st_id}
                className="strtgy__card d-flex col flex-column ms-lg-2 me-lg-2 "
              >
                <div className="d-flex row flex-row px-2">
                  <div className="strtgy__thumb col-2">
                    {item?.st_name[0].toUpperCase()}
                  </div>
                  <div className="strtgy__card__details col-10 pe-0">
                    <div className="d-flex">
                      <div className="d-flex flex-column col pe-0 ps-2">
                        <span>
                          {dayjs.utc(item?.created_at).format('DD MMM YYYY')}
                        </span>
                        <h3>{item?.st_name}</h3>
                      </div>
                      <div className="d-flex align-items-center justify-content-end float-end">
                        <Link
                          className="strtgy__card__more"
                          to={`/strategy/${item?.st_id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex sep__top strtgy__card__sub__details">
                      <div className="d-flex">
                        <div className="col px-0 ps-2">
                          <span>Virtual Trade</span>
                          <span className="vrtl__trd__badge">
                            {item?.vtList?.length}
                          </span>
                        </div>
                        <div className="col px-0 d-flex align-items-center justify-content-end">
                          <span>Live Trade</span>
                          <span className="lv__trd__badge">
                            {item?.ltList?.length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="row mt-3">
          <div className="strgy_ttl_box">
            <h2 className="strgy_ttl">Strategy</h2>
            <a onClick={() => history.push('/strategies')}>View All</a>
          </div>
          <div className="cstm__mob__scroll">
            {subscribeStrategies.map((item) => (
              <div
                key={item?.st_id}
                className="strtgy__card d-flex col-12 flex-column mb-2"
              >
                <div className="d-flex row flex-row px-2">
                  <div className="strtgy__thumb col-2">
                    {item?.st_name[0].toUpperCase()}
                  </div>
                  <div className="strtgy__card__details col-10 pe-0">
                    <div className="d-flex">
                      <div className="d-flex flex-column col pe-0 ps-2">
                        <span>
                          {dayjs.utc(item?.created_at).format('DD MMM YYYY')}
                        </span>
                        <h3>{item?.st_name}</h3>
                      </div>
                      <div className="d-flex align-items-center justify-content-end float-end">
                        <Link
                          className="strtgy__card__more"
                          to={`/strategy/${item?.st_id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex sep__top strtgy__card__sub__details">
                  <div className="d-flex">
                    <div className="col px-0">
                      <span>Virtual Trade</span>
                      <span className="vrtl__trd__badge">
                        {item?.vtList?.length}
                      </span>
                    </div>
                    <div className="col px-0 d-flex align-items-center justify-content-end">
                      <span>Live Trade</span>
                      <span className="lv__trd__badge">
                        {item?.ltList?.length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(SwitchedStrategies);
