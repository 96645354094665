import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import logo from '../../assets/images/logo.svg';
import dark_logo from '../../assets/images/logo_black.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../Config/config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  console.log(email);
  const forgetPassword = () => {
    if (email !== '') {
      axios
        .post(`${config.base_url}${config.forgot_pass}`, {
          email: email,
        })
        .then((res) => {
          setTimeout(() => {
            setEmail('');
          }, 1000);
          localStorage.setItem('EmailID', JSON.stringify(email));
          console.log('Forget Password', res);
          if (res.data.message === 'Email not registered') {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            return toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } else {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const submitForm = ({ email }) => {
    console.log(email);
    form.resetFields();
  };

  return (
    <>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" alt="logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="forgotForm">
                  <Form
                    form={form}
                    className="mt-5"
                    layout="vertical"
                    onFinish={submitForm}
                  >
                    <Form.Item
                      label="EMAIL"
                      name="email"
                      value={email}
                      rules={[
                        {
                          required: true,
                          message: 'Email field required',
                        },
                      ]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Enter your mail"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <div className="text-center mt-5">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="allBtns"
                          onClick={forgetPassword}
                        >
                          Continue
                        </Button>
                      </Form.Item>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Link
                        className="text-primary text-decoration-none p-1"
                        to="/"
                      >
                        <i className="fa fa-unlock me-1" aria-hidden="true"></i>{' '}
                        Back To Login
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
