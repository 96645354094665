import React, { useState, useEffect } from 'react';
import {
  PrinterOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { DatePicker, Space } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import StrategyDesktopTable from './StrategyDesktopTable';
import StrategyMobileTable from './StrategyMobileTable';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';

import { useHistory, useParams } from 'react-router-dom';
import config from '../../../Config/config';
import axios from 'axios';
import { mydecodedTokenFunction } from '../../../utils/decodetoken';
import dayjs from 'dayjs';
import BackTesting from '../BackTesting';

const { RangePicker } = DatePicker;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const StrategyReport = () => {
  const [tableDate, setTableDate] = useState([]);
  const [fetchState, setFetchState] = useState(false);
  const [backTestingModalShow, setBackTestingModalShow] = useState(false);
  const [modalData, setModalData] = useState('');
  const HandleDateChange = (date, dateString) => {
    setTableDate(dateString);
  };
  const [strategyData, setStrategyData] = useState([]);
  const history = useHistory();
  const [virtualData, setVirtualData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  let token = localStorage.getItem('token');
  let stAccess = JSON.parse(localStorage.getItem('st_access'));
  let userid = mydecodedTokenFunction(token);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const [emptyState, setEmptyState] = useState(false);
  let { id } = useParams();

  const [toggleState, setToggleState] = useState('virtual');
  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    getVirtualTradeData();
    getLiveTradeData();
    axios
      .get(`${config.base_url}${config.get_strategy}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setStrategyData(res?.data?.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (toggleState === 'virtual') {
      getVirtualTradeData();
    } else {
      getLiveTradeData();
    }
  }, [backTestingModalShow]);

  const getVirtualTradeData = () => {
    axios
      .post(
        `${config.base_url}${config.list_virtual_trade}/list?page=1&limit=10`,

        {
          user_id: userid.id,
          strategy_id: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        // console.log('virtual', res);
        // const data = res?.data?.data;
        const updatedData = res?.data?.data.map((item) => ({
          ...item,
          report_name: item?.vt_inputs.report_name,
          max_cap: item?.vt_inputs.investment,
          key: item?._id,
        }));
        setVirtualData(updatedData);
      })
      .catch((error) => {
        // setloading(false);
        console.log(error);
      });
  };

  const getLiveTradeData = () => {
    axios
      .post(
        `${config.base_url}${config.list_live_trade}/list?page=1&limit=10`,
        {
          user_id: userid.id,
          strategy_id: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        // console.log(res);
        const updatedData = res?.data?.data.map((item) => ({
          ...item,
          report_name: item?.lt_inputs?.report_name,
          max_cap: item?.lt_inputs?.investment,
          key: item?._id,
        }));

        setLiveData(updatedData);
      });
  };

  const GetInputsData = () => {
    if (toggleState === 'virtual') {
      return strategyData[0]?.vt_inputs;
    } else if (toggleState === 'live') {
      return strategyData[0]?.lt_inputs;
    }
  };

  function getIconButton() {
    if (toggleState === 'virtual' && virtualData.length > 0) {
      return (
        <span
          onClick={() => {
            setBackTestingModalShow(true);
          }}
        >
          + Add Virtual Trade
        </span>
      );
    }
    if (toggleState === 'live' && liveData.length > 0) {
      return (
        <span
          onClick={() => {
            setBackTestingModalShow(true);
          }}
        >
          + Add Live Trade
        </span>
      );
    }
  }

  //delete request

  return (
    <>
      <Sidebar />
      <div className="left__bar">
        <div className="row bg__white p-2 mt-3 rounded align-items-center">
          <div className="col-lg-3 order-1 col-6 ps-0 ps-lg-3 ps-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-2 col-3">
                <Link
                  to={'/strategies'}
                  className="btn__back"
                  // onClick={() => {
                  //   history.goBack();
                  // }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                  </svg>
                </Link>
              </div>
              <div className="col-lg-10 col-md-10 col-9 pe-0 pe-lg-3">
                <h2 className="strtgy__ttl">{strategyData[0]?.name}</h2>
                <span className="badge__green me-2">Active</span>
              </div>
            </div>
          </div>

          <div className="col-lg-8 order-3 order-lg-2 ps-0 pe-lg-0 ps-lg-0">
            <div className="strategy__sml__details inner">
              <ul>
                <li>
                  <span>Created At :</span>
                  <p>
                    {dayjs(strategyData[0]?.created_at).format(
                      'DD MMM, YYYY h:mm a',
                    )}
                  </p>
                </li>
                <li>
                  <span>Min Cap :</span>
                  <p>{strategyData[0]?.min_amount_required}</p>
                </li>
                <li>
                  <span>Instrument :</span>
                  <p>3552</p>
                </li>
                <li>
                  <span>Risk :</span>
                  <p>
                    {' '}
                    {strategyData[0]?.risk_factor &&
                    strategyData[0]?.risk_factor !== '' &&
                    strategyData[0]?.risk_factor !== null &&
                    strategyData[0]?.risk_factor !== undefined
                      ? `${strategyData[0]?.risk_factor}%`
                      : ''}
                  </p>
                  <span className="indicator__down ms-1">
                    <svg
                      stroke="currentColor"
                      fill="red"
                      strokeWidth="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0 0 48.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path>
                    </svg>
                  </span>
                </li>
                <li>
                  <span>Growth :</span>
                  <p>
                    {' '}
                    {strategyData[0]?.growth_factor &&
                    strategyData[0]?.growth_factor !== '' &&
                    strategyData[0]?.growth_factor !== null &&
                    strategyData[0]?.growth_factor !== undefined
                      ? `${strategyData[0]?.growth_factor}%`
                      : ''}
                  </p>
                  <span className="indicator__up ms-1">
                    <svg
                      stroke="currentColor"
                      fill="green"
                      strokeWidth="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-1 order-2 col-6 order-lg-3 pe-0 pe-lg-3">
            <ul className="strategy__sml__btns">
              <li>
                <a href="">
                  <Tooltip title="Info" placement="bottom">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="18px"
                      width="18px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                  </Tooltip>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row strtgy__table__box mt-3">
          <div className="col-5 col-lg-6 ps-0">
            <ul className="strtgy__tbl">
              <li
                onClick={() => toggleTab('virtual')}
                className={toggleState === 'virtual' ? 'active' : 'tabs'}
              >
                <span>Virtual Trade</span>
              </li>
              <li
                onClick={() => toggleTab('live')}
                className={toggleState === 'live' ? 'active' : 'tabs'}
              >
                <span>Live Trade</span>
              </li>
            </ul>
            <div className="strtgy__table__drpdwn">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {toggleState === 'virtual' ? (
                    <span>Virtual Trade</span>
                  ) : (
                    <span>Live Trade</span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href=""
                    onClick={() => toggleTab('virtual')}
                    className={toggleState === 'virtual' ? 'active' : 'tabs'}
                  >
                    Virtual Trade
                  </Dropdown.Item>
                  <Dropdown.Item
                    href=""
                    onClick={() => toggleTab('live')}
                    className={toggleState === 'live' ? 'active' : 'tabs'}
                  >
                    Live Trade
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center">
            <button className="btn__add__virtualTrade">
              {isMobile === false ? (
                getIconButton()
              ) : (
                // <span>+ Add Virtual Trade</span>
                <span className="mb__btn">
                  <PlusOutlined />
                </span>
              )}
            </button>
            <div className="cstm__dt__pick">
              <Space direction="vertical">
                <RangePicker
                  onChange={HandleDateChange}
                  placeholder={['Today', '']}
                />
              </Space>
            </div>

            <button className="btn__print deactive">
              <Tooltip title="Print" placement="top">
                <PrinterOutlined />
              </Tooltip>
            </button>

            <button className="btn__delete deactive">
              <Tooltip title="Delete" placement="top">
                <DeleteOutlined />
              </Tooltip>
            </button>
          </div>
        </div>

        <div className="tbl_data row">
          <div
            className={
              toggleState === 'virtual' ? 'tab-content-active' : 'tabs-content'
            }
          >
            {virtualData.length === 0 ? (
              <div className="empty__bx">
                <h3>You don’t have Virtual Running</h3>
                <button
                  onClick={() => {
                    setBackTestingModalShow(true);
                  }}
                  className="btn__add__strategy"
                >
                  <svg
                    stroke="currentColor"
                    fill="#4074F9"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    ></path>
                  </svg>
                </button>
                <h4>Click on the + button to setup Virtual</h4>
              </div>
            ) : (
              <div className="col ps-0 pe-0 mt-3">
                <div>
                  {isMobile == false ? (
                    <StrategyDesktopTable
                      tradeOrder={virtualData}
                      toggleState={toggleState}
                      getVirtualTradeData={getVirtualTradeData}
                    />
                  ) : (
                    <StrategyMobileTable
                      tradeOrder={virtualData}
                      toggleState={toggleState}
                      getVirtualTradeData={getVirtualTradeData}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className={
              toggleState === 'live' ? 'tab-content-active' : 'tabs-content'
            }
          >
            {liveData.length === 0 ? (
              <div className="empty__bx">
                <h3>You don’t have Live Running</h3>
                <button
                  onClick={() => {
                    setBackTestingModalShow(true);
                  }}
                  className="btn__add__strategy"
                >
                  <svg
                    stroke="currentColor"
                    fill="#4074F9"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    ></path>
                  </svg>
                </button>
                <h4>Click on the + button to setup Live</h4>
              </div>
            ) : (
              <div className="col ps-0 pe-0 mt-3">
                <div>
                  {isMobile == false ? (
                    <StrategyDesktopTable
                      tradeOrder={liveData}
                      toggleState={toggleState}
                      getVirtualTradeData={getLiveTradeData}
                    />
                  ) : (
                    <StrategyMobileTable
                      tradeOrder={liveData}
                      toggleState={toggleState}
                      getVirtualTradeData={getLiveTradeData}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className={
              toggleState === 3 ? 'tab-content-active' : 'tabs-content'
            }
          >
            <div className="col ps-0 pe-0 mt-0">
              <div className="close__order__data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#4074f9"
                  className="bi bi-database-exclamation"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5Zm0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                </svg>
                <span>You have no data yet!</span>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={backTestingModalShow}
          size={'xl'}
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="modal-header" style={{ backgroundColor: '#E0EAFF' }}>
            <h4 className="fsize-22 font-weight-bold mb-0">
              {toggleState === 'virtual' ? 'VirtualTrade' : 'Live Trade Setup'}
            </h4>
          </div>
          <Modal.Body className="position-relative tradesetting_tab">
            <BackTesting
              inputs={GetInputsData()}
              modalData={toggleState}
              setFetchState={setFetchState}
              strategy_id={id}
              onClose={setBackTestingModalShow}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default StrategyReport;
