import { Button, Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import dark_logo from '../../assets/images/logo_black.svg';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
// import { socket } from '../../context/socket';
import config from '../../Config/config';

function Login() {
  let value = secureLocalStorage.getItem('User_Details');
  const [email, setEmail] = useState(value ? value.email : '');
  const [password, setPassword] = useState(value ? value.password : '');
  const [loading, setLoading] = React.useState(false);
  const [PassError, setPassError] = React.useState('');
  const [EmailError, setEmailError] = React.useState('');
  const [rememberme, setRememberMe] = useState(false);
  const [showpassword, setShowPassword] = React.useState(false);
  //console.log(process.env.DEVELOPMENT_BASEURL);
  let history = useHistory();

  // const history = useHistory();
  const loginHandle = () => {
    if (email !== '' && password !== '') {
      if (password.length >= 8) {
        setLoading(true);
        axios
          .post(`${config.base_url}${config.login}`, {
            email: email,
            password: password,
          })
          .then((res) => {
            console.log(res);
            console.log('Response Data', res);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('allbots', res.data.bot_list);
            localStorage.setItem(
              'st_access',
              JSON.stringify(res.data.st_access ? res.data.st_access : []),
            );
            setLoading(false);

            if (res.data.status === true) {
              //   history.push('/home');
              //history.push('/strategies');
              window.location.href = '/portfolio/virtual';
              setTimeout(() => {
                return toast.success('Login Successful!', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 3000,
                });
              }, 1000);
              if (rememberme) {
                secureLocalStorage.setItem('User_Details', {
                  email: email,
                  password: password,
                });
              } else {
                if (value.email === '' && value.password === '') {
                  secureLocalStorage.setItem('User_Details', {
                    email: '',
                    password: '',
                  });
                }
              }
            } else {
              if (res.data.message === 'Invalid password.') {
                setPassError('Invalid Password');
              } else if (res.data.message === 'Please verify your email.') {
                setEmailError('Please verify your email');
              } else if (res.data.message === 'Email not registered.') {
                setEmailError('Email not registered');
              } else {
                return toast.error(res.data.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 3000,
                });
              }
            }
          })
          .catch((error) => {
            //console.log(error);
            if (error.response !== undefined) {
              //console.log('here');
              setLoading(false);
              return toast.error(error.response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
              });
            } else {
              setLoading(false);
              // return toast.error('something went wrong server error', {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   autoClose: 3000,
              // });
            }
          });
      } else {
        setPassError('Passwords must contain at least eight characters.');
      }
    } else {
      if (password === '' && email === '') {
        setPassError('Password field required');
        setEmailError('Email field required');
      } else if (email === '') {
        setEmailError('Email field required');
      } else if (password === '') {
        setPassError('Password field required');
      }
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  //Onchange email
  const handleEmail = (e) => {
    if (EmailError.length > 0) {
      setEmailError('');
    }
    setEmail(e.target.value);
    //console.log(email);
  };

  //Onchange password

  const handlePassword = (e) => {
    if (PassError.length > 0) {
      setPassError('');
    }
    setPassword(e.target.value);
    //console.log(password);
  };

  return (
    <>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                  {/* <Link className="adminLogin" to={"/admin-login"}>
                    Admin Login
                  </Link> */}
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} alt="Logo" />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="text-center mt-5">
                  <h1>Hello! Welcome Back</h1>
                </div>

                <Form className="mt-5" layout="vertical">
                  <Form.Item label="EMAIL" name="email">
                    <Input
                      className="form-control"
                      placeholder="Enter your mail"
                      name="email"
                      type="email"
                      value={email}
                      onChange={handleEmail}
                      defaultValue={value ? value.email : ''}
                    />
                    {EmailError ? (
                      <>
                        <div className="ant-form-item-explain ant-form-item-explain-connected">
                          <div
                            className="ant-form-item-explain-error"
                            style={{ marginTop: '0px' }}
                          >
                            {EmailError}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Form.Item>

                  <Form.Item label="PASSWORD" name="password">
                    <span className="d-flex position-relative">
                      <Input
                        className="form-control"
                        placeholder="Enter your password"
                        name="password"
                        type={showpassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePassword}
                        defaultValue={value ? value.password : ''}
                      />
                      <i
                        className={`fa ${
                          showpassword ? 'fa-eye-slash' : 'fa-eye'
                        } eyeIcon`}
                        onClick={(e) => {
                          setShowPassword(!showpassword);
                        }}
                      />
                    </span>
                    {PassError ? (
                      <>
                        <div className="ant-form-item-explain ant-form-item-explain-connected">
                          <div
                            className="ant-form-item-explain-error"
                            style={{ marginTop: '0px' }}
                          >
                            {PassError}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Form.Item>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className="mb-0"
                    >
                      <Checkbox
                        className="rememberMe"
                        onClick={() =>
                          rememberme
                            ? setRememberMe(false)
                            : setRememberMe(true)
                        }
                      >
                        Remember me
                      </Checkbox>
                    </Form.Item>
                    <Link to="/user-forgot-password" className="forgotPassword">
                      Forget Password?
                    </Link>
                  </div>
                  <div className="text-center mt-5">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="allBtns m-auto"
                        onClick={loginHandle}
                        loading={loading}
                      >
                        {loading ? '      ' : 'LOG IN'}
                      </Button>
                    </Form.Item>
                    <div>
                      Don't have an account? <Link to={'/signup'}>Signup</Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
