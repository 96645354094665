import { Button, Checkbox, Form, Input, message, Radio } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import dark_logo from '../../assets/images/logo_black.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../Config/config';

function Signup() {
  let history = useHistory();
  const [value, setValue] = useState('');
  const [signUpData, setSignUpData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNum: '',
    password: '',
    confirmPassword: '',
  });
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [agree, setAgree] = useState(false);

  const handelChange = (e) => {
    let list = { ...signUpData, [e.target.name]: e.target.value };
    //checkPassword(list);
    emailError !== '' && setEmailError('');
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };
  //console.log(setSignUpData);

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  //Signup Function

  const SignUp = () => {
    if (
      signUpData.firstName === '' ||
      signUpData.lastName === '' ||
      signUpData.email === '' ||
      signUpData.mobileNum === '' ||
      signUpData.password === '' ||
      signUpData.confirmPassword === '' ||
      value === ''
    ) {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

    var minMaxLength = /^[\s\S]{8,16}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/;

    if (
      minMaxLength.test(signUpData.password) &&
      upper.test(signUpData.password) &&
      lower.test(signUpData.password) &&
      number.test(signUpData.password)
    ) {
      // console.log("Is a Valid password");
      setPasswordError('');
    } else {
      return setPasswordError(
        'Password Should contain atleast one Uppercase, Lowercase and Numeric',
      );
    }

    let payload = {
      signup_type: value,
      email: signUpData.email,
      first_name: signUpData.firstName,
      last_name: signUpData.lastName,
      phone_no: signUpData.mobileNum,
      password: signUpData.password,
      user_accessibility: ['1', '2'],
      default_stock: [],
    };

    axios
      .post(`${config.base_url}${config.sign_up}`, payload)
      .then((res) => {
        // console.log({botData: res});
        console.log('Response Data', res);
        localStorage.setItem('EmailID', JSON.stringify(payload.email));
        if (res.data.status) {
          //   history.push('/home');
          if (res.data.message === 'User added successfully.') {
            history.push('/success');
          } else {
            //console.log(res.data.message);
            return toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          if (res.data.message === 'Email already registered.') {
            setEmailError('Email already registered.');
          } else {
            return toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
            });
          }
        }
      })
      .catch((error) => {
        //console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  console.log(emailError);

  return (
    <>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-5">
                <div className="mt-5">
                  <h1>Signup</h1>
                </div>
                <div>
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={'Business'}>BUSINESS</Radio>
                    <Radio value={'Enterprise'}>ENTERPRISE</Radio>
                    <Radio value={'Invidual'}>INDIVIDUAL</Radio>
                  </Radio.Group>
                </div>

                <Form className="mt-5" layout="vertical">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="FIRST NAME"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: 'Name field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your first name"
                          name="firstName"
                          value={setSignUpData.firstName}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="LAST NAME"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Last name field required',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your last name"
                          name="lastName"
                          value={setSignUpData.lastName}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="EMAIL ID"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Email field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your email"
                          name="email"
                          value={setSignUpData.email}
                          onChange={handelChange}
                        />
                      </Form.Item>
                      {emailError !== '' ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {emailError}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="PHONE NO."
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Phone field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your phone number"
                          name="mobileNum"
                          value={setSignUpData.mobileNum}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="CREATE PASSWORD"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Password field required',
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          //className="form-control"
                          placeholder="Enter password"
                          name="password"
                          value={setSignUpData.password}
                          onChange={handelChange}
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            height: '40px',
                            borderRadius: '0.375rem',
                          }}
                        />
                      </Form.Item>
                      {passwordError ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {passwordError}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        type="password"
                        label="CONFIRM PASSWORD"
                        name="verfiy_password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Password field required',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!',
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          //className="form-control"
                          placeholder="Verify password"
                          name="confirmPassword"
                          value={setSignUpData.confirmPassword}
                          onChange={handelChange}
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            height: '40px',
                            borderRadius: '0.375rem',
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className="mb-0"
                    >
                      <Checkbox
                        className="rememberMe"
                        onClick={() => {
                          agree ? setAgree(false) : setAgree(true);
                        }}
                      >
                        I agree to all the{' '}
                        <a style={{ color: '#4074f9', fontWeight: '500' }}>
                          Terms, Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a style={{ color: '#4074f9', fontWeight: '500' }}>
                          Fees
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className="text-left mt-3">
                    {agree ? (
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="allBtns"
                          onClick={SignUp}
                        >
                          SIGN UP
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="allBtns"
                          onClick={SignUp}
                          disabled
                          style={{ backgroundColor: 'rgb(141,129,242)' }}
                        >
                          SIGN UP
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                  <div className="text-center">
                    Already have an Account?{' '}
                    <Link
                      style={{ color: '#4074f9', fontWeight: '500' }}
                      to={'/'}
                    >
                      Log in
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
