import React from 'react';
import io from 'socket.io-client';
// import socketio from 'socket.io-client';
import config from '../Config/config';
const tokenexist = localStorage.getItem('token');

// export const socket = io(`${config.Socket_base_url}/users?token=${tokenexist}`);

export const chartSocket = io(
  `${config.Socket_base_url}/chat_data_by_redis?token=${tokenexist}`,
  {
    autoConnect: false,
  },
);
export const priceSocket = io(
  `${config.Socket_base_url}/option_price_by_redis?token=${tokenexist}`,
  {
    autoConnect: false,
  },
);
export const tradeSocket = io(
  `${config.Socket_base_url}/trade_order_by_redis?token=${tokenexist}`,
  {
    autoConnect: false,
  },
);

export const valueSocket = io(
  `${config.Socket_base_url}/trades?token=${tokenexist}`,
  {
    autoConnect: false,
  },
);

// export const socket = socketio.connect(
//   `${config.Socket_base_url}/users?token=${tokenexist}`,
// );

// export const SocketContext = React.createContext();
