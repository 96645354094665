import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'antd';
import logo from '../../assets/images/logo.svg';
import dark_logo from '../../assets/images/logo_black.svg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../Config/config';

function ResetPassword() {
  //console.log("here");
  let history = useHistory();
  const [password, setPassword] = useState({
    new_password: '',
    confirm_password: '',
  });
  const { token } = useParams();
  //console.log(token);
  const [passwordError, setPasswordError] = useState('');

  const handelChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  //console.log(password);

  const changePassword = () => {
    if (password.new_password !== password.confirm_password) {
      return;
    }
    // console.log(updatedPassword);

    if (password.new_password === '' || password.confirm_password === '') {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

    var minMaxLength = /^[\s\S]{8,16}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/;

    if (
      minMaxLength.test(password.new_password) &&
      upper.test(password.new_password) &&
      lower.test(password.new_password) &&
      number.test(password.new_password)
    ) {
      setPasswordError('');
    } else {
      return setPasswordError(
        'Password Should contain atleast one Uppercase, Lowercase and Numeric',
      );
    }

    let payload = {
      password: password.new_password,
    };
    // console.log(payload);
    axios
      .post(`${config.base_url}${config.reset_pass}/${token}`, payload)
      .then((res) => {
        console.log('Forget Password', res);
        setTimeout(() => {
          history.push('/');
        }, 3000);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        //console.log(error.response.data.message.name);
        if (error.response.data.message.name === 'TokenExpiredError') {
          history.push('/user-expired_link');
        } else {
          return toast.error(error.response.data.message.name, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      });
  };

  return (
    <>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4  ">
                <div className="resetPassword">
                  <Form className="mt-5" layout="vertical">
                    <Form.Item
                      label="Enter New Password"
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: 'Password field required',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter New Password"
                        name="new_password"
                        onChange={handelChange}
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          height: '40px',
                          borderRadius: '0.375rem',
                        }}
                      />
                    </Form.Item>
                    {passwordError ? (
                      <>
                        <div className="ant-form-item-explain ant-form-item-explain-connected">
                          <div
                            className="ant-form-item-explain-error"
                            style={{ marginTop: '0px' }}
                          >
                            {passwordError}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <Form.Item
                      label="Confirm Password"
                      name="confirm_password"
                      dependencies={['new_password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Password field required',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue('new_password') === value
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!',
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        name="confirm_password"
                        onChange={handelChange}
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          height: '40px',
                          borderRadius: '0.375rem',
                        }}
                      />
                    </Form.Item>
                    <div className="text-center mt-5">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="allBtns"
                          onClick={changePassword}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
