import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
const tradeColumns = [
  {
    title: 'Symbol',
    dataIndex: 'stock',
  },
  {
    title: 'Entry Time',
    dataIndex: 'entry_time',
    render: (text) => (
      <>
        {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
        {
          <p>
            <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
          </p>
        }
      </>
    ),
  },
  {
    title: 'Entry Price',
    dataIndex: 'entry_price',
  },
  {
    title: 'Exit Price',
    dataIndex: 'exit_price',
  },
  {
    title: 'Exit Time',
    dataIndex: 'exit_time',
    render: (text) => (
      <>
        {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
        {
          <p>
            <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
          </p>
        }
      </>
    ),
  },

  {
    title: 'Exit Type',
    dataIndex: 'exit_type',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'P&L',
    key: 'pnl',
    dataIndex: 'pnl',
    render: (pnl) => (
      <span>
        {pnl > 0 ? (
          <BsArrowUp className="text-success" />
        ) : (
          <BsArrowDown className="text-danger" />
        )}
        {
          <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
            {pnl}
          </span>
        }
      </span>
    ),
  },
  {
    title: 'Brokerage',
    dataIndex: 'brokerage',
  },
];
const closedColumns = [
  {
    title: 'Symbol',
    dataIndex: 'tradingsymbol',
  },
  {
    title: 'Order Time',
    dataIndex: 'order_timestamp',
    render: (text) => dayjs.utc(text).format('DD-MMM-YYYY hh:mm:ss a'),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },

  {
    title: 'Lot Size',
    dataIndex: 'lot_size',
  },

  {
    title: 'Trigger Price',
    dataIndex: 'trigger_price',
  },
  {
    title: 'Trade Action',
    dataIndex: 'trade_action',
  },

  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type',
  },

  {
    title: 'Exit Type',
    dataIndex: 'exit_type',
    render: (text) => (text ? text : '-'),
  },
];
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const PortfolioClosedTable = ({
  dataSource,
  from,
  pagination,
  handleTableChange,
}) => {
  return (
    <div>
      <Table
        columns={from === 'closed' ? closedColumns : tradeColumns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default React.memo(PortfolioClosedTable);
