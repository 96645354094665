import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import Dhan1 from "../../../../assets/images/Dhan1.png";
import Dhan2 from "../../../../assets/images/Dhan2.png";
import Dhan3 from "../../../../assets/images/Dhan3.png";
import Dhan4 from "../../../../assets/images/Dhan4.png";
import Dhan5 from "../../../../assets/images/Dhan5.png";
import Dhan6 from "../../../../assets/images/Dhan6.png";
import Header from "../../Header";

function DhanInstructions() {
  document.querySelector("body").style.overflow = "hidden";
  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-dhan-API-key"
                      role="tab"
                      aria-controls="v-pills-dhan-API-key"
                      aria-selected="true"
                    >
                      Steps to get Dhan API key
                    </a>
                    {/* <a
                    class="nav-link"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-TOTP"
                    role="tab"
                    aria-controls="v-pills-TOTP"
                    aria-selected="false"
                  >
                    Step to create TOTP
                  </a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-dhan-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get dhan API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            Note : First go with url to register API :
                            <br />
                            Url :{" "}
                            <a
                              href="https://dhanhq.co/trading-api-for-traders/"
                              target="_blank"
                            >
                              Login Dhan
                            </a>
                            <div className="mt-4 mb-4">
                              <img
                                src={Dhan1}
                                alt="Login Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                            <p className="mb-2">
                              Enter details and click on connect with us. We
                              receive mail for successful registration. If
                              already registered, ignore this.
                            </p>
                          </li>
                          <li>
                            Now follow the below steps.
                            <ul style={{ marginLeft: "20px" }}>
                              <li>
                                Login to web.dhan.co
                                <div className="mt-4 mb-4">
                                  <img
                                    src={Dhan2}
                                    alt="Login Image"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      height: "500px",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                Go to your profile
                                <div className="mt-4 mb-4">
                                  <img
                                    src={Dhan3}
                                    alt="profile Image"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      height: "500px",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                Click on the “Dhan HQ API’s & Access”
                                <div className="mt-4 mb-4">
                                  <img
                                    src={Dhan4}
                                    alt="API Image"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      height: "500px",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                This page will give you the option to generate
                                access token.
                                <div className="mt-4 mb-4">
                                  <img
                                    src={Dhan5}
                                    alt="access token Image"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      height: "500px",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                Click on the create button and enter your app
                                Name and submit.
                                <div className="mt-4 mb-4">
                                  <img
                                    src={Dhan6}
                                    alt="final Image"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      height: "500px",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>Now you can use an access token.</li>
                            </ul>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default DhanInstructions;
