import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import { Space, Table, Tag } from "antd";
import Paisa1 from "../../../../assets/images/5Paisa1.png";
import Paisa2 from "../../../../assets/images/5Paisa2.png";
import Paisa3 from "../../../../assets/images/5Paisa3.png";
import Paisa4 from "../../../../assets/images/5Paisa4.png";
import Header from "../../Header";

function PaiseInstructions() {
  document.querySelector("body").style.overflow = "hidden";

  const columns = [
    {
      title: "Field",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value) => {
        let data = value.split(".");
        return (
          <>
            <li>{data[0]}</li>
          </>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      name: "App Name",
      value: "Your App Name",
    },
    {
      key: "2",
      name: "Redirect URL",
      value: "Short Name of your APP",
    },
    {
      key: "3",
      name: "Description(Optional)",
      value:
        "URL to which we need to redirect after successful login authentication. Note: Code to generate the token will be sent as parameter to this URL",
    },
    {
      key: "4",
      name: "App Permissions",
      value: " Refer Permissions Template",
    },
    {
      key: "4",
      name: "Image(Optional)",
      value: "Images",
    },
  ];

  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-5Paise -API-key"
                      role="tab"
                      aria-controls="v-pills-5Paise-API-key"
                      aria-selected="true"
                    >
                      Steps to get 5Paise API key
                    </a>
                    {/* <a
                    class="nav-link"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-Token"
                    role="tab"
                    aria-controls="v-pills-Token"
                    aria-selected="false"
                  >
                    Token Generation Steps
                  </a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex ">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-5Paise-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get 5Paise API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            <p>Login to 5paisa account.</p>
                            <p>
                              Url :{" "}
                              <a
                                href="https://login.5paisa.com/"
                                target="_blank"
                              >
                                Login 5Paise
                              </a>
                            </p>
                            <div className="mt-4 mb-4">
                              <img
                                src={Paisa1}
                                alt="Login Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Click on the Menu section.
                            <div className="mt-4 mb-4">
                              <img
                                src={Paisa2}
                                alt="Menu Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Click on Developer Api
                            <div className="mt-4 mb-4">
                              <img
                                src={Paisa3}
                                alt="Developer API Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Click on Get API Keys to generate the 5paisa API
                            key.
                            <div className="mt-4 mb-4">
                              <img
                                src={Paisa4}
                                alt="API Key Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "500px",
                                }}
                              />
                            </div>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  {/* <div
                  class="tab-pane fade"
                  id="v-pills-Token"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <div className="mb-4">
                    <h4>Token Generation Steps </h4>
                    <p>
                      <ul style={{ paddingLeft: "30px" }}>
                        <li>
                          Client initiates a request to get token for his app
                        </li>
                        <li>
                          An authorization code will be returned if the app_id
                          and secret_id is valid from the above request
                        </li>
                        <li>
                          If the above request succeed, the user will be
                          redirected to the login page to authorize the app
                        </li>
                        <li>
                          User will provide login credentials which will be
                          validated from the server
                        </li>
                        <li>
                          On successfully validating the credentials,
                          access_token will be generated and redirect users to
                          the page specified in app dashboard
                        </li>
                      </ul>
                    </p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default PaiseInstructions;
