import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Accordion from 'react-bootstrap/Accordion';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const ReportTradesTable = ({ paginationCall, closedCards }) => {
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 700,
        overflow: 'auto',
        display: 'flex',
        // flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={closedCards.length}
        next={paginationCall}
        hasMore={true}
        loader={<h4>No Data Available</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {closedCards?.map((item) => (
          <div key={item._id}>
            <div className="tbl__hd mobile close__order vt">
              <div className="coll">Symbol</div>
              <div className="coll text-center">QTY</div>
              <div className="coll">P&L</div>
              <div className="coll">Brokerage</div>
              <div className="coll">Exit Type</div>
            </div>
            <Accordion className="close__order">
              <Accordion.Item eventKey={item._id}>
                <Accordion.Header>
                  <div className="tbl__body close__order vt">
                    <Tooltip title={item?.stock} placement="right">
                      <div className="coll text-truncate">{item?.stock}</div>
                    </Tooltip>
                    <div className="coll text-center">{item?.quantity}</div>
                    <div className="coll text-truncate">
                      <span
                        className={
                          item?.pnl > 0 ? 'text-success' : 'text-danger'
                        }
                      >
                        {item?.pnl}
                      </span>
                      <span>
                        {item?.pnl > 0 ? (
                          <BsArrowUp className="text-success" />
                        ) : (
                          <BsArrowDown className="text-danger" />
                        )}
                      </span>
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.brokerage}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.exit_type}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbl__body__inner close__order report">
                    <div className="coll text-truncate">Entry Time</div>
                    <div className="coll text-center text-truncate">
                      Entry Price
                    </div>
                    <div className="coll text-center text-truncate">
                      Exit Price
                    </div>
                    <div className="coll text-center text-truncate">
                      Exit Time
                    </div>
                  </div>

                  <div className="tbl__body__inner__data close__order report">
                    <Tooltip
                      title={dayjs
                        .utc(item?.entry_time)
                        .format('DD-MMM-YYYY hh:mm:ss a')}
                      placement="right"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.entry_time)
                          .format('DD-MMM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.entry_price}
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.exit_price}
                    </div>
                    <Tooltip
                      title={dayjs
                        .utc(item?.exit_time)
                        .format('DD-MMM-YYYY hh:mm:ss a')}
                      placement="left"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.exit_time)
                          .format('DD-MMM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ReportTradesTable;
