import React, { useState, useEffect } from 'react';
import './Button.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Select, DatePicker, TimePicker, Alert } from 'antd';
import moment from 'moment';
import '../Backtesting.css';
import axios from 'axios';
import { mydecodedTokenFunction } from '../../utils/decodetoken';

import { toast } from 'react-toastify';
import NoDataFound from '../Common/NoDataFound';
import config from '../../Config/config';
import ModalInputFields from './ModalInputFields';

import { Tabs } from 'antd';
const { TabPane } = Tabs;

const { Option } = Select;

const { RangePicker } = DatePicker;
function BackTesting({
  setFetchState,
  onback,
  inputs,
  strategy_id,
  onClose,
  involkeVT,
  from,
  involkeLT,
  setUpdateBtReport,
  modalData,
  reportData,
  VTReport,
  brokerId,
  configData,
  BrokerTab,
  iT_id,
  getLiveTradeData,
  getVirtualTradeData,
  getBroker,
  fetchState,
}) {
  //Checking if input contain more toggle value
  const checkStatus = () => {
    let temp = {};
    if (inputs && inputs.length > 0) {
      inputs?.map((data) => {
        temp = {
          ...temp,
          [data.value_name]: data.value_default,
        };
      });
      return temp;
    }
  };

  let todayDate = new Date().toLocaleString().split(',')[0];
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);

  const [checked, setChecked] = useState(checkStatus());
  const [ExchangeData, setExchangeData] = useState([]);
  const [allStocks, setallStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [activeTab, setActiveTab] = useState('Setup');
  const [saveNextButton, setSaveNextButton] = useState(true);
  const [connectedBroker, setConnectedBroker] = useState([]);
  const [brokerButton, setBrokerButton] = useState(false);
  const [refetch, setrefetch] = useState(false);
  const handleBrokerButton = () => {
    setBrokerButton(!brokerButton);
  };
  const [liveTradeButton, setLiveTradeButton] = useState(false);
  console.log(modalData);

  // DOW30 stock symbol
  let DOW30 = [
    'AXP',
    'AMGN',
    'AAPL',
    'BA',
    'CAT',
    'CSCO',
    'CVX',
    'GS',
    'HD',
    'HON',
    'IBM',
    'INTC',
    'JNJ',
    'KO',
    'JPM',
    'MCD',
    'MMM',
    'MRK',
    'MSFT',
    'NKE',
    'PG',
    'TRV',
    'UNH',
    'CRM',
    'VZ',
    'V',
    'WBA',
    'WMT',
    'DIS',
    'DOW',
  ];

  // NASDAQ stock symbol

  let NASDAQ = [
    'AMD',
    'ADBE',
    'ABNB',
    'ALGN',
    'AMZN',
    'AMGN',
    'AEP',
    'ADI',
    'ANSS',
    'AAPL',
    'AMAT',
    'ASML',
    'TEAM',
    'ADSK',
    'ATVI',
    'ADP',
    'AZN',
    'AVGO',
    'BIDU',
    'BIIB',
    'BMRN',
    'BKNG',
    'CDNS',
    'CHTR',
    'CPRT',
    'CRWD',
    'CTAS',
    'CSCO',
    'CMCSA',
    'COST',
    'CSX',
    'CTSH',
    'DDOG',
    'DOCU',
    'DXCM',
    'DLTR',
    'EA',
    'EBAY',
    'EXC',
    'FAST',
    'META',
    'FISV',
    'FTNT',
    'GILD',
    'GOOG',
    'GOOGL',
    'HON',
    'ILMN',
    'INTC',
    'INTU',
    'ISRG',
    'MRVL',
    'IDXX',
    'JD',
    'KDP',
    'KLAC',
    'KHC',
    'LRCX',
    'LCID',
    'LULU',
    'MELI',
    'MAR',
    'MTCH',
    'MCHP',
    'MDLZ',
    'MRNA',
    'MNST',
    'MSFT',
    'MU',
    'NFLX',
    'NTES',
    'NVDA',
    'NXPI',
    'OKTA',
    'ODFL',
    'ORLY',
    'PCAR',
    'PANW',
    'PAYX',
    'PDD',
    'PYPL',
    'PEP',
    'QCOM',
    'REGN',
    'ROST',
    'SIRI',
    'SGEN',
    'SPLK',
    'SWKS',
    'SBUX',
    'SNPS',
    'TSLA',
    'TXN',
    'TMUS',
    'VRSN',
    'VRSK',
    'VRTX',
    'WBA',
    'WDAY',
    'XEL',
    'ZM',
    'ZS',
  ];

  // Get all stock list
  useEffect(() => {
    axios
      .get(`${config.base_url}${config.all_stocks}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        let sortData = res.data?.data.sort((a, b) =>
          a.stock_name.localeCompare(b.stock_name),
        );
        setallStocks(sortData);
        setExchangeData(sortData);
      })
      .catch(function (status) {});
  }, []);

  const timeFormat = 'HH:mm';

  useEffect(() => {
    axios
      .get(`${config.base_url}${config.broker_crendientials}/${userid.id}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        console.log('Connected Brokers ', res.data.data);
        setConnectedBroker(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  // Function runs when form is completed.
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  // Function runs when form is failed to completed.

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Select.Option key={i.toString(36) + i}>
        {i.toString(36) + i}
      </Select.Option>,
    );
  }

  // Timepicker handel input change

  const handleInputFormtime = (e, name) => {
    setInputArray({
      ...inputArray,
      [name]: moment(e._d).format('HH:mm'),
    });
  };

  // handel x`change

  const handleselect = (name, value) => {
    setInputArray({
      ...inputArray,
      [name]: value,
    });
  };

  // handle change function for exchanges

  const handleInputFormExchange = (name, value) => {
    //console.log(value);
    setInputArray({ ...inputArray, [name]: value });
    if (value === 'NASDAQ100') {
      setallStocks(NASDAQ);
    } else if (value === 'DOW30') {
      setallStocks(DOW30);
    } else {
      setallStocks(ExchangeData);
    }
  };

  // select for handle input stocks

  const handleInputStocks = (name, value) => {
    //console.log(value);
    //setstocks(value);
    setInputArray({ ...inputArray, [name]: value });
  };

  //Function for date picker

  const datepickersvalues = (name, val) => {
    const getStartDates = (value) => {
      if (value !== '') {
        return moment(value[0]._d).format('DD-MM-YYYY HH:mm:ss');
      } else {
        return '';
      }
    };

    const getEndDates = (value) => {
      if (value !== '') {
        return moment(value[1]._d).format('DD-MM-YYYY HH:mm:ss');
      } else {
        return '';
      }
    };
    setInputArray({
      ...inputArray,
      [name]: [getStartDates(val), getEndDates(val)],
    });
  };

  const calcTopGL = (name, value) => {
    console.log(value);
    setChecked({ ...checked, [name]: value });
    setInputArray({ ...inputArray, [name]: value });
  };

  const handlePublish = async (e) => {
    setLoading(true);
    var payload1 = {
      user_id: userid.id,
      strategy_id: strategy_id,
      status: false,
      current_status: 'pending',
      vt_inputs: inputArray,
    };
    var payload2 = {
      user_id: userid.id,
      strategy_id: strategy_id,
      status: false,
      current_status: 'pending',
      lt_inputs: inputArray,
      credential_id: selectedBrokerId,
      broker_name: selectedBrokerName,
    };
    // var payload = {
    //   user_id: userid.id,
    //   strategy_id: strategy_id,
    //   status: 'pending',
    //   bt_inputs: inputArray,
    // };
    const checkValue = (data) => {
      var count = 0;
      for (let x in data) {
        if (
          data[x] !== '' &&
          data[x] !== [] &&
          data[x] !== undefined &&
          data[x] !== null
        ) {
          count++;
        }
      }
      if (count === Object.keys(data).length) {
        return false;
      } else return true;
    };
    if (checkValue(inputArray)) {
      setIsAlert(true);
      return;
    }
    console.log(payload2);
    setIsAlert(false);
    axios
      .post(
        modalData === 'virtual'
          ? `${config.base_url}${config.virtual_trade}`
          : `${config.base_url}${config.live_trade}`,
        modalData === 'live' ? payload2 : payload1,
        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      )
      .then((res) => {
        console.log('Response Data', res.data);
        onClose(false);
        onback();
        getVirtualTradeData();
        getLiveTradeData();

        // if (modalData === 'live') {
        //   involkeLT();
        // } else {
        //   involkeVT();
        // }

        setLoading(false);
        // setUpdateBtReport(res);

        if (res.data.status) {
          return toast.success(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(function (status) {
        setLoading(true);
      });
  };

  function updatePublish() {
    var payload = {
      user_id: userid.id,
      strategy_id: strategy_id,
      status: false,
      lt_inputs: inputArray,
      broker_name: upDatedBrokerName,
      credential_id: upDatedBrokerId,
      lt_id: iT_id,
    };

    axios
      .put(
        `${config.base_url}${config.live_trade}`,
        payload,

        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      )
      .then((res) => {
        console.log('Response Data', res.data);
        getBroker('today', 1);
        if (res.data.status) {
          return toast.success('Update Successfully ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(function (status) {});
  }

  const liveTradeTab = () => {
    const checkValue = (data) => {
      var count = 0;
      for (let x in data) {
        if (
          data[x] !== '' &&
          data[x] !== [] &&
          data[x] !== undefined &&
          data[x] !== null
        ) {
          count++;
        }
      }

      if (count === Object.keys(data).length) {
        return false;
      } else return true;
    };
    if (checkValue(inputArray)) {
      setIsAlert(true);
      return;
    }
    setActiveTab('Broker');
    setIsAlert(false);
    setSaveNextButton(false);
  };

  const child = [];

  if (allStocks?.length > 0) {
    var result = allStocks[0].stock_name
      ? allStocks.reduce((unique, o) => {
          if (!unique.some((obj) => obj.stock_name === o.stock_name)) {
            unique.push(o);
          }
          return unique;
        }, [])
      : allStocks;

    //console.log(result);

    for (let i = 0; i < result.length; i++) {
      child.push(
        <Option
          key={result[i].stock_name ? result[i].stock_name : result[i]}
          value={result[i].stock_name ? result[i].stock_name : result[i]}
        >
          {result[i].stock_name ? result[i].stock_name : result[i]}
        </Option>,
      );
    }
  }

  const [inputArray, setInputArray] = useState();

  useEffect(() => {
    if (inputs && inputs.length > 0) {
      let dummy = {};
      for (let i = 0; i < inputs.length; i++) {
        if (dummy[inputs[i].value_name] === undefined) {
          dummy[inputs[i].value_name] = inputs[i].value_default;
        }
      }
      setInputArray(dummy);
    }
  }, []);

  // Normal input handle change function

  const handelChangeInputs = (e, type) => {
    setInputArray({
      ...inputArray,
      [e.target.name]:
        type === 'float' || type === 'integer' || type === 'number'
          ? Number(e.target.value)
          : e.target.value,
    });
  };

  const handleButtonClick = (id) => {
    setConnectedBroker((prevData) =>
      prevData.map((item) =>
        item._id === id
          ? { ...item, connected: !item.connected }
          : { ...item, connected: false },
      ),
    );
  };
  const connectedCards = connectedBroker.filter((item) => item.connected);
  const [selectedBrokerId] = connectedCards.map((card) => card._id);
  const [selectedBrokerName] = connectedCards.map((card) => card.broker_name);

  const [upDatedBrokerId, setUpDatedBrokerId] = useState(brokerId);

  const handleUpdateClick = (id) => {
    const isAlreadyConnected = upDatedBrokerId === id;
    if (isAlreadyConnected) {
      return;
    }
    setUpDatedBrokerId(id);
  };
  // console.log(upDatedBrokerId);

  const upDatedBrokerName = connectedBroker.find(
    (card) => card._id === upDatedBrokerId,
  )?.broker_name;

  function saveChangesButton() {
    if (JSON.stringify(configData) === JSON.stringify(inputArray)) {
      if (brokerId === upDatedBrokerId) {
        return true;
      } else {
        return false;
      }
    }
    if (JSON.stringify(configData) !== JSON.stringify(inputArray)) {
      if (brokerId !== upDatedBrokerId) {
        return false;
      } else if (brokerId === upDatedBrokerId) {
        return false;
      }
    }
  }
  function generateReportButton() {
    if (VTReport === 'VTReport') {
      return true;
    } else if (inputArray?.report_name === '') {
      return true;
    }
    return false;
  }

  return (
    <>
      {isAlert && (
        <div
          className="container w-25 mt-2"
          style={{ marginBottom: '-47.5px' }}
        >
          <Alert
            message="Please fill the entire form!"
            type="warning"
            closable
            style={{ zIndex: 10 }}
            onClose={() => setIsAlert(false)}
          />
        </div>
      )}

      {/* <Header /> */}

      <div className="mb-2">
        <div className="row">
          <div className="col-12">
            {modalData === 'live' ? (
              <div>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === 'Setup' ? 'active' : ''
                      } fsize-20`}
                      onClick={() => setActiveTab('Setup')}
                      role="tab"
                      aria-controls="Setup"
                      aria-selected={activeTab === 'Setup'}
                    >
                      Setup
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === 'Broker' ? 'active' : ''
                      } fsize-20`}
                      onClick={() => {
                        if (
                          BrokerTab === 'BrokerTab' &&
                          JSON.stringify(configData) ===
                            JSON.stringify(inputArray)
                        ) {
                          setActiveTab('Broker');
                        }
                      }}
                      role="tab"
                      aria-controls="Broker"
                      aria-selected={activeTab === 'Broker'}
                    >
                      Broker
                    </a>
                  </li>
                </ul>

                <div className="tab-content mt-3">
                  <div
                    className={`tab-pane ${
                      activeTab === 'Setup' ? 'active' : ''
                    }`}
                    id="Setup"
                    role="tabpanel"
                    aria-labelledby="Setup-tab"
                  >
                    <Form
                      name="basic"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="mt-4"
                    >
                      {inputs && inputs.length > 0 ? (
                        <ModalInputFields
                          inputs={inputs}
                          handleInputFormExchange={handleInputFormExchange}
                          handleselect={handleselect}
                          handleInputStocks={handleInputStocks}
                          datepickersvalues={datepickersvalues}
                          calcTopGL={calcTopGL}
                          handleInputFormtime={handleInputFormtime}
                          handelChangeInputs={handelChangeInputs}
                          checked={checked}
                          timeFormat={timeFormat}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                      <div className="modal-footer  mt-2 ps-0 pe-0">
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn_kill_cancel me-2"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn_kill_submit"
                            disabled={
                              JSON.stringify(configData) ===
                              JSON.stringify(inputArray)
                            }
                            onClick={() => {
                              liveTradeTab();
                            }}
                          >
                            Save & Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === 'Broker' ? 'active' : ''
                    }`}
                    id="Broker"
                    role="tabpanel"
                    aria-labelledby="Broker-tab"
                  >
                    <div className="clearfix py-3">
                      <div className="">
                        <h5 className="float-left">Available Brokers</h5>
                      </div>
                    </div>
                    <div className="d-flex row">
                      {connectedBroker.map((item) => (
                        <div className="col-lg-3" key={item.broker_name}>
                          <div className="broker_card d-flex flex-column justify-content-center">
                            <div className="d-flex justify-content-center">
                              <img
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '70px',
                                }}
                                src={item.logo_img}
                                alt="No-img"
                              />
                            </div>
                            <h3>{item?.broker_name}</h3>
                            <span className="fsize-13">
                              {item?.description}
                              {/* Plan properly your workflow */}
                            </span>
                            <p className="py-4">
                              Avl. Balance:{' '}
                              <span style={{ color: '#4074F9' }}>
                                {item?.funds}
                              </span>
                              <br />
                              <span className="fsize-13">
                                Client ID:{item?.id}
                              </span>
                            </p>
                            <a
                              className={
                                brokerId
                                  ? upDatedBrokerId === item._id
                                    ? 'btn_disconnect py-2'
                                    : 'btn_connect py-2'
                                  : item.connected
                                  ? 'btn_disconnect py-2'
                                  : 'btn_connect py-2'
                              }
                              onClick={() =>
                                brokerId
                                  ? handleUpdateClick(item._id)
                                  : handleButtonClick(item._id)
                              }
                            >
                              {brokerId
                                ? upDatedBrokerId === item._id
                                  ? 'Disconnect'
                                  : 'Connect'
                                : item.connected
                                ? 'Disconnect'
                                : 'Connect'}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="modal-footer justify-content-right mt-4 ps-0 pe-0">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn_kill_cancel me-2"
                          onClick={() => onClose()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn_kill_submit"
                          data-dismiss="modal"
                          // disabled={
                          //   brokerId !== upDatedBrokerId ||
                          //   JSON.stringify(configData) ===
                          //     JSON.stringify(inputArray)
                          // }
                          disabled={saveChangesButton()}
                          onClick={() => {
                            onClose();
                            brokerId ? updatePublish() : handlePublish();
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="mt-4"
                >
                  {inputs && inputs.length > 0 ? (
                    <ModalInputFields
                      inputs={inputs}
                      handleInputFormExchange={handleInputFormExchange}
                      handleselect={handleselect}
                      handleInputStocks={handleInputStocks}
                      datepickersvalues={datepickersvalues}
                      calcTopGL={calcTopGL}
                      handleInputFormtime={handleInputFormtime}
                      handelChangeInputs={handelChangeInputs}
                      checked={checked}
                      timeFormat={timeFormat}
                    />
                  ) : (
                    <NoDataFound />
                  )}
                  <div className="modal-footer  mt-2 ps-0 pe-0">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn_kill_cancel me-2"
                        onClick={() => onClose()}
                        // onClick={() => setTradeSetting(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn_kill_submit"
                        disabled={generateReportButton()}
                        onClick={handlePublish}
                      >
                        Generate Report
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BackTesting;
