export function calculateBrokerage(
  entryPrice,
  exitPrice,
  quantity,
  positionType,
  broker,
  marketType = 'options',
) {
  if (broker === 'Zerodha') {
    let gst = 0.18;
    let sebiCharges = 0.000001;
    let clearingCharge = 0;
    let sttCharges, exchangeCharges, stampDutyCharges, kiteBrokerage;
    let buyPrice;
    let sellPrice;

    if (marketType === 'options') {
      sttCharges = 0.0625;
      exchangeCharges = 0.0505;
      stampDutyCharges = 0.00003;
      kiteBrokerage = 40;
    } else {
      sttCharges = 0.01;
      exchangeCharges = 0.002;
      stampDutyCharges = 0.00002;
      kiteBrokerage = 0.03;
    }
    if (positionType === 'LONG') {
      buyPrice = entryPrice;
      sellPrice = exitPrice;
    } else {
      buyPrice = exitPrice;
      sellPrice = entryPrice;
    }

    const turnover = buyPrice * quantity + sellPrice * quantity;

    let brokerage;
    if (marketType === 'options') {
      brokerage = kiteBrokerage;
    } else {
      brokerage = Math.min(
        40,
        Math.round(turnover * (kiteBrokerage / 100) * 100) / 100,
      );
    }

    const stt = Math.round((quantity * sellPrice * sttCharges) / 100);

    exchangeCharges =
      Math.round((turnover / 100) * exchangeCharges * 100) / 100;

    sebiCharges = Math.round(turnover * sebiCharges * 100) / 100;

    const stampDuty = Math.round(quantity * buyPrice * stampDutyCharges);

    let totalCharges = brokerage + sebiCharges + exchangeCharges;

    gst = Math.round(totalCharges * gst * 100) / 100;

    totalCharges =
      Math.round(
        (brokerage +
          sebiCharges +
          exchangeCharges +
          stt +
          clearingCharge +
          stampDuty +
          gst) *
          100,
      ) / 100;

    // Calculating Net PnL
    const netPL = (sellPrice - buyPrice) * quantity - totalCharges;

    return [totalCharges, netPL];
  } else if (broker === 'Kotak') {
    // console.log('kotak');
    let gst = 0.18;
    let sebiCharges = 0.000001;
    let clearingCharge = 0;
    let brokerage = 0;
    let sttCharges, exchangeCharges, stampDutyCharges;
    let buyPrice;
    let sellPrice;
    if (marketType === 'options') {
      sttCharges = 0.0625;
      exchangeCharges = 0.0505;
      stampDutyCharges = 0.00003;
    } else {
      sttCharges = 0.01;
      exchangeCharges = 0.002;
      stampDutyCharges = 0.00002;
    }
    if (positionType === 'LONG') {
      buyPrice = entryPrice;
      sellPrice = exitPrice;
    } else {
      buyPrice = exitPrice;
      sellPrice = entryPrice;
    }

    const turnover = buyPrice * quantity + sellPrice * quantity;

    const stt = (quantity * sellPrice * sttCharges) / 100;

    exchangeCharges = (turnover / 100) * exchangeCharges;

    sebiCharges = turnover * sebiCharges;

    const stampDuty = quantity * buyPrice * stampDutyCharges;

    let totalCharges = brokerage + sebiCharges + exchangeCharges;

    gst = totalCharges * gst;

    totalCharges =
      brokerage +
      sebiCharges +
      exchangeCharges +
      stt +
      clearingCharge +
      stampDuty +
      gst;

    const netPL = (sellPrice - buyPrice) * quantity - totalCharges;

    return [totalCharges, netPL];
  } else {
    return [0, 0];
  }
}
