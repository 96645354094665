import React from "react";
import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "reactstrap";
import "../../ReportPdf.css";
import { Space, Table, Tag } from "antd";
import flat1 from "../../../../assets/images/flat1.png";
import flat2 from "../../../../assets/images/flat2.png";
import flat3 from "../../../../assets/images/flat3.png";
import flat4 from "../../../../assets/images/flat4.png";
import Header from "../../Header";

function FlatTradeInstructions() {
  document.querySelector("body").style.overflow = "hidden";

  const columns = [
    {
      title: "Field",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value) => {
        let data = value.split(".");
        return (
          <>
            <li>{data[0]}</li>
            <li>{data[1]}</li>
          </>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      name: "App Name",
      value: "Your App Name",
    },
    {
      key: "2",
      name: "App ShortName",
      value: "Short Name of your APP",
    },
    {
      key: "3",
      name: "Redirect URL",
      value:
        "URL to which we need to redirect after successful login authentication. Note: Code to generate the token will be sent as parameter to this URL",
    },
    {
      key: "4",
      name: "Description",
      value: "Short description about your app",
    },
  ];

  return (
    <>
      <Header />
      <div className="mt-5">
        <Container fluid className="instruction">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-3">
                  <h5 className="text-muted text-capitalize">Overview</h5>

                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-flattrade -API-key"
                      role="tab"
                      aria-controls="v-pills-flattrade-API-key"
                      aria-selected="true"
                    >
                      Steps to get flattrade API key
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-Token"
                      role="tab"
                      aria-controls="v-pills-Token"
                      aria-selected="false"
                    >
                      Token Generation Steps
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex ">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-flattrade -API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get flattrade API key:</h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            Login to Wall
                            <br />
                            Url :{" "}
                            <a
                              href="https://wall.flattrade.in/"
                              target="_blank"
                            >
                              Login flattrade
                            </a>
                          </li>
                          <li>
                            Navigate to Pi in top menu bar and click on “CREATE
                            NEW API KEY”
                            <div className="mt-4 mb-4">
                              <img
                                src={flat1}
                                alt="CREATE NEW API KEY Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "150px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Use the following values to enter the form
                            <Table
                              columns={columns}
                              dataSource={data}
                              style={{ marginTop: "20px" }}
                              pagination={false}
                            />
                            <div className="mt-4 mb-4">
                              <img
                                src={flat2}
                                alt="Form Image"
                                style={{
                                  width: "80%",
                                  borderRadius: "20px",
                                  height: "700px",
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            Your API key is now generated
                            <div className="mt-4 mb-4">
                              <img
                                src={flat3}
                                alt="Generate API Key Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "200px",
                                }}
                              />
                            </div>
                          </li>
                          <li>Copy the API KEY</li>
                          <li>
                            Click the eye icon to view the secret key. Copy
                            secret key
                            <div className="mt-4 mb-4">
                              <img
                                src={flat4}
                                alt="secret Key Image"
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  height: "200px",
                                }}
                              />
                            </div>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-Token"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="mb-4">
                      <h4>Token Generation Steps </h4>
                      <p>
                        <ul style={{ paddingLeft: "30px" }}>
                          <li>
                            Open the Authorization URL
                            https://auth.flattrade.in/?app_key=APIKEY in browser
                          </li>
                          <li>
                            Enter your Client id (UCC), Password, PAN/DOB and
                            submit.
                          </li>
                          <li>
                            After you are authorized in our authentication
                            portal, the authorization screen will redirect to
                            your URL with request_token
                            <p>
                              Example:
                              <a
                                href="https://yourRedirectURL.com/?request_code=requestCodeValue"
                                target="_blank"
                              >
                                https://yourRedirectURL.com/?request_code=requestCodeValue
                              </a>
                            </p>
                          </li>
                          <li>
                            Call to https://authapi.flattrade.in/trade/apitoken
                            in POST method to validate request_code and get the
                            token
                            <div
                              style={{
                                border: "1px solid black",
                                borderRadius: "10px",
                                maxWidth: "400px",
                                marginTop: "10px",
                              }}
                            >
                              {`{
                              api_key: "xcvvwegfhgh4454646",
                              Request_code:
                                "xxdfddfdfdsfdsf84okkdlfelfdfdfd345fsf",
                              api_secret: "sdfdsfsdfdsfXXXXXXX",
                            }`}
                            </div>
                          </li>
                          <li>
                            You will get a response with the following values.
                            This token can be used in appropriate endpoints to
                            get more details of the user
                            <div
                              style={{
                                border: "1px solid black",
                                borderRadius: "10px",
                                maxWidth: "400px",
                                marginTop: "10px",
                              }}
                            >
                              {`{
                                "Token":"dsfdsf84okkdlfelfdfdfd3454545454ssdfsf",
                              "client":"CCODE123",
                                "status":"Ok",
                                "Emsg":""
                              }
                              `}
                            </div>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default FlatTradeInstructions;
