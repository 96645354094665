import React from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

const PortfolioOpenCards = ({
  lastOrder,
  handleCheckboxChange,
  selectedItems,
  tab,
}) => {
  let history = useHistory();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  return (
    <div className="card">
      <div className="card-header">
        <div className="trd__data__head">
          <div className="coll">Select</div>
          <div className="coll">Strategy</div>
          <div className="coll">Report Name</div>
          <div className="coll">Symbol</div>
          <div className="coll">Order Time</div>
          <div className="coll">Lot Size</div>
          <div className="coll">QTY.</div>
          <div className="coll">Avg.</div>
          <div className="coll">LTP</div>
          <div className="coll">P&L</div>
          <div className="coll">Charge</div>
        </div>
      </div>

      <div className="card-body">
        {lastOrder.map((item) => (
          <div key={item.request_id} className="card crd__row">
            <div className="trd__data__body">
              <div className="coll">
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(item.request_id)}
                  checked={selectedItems.includes(item.request_id)}
                />
              </div>
              <div
                className="coll"
                onClick={() => history.push(`/strategy/${item?.strategy_id}`)}
              >
                <span className="text-primary">{item?.strategys}</span>
              </div>

              <div
                className="coll"
                onClick={() =>
                  history.push(
                    `/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
                      item?.request_id
                    }`,
                  )
                }
              >
                <span className="text-primary">{item?.vt_requests}</span>
              </div>
              <div className="coll text-truncate">{item?.tradingsymbol}</div>
              <div className="coll text-truncate">
                {dayjs.utc(item?.entryTime).format('DD-MMM-YYYY hh:mm:ss a')}
              </div>
              <div className="coll">{item?.lot_size}</div>
              <div className="coll">{item?.quantity_left}</div>
              <div className="coll">{item?.entryPrice}</div>
              <div className="coll">{item?.livePrice}</div>
              <div className="coll arrow">
                <span>
                  {item?.pnl > 0 ? (
                    <BsArrowUp className="text-success" />
                  ) : (
                    <BsArrowDown className="text-danger" />
                  )}
                </span>
                <span
                  className={item?.pnl > 0 ? 'text-success' : 'text-danger'}
                >
                  {item?.pnl}
                </span>
              </div>
              <div className="coll">{item.charges}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(PortfolioOpenCards);
