import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

function DateTime() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <span className="primary__text__mute">
      {dayjs(new Date()).format('DD-MMM-YYYY')}
      <span className="ml-2">{time.toLocaleTimeString()}</span>
    </span>
  );
}

export default DateTime;
