import React, { Component } from 'react';
import { Button, Table, Tag } from 'antd';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Tooltip from '@mui/material/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import dayjs from 'dayjs';

const TradeMobileCards = ({ tradeOrdersData, tradeCall, tab, hasMore }) => {
  let history = useHistory();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 700,
        overflow: 'auto',
        display: 'flex',
        // flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={tradeOrdersData.length}
        next={tradeCall}
        hasMore={true}
        loader={<h4>No Data Available</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {tradeOrdersData?.map((item, index) => (
          <div key={index}>
            <div className="tbl__hd mobile close__order trade">
              <div className="coll text-truncate">Report Name</div>
              <div className="coll">Symbol</div>
              <div className="coll text-center">QTY</div>
              <div className="coll">P&L</div>
              <div className="coll">Exit Type</div>
            </div>
            <Accordion className="close__order trade">
              <Accordion.Item eventKey={item?._id}>
                <Accordion.Header>
                  <div className="tbl__body close__order trade">
                    <div
                      className="coll text-truncate"
                      onClick={() =>
                        history.push(
                          `/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
                            item?.request_id
                          }`,
                        )
                      }
                    >
                      <span className="text-primary">{item?.report_name}</span>
                    </div>
                    <Tooltip title={item?.stock} placement="top">
                      <div className="coll text-truncate">{item?.stock}</div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.quantity}
                    </div>
                    <div className="coll text-truncate">
                      <span
                        className={
                          item?.pnl > 0 ? 'text-success' : 'text-danger'
                        }
                      >
                        {item?.pnl}
                      </span>
                      <span>
                        {item?.pnl > 0 ? (
                          <BsArrowUp className="text-success" />
                        ) : (
                          <BsArrowDown className="text-danger" />
                        )}
                      </span>
                    </div>
                    <div className="coll text-center text-truncate">
                      {item?.exit_type}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbl__body__inner close__order">
                    <div className="coll text-truncate">Strategy</div>
                    <div className="coll text-truncate">Entry Time</div>
                    <div className="coll text-center text-truncate">
                      Entry Price
                    </div>
                    <div className="coll text-truncate">Exit Time</div>
                    <div className="coll text-center text-truncate">
                      Exit Price
                    </div>
                    <div className="coll text-center text-truncate">
                      Brokerage
                    </div>
                  </div>

                  <div className="tbl__body__inner__data close__order">
                    <div
                      className="coll text-truncate"
                      onClick={() =>
                        history.push(`/strategy/${item?.strategy_id}`)
                      }
                    >
                      <span className="text-primary">{item?.st_name}</span>
                    </div>
                    <Tooltip
                      title={dayjs
                        .utc(item?.entry_time)
                        .format('DD-MM-YYYY hh:mm:ss a')}
                      placement="top"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.entry_time)
                          .format('DD-MM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.entry_price}
                    </div>
                    <Tooltip
                      title={dayjs
                        .utc(item?.exit_time)
                        .format('DD-MM-YYYY hh:mm:ss a')}
                      placement="top"
                    >
                      <div className="coll text-truncate">
                        {dayjs
                          .utc(item?.exit_time)
                          .format('DD-MM-YYYY hh:mm:ss a')}
                      </div>
                    </Tooltip>
                    <div className="coll text-center text-truncate">
                      {item?.exit_price}
                    </div>

                    <div className="coll text-center text-truncate">
                      {item?.brokerage}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
        {/* {hasMore || tradeOrdersData.length === 0 ? (
          // Show a message when there's no more data to load
          <p>No more data to load</p>
        ) : null} */}
      </InfiniteScroll>
    </div>
  );
};

export default React.memo(TradeMobileCards);
