import React, { useState, useEffect } from 'react';
import '../UI/Header.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FuturisticWhiteLogo from '../../img/futuristic_white_logo.png';
import { useLocation, useHistory, NavLink, Link } from 'react-router-dom';
import moment from 'moment';
// import { SocketContext, chartSocket, priceSocket } from '../../context/socket';
import { chartSocket, priceSocket, tradeSocket } from '../../context/socket';
import makeStyles from '@mui/styles/makeStyles';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { ToastContainer, toast } from 'react-toastify';
import UserProfile from '../../utils/UserProfile';
import {
  Button as Button1,
  Dropdown as AntdDropDown,
  Form,
  Input,
  Modal,
  Popover,
  Switch,
} from 'antd';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { BsBell } from '../../assets/Icon';
// import { socket } from '../../context/socket';
import config from '../../Config/config';

// const pages = ['NSE', 'BSE', ];
// const settings = ['Dashboard', 'Profile', 'Logout'];
const settings = ['Change Password', 'Logout'];

var menuTab = '';

const Header = (props) => {
  const route = window.location.pathname;
  const [active, setActive] = useState(false);
  useEffect(() => {
    let modified = route.split('/')[1];
    //console.log(modified);
    if (
      modified === 'strategies' ||
      modified === 'strategy' ||
      modified === 'view-report'
    ) {
      //console.log("route1", route);
      setActive('route1');
    } else {
      //console.log("route2", route);
      setActive('route2');
    }
  }, []);
  //console.log(active);
  const [dropVisibility, setDropVisibility] = useState(true);
  // const socket = React.useContext(SocketContext);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { pathname } = location;
  const [profileVisible, setProfileVisibility] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isActive, setisActive] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let currentPassword = secureLocalStorage.getItem('User_Details');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  const [ButtonLoader, setButtonLoader] = useState(false);

  //console.log(currentPassword);

  const [updatedPassword, setUpdatedPassword] = useState({
    verify_password: '',
    old_password: '',
    new_password: '',
    user_id: userid ? userid.id : '',
  });
  const [passwordError, setPasswordError] = useState('');
  const token = localStorage.getItem('token');
  const [oldPassError, setOldpassError] = useState('');
  // const { alertNum, alertNumOrder } = props;
  const [notification, setOpenNotification] = useState(false);
  const hide = () => {
    setOpenNotification(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpenNotification(true);
  };

  const closeModal = (Component) => {
    profileVisible ? setProfileVisibility(false) : setProfileVisibility(true);
  };

  // const [isActiveHome, setisActiveHome] = React.useState(true)

  // console.log(isActive);
  const useStyles = makeStyles({
    button: {
      '&.active': {
        borderBottom: '3px solid white',
        color: 'white',
        fontSize: '0.875rem',
        fontWeight: '500',
        background: '#fc7303',
      },
    },
  });

  // useEffect(() => {
  //   socket.on('new_alert_doc', (data) => {
  //     setIsAlert(true);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   socket.on('new_orders', (data) => {
  //     setIsOrder(true);
  //   });
  // }, [socket]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseNavItem = (elm) => {
    if (elm === 'Logout') {
      //console.log(elm);

      localStorage.removeItem('token');
      window.location.href = '/';
      setAnchorElNav(null);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // const handleRedirectMenu = (value) => {
  //   console.log(value);
  //   if (value === "alterts") {
  //         history.push("/");

  //   }else{
  //   history.push("/backtesting-report");

  //   }
  // };

  const handleRedirectAlertMenu = () => {
    setisActive(false);
  };

  const handleRedirectBackMenu = () => {
    console.log('hhhhnh');
    setisActive(true);
  };

  const [anchorElNew, setAnchorElNew] = useState(null);
  const [anchorElNew1, setAnchorElNew1] = useState(null);

  const openNew = Boolean(anchorElNew);
  const openMenu = Boolean(anchorElNew1);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };
  const handleClickNew1 = (event) => {
    setAnchorElNew1(event.currentTarget);
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };
  const handleCloseNew1 = () => {
    setAnchorElNew1(null);
  };
  const date = new Date().toLocaleDateString();
  //Convert timestamp in GMT/UTC format
  const utcDate = new Date().toUTCString();
  let time = new Date().toLocaleTimeString();

  const [realTime, setTime] = useState(time);

  const realTimeFunc = () => {
    setTime(moment(new Date()).format('LTS'));
  };

  setInterval(realTimeFunc, 1000);
  const classes = useStyles();

  const onChangePassword = (e) => {
    let list = { ...updatedPassword, [e.target.name]: e.target.value };
    CheckpasswordError(list);
    setUpdatedPassword({ ...updatedPassword, [e.target.name]: e.target.value });
  };

  const CheckpasswordError = (list) => {
    if (list.old_password !== currentPassword.password) {
      return setOldpassError('Invalid password');
    } else {
      setOldpassError('');
    }
  };

  const updatedPass = () => {
    if (updatedPassword.new_password !== updatedPassword.verify_password) {
      return;
    }
    // console.log(updatedPassword);

    if (updatedPassword.old_password !== currentPassword.password) {
      return setOldpassError('Invalid password');
    } else {
      setOldpassError('');
    }

    if (
      updatedPassword.old_password === '' ||
      updatedPassword.new_password === ''
    ) {
      return toast.warning('All fields are mandatory', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

    // var minMaxLength = /^[\s\S]{8,16}$/,
    //   upper = /[A-Z]/,
    //   lower = /[a-z]/,
    //   number = /[0-9]/;

    if (updatedPassword.new_password.length >= 8) {
      setPasswordError('');
    } else {
      return setPasswordError('Password should be atleast 8 character long.');
    }

    let payload = {
      old_password: updatedPassword.old_password,
      new_password: updatedPassword.new_password,
      user_id: updatedPassword.user_id,
    };
    console.log(payload);
    setButtonLoader(true);

    axios
      .post(`${config.base_url}${config.change_user_pass}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        localStorage.removeItem('token');
        setButtonLoader(false);
        setIsModalVisible(false);
        secureLocalStorage.setItem('User_Details', {
          ...currentPassword,
          password: updatedPassword.new_password,
        });
        setTimeout(() => {
          // history.push("/login");
          window.location.href = '/login';
        }, 3000);
        console.log('Updated Data', res.data);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        setButtonLoader(false);
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const notificationData = (
    <div className="notificationWindow">
      <div className="d-flex align-items-center">
        <button className="w-100 text-center notificationBttns active">
          General
        </button>
        <button className="w-100 text-center notificationBttns">
          Transactions
        </button>
      </div>

      <div className="zeroNotification d-flex align-items-center justify-content-center">
        <div>0 Nofification</div>
      </div>
    </div>
  );

  chartSocket.on('disconnect', () => {
    console.log('chartSocket', chartSocket.connected);
  });

  return (
    <>
      <div className="mx-3 round-10 overflow-hidden bgLight mb-2">
        <AppBar
          position="static"
          className="p-1 bgLight"
          style={{ boxShadow: 'none' }}
        >
          <Container maxWidth={'xxl'}>
            <Toolbar disableGutters style={{ minHeight: '48px' }}>
              {/* <Typography
              onClick={() => history.push("/")}
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              LOGO
            </Typography> */}
              <Link to={'/strategies'}>
                <div style={{ width: 140 }}>
                  <img
                    style={{ width: '100%', height: '100%' }}
                    src={FuturisticWhiteLogo}
                    alt=""
                  />
                </div>
              </Link>

              {/* Realtime */}
              {/* <MenuItem style={{ cursor: "default" }}>
                <AccessTimeIcon />
                <p className="m-0 pl-1"> {realTime}</p>
              </MenuItem> */}

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'block', lg: 'none', md: 'none', xl: 'block' },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {/* {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}
                    className="mt-1 mb-1">
                    <Typography textAlign="center"
                      className="mt-1 mb-1">{page}</Typography>
                  </MenuItem>
                ))} */}
                </Menu>
              </Box>

              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                LOGO
              </Typography>

              <Box
                className="align-items-center"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  marginLeft: 'auto',
                  marginRight: '26px',
                }}
              >
                <NavLink
                  to={'/strategies'}
                  exact
                  // activeStyle={{
                  //   color: "#4074F9",
                  //   fontWeight: "500",
                  // }}
                  style={{
                    color: '#000',
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    marginRight: '27px',
                    position: 'relative',
                  }}
                  sx={{ my: 0, color: 'white', display: 'block' }}
                  activeClassName="admin-link-active"
                >
                  {active === 'route1' ? (
                    <p
                      style={{ color: '#4074F9', fontWeight: '500' }}
                      className="admin-link-active"
                    >
                      STRATEGY
                    </p>
                  ) : (
                    'STRATEGY'
                  )}
                </NavLink>
                {/* <NavLink
                  to={"/"}
                  exact
                  activeStyle={{
                    color: "#4074F9",
                    fontWeight: "500",
                  }}
                  style={{
                    color: "#000",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    marginRight: "27px",
                    position: "relative",
                  }}
                  sx={{ my: 0, color: "white", display: "block" }}
                >
                  {alertNum === 0 && isAlert ? (
                    <Badge
                      color="error"
                      badgeContent={"!"}
                      // className="mt-3"
                      style={{
                        position: "absolute",
                        top: -2,
                        right: -2,
                      }}
                    ></Badge>
                  ) : (
                    ""
                  )}
                  ALERTS
                </NavLink> */}
                <NavLink
                  to={'/chart'}
                  exact
                  activeStyle={{
                    color: '#4074F9',
                    fontWeight: '500',
                  }}
                  style={{
                    color: '#000',
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    marginRight: '27px',
                    position: 'relative',
                  }}
                  sx={{ my: 0, color: 'white', display: 'block' }}
                  activeClassName="admin-link-active"
                >
                  CHART
                </NavLink>

                {/* <NavLink
                  to={'/profit-n-loss'}
                  exact
                  activeStyle={{
                    color: '#4074F9',
                    fontWeight: '500',
                  }}
                  style={{
                    color: '#000',
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    marginRight: '27px',
                    position: 'relative',
                  }}
                  sx={{ my: 0, color: 'white', display: 'block' }}
                  activeClassName="admin-link-active"
                > */}
                {/* {alertNumOrder === 0 && isOrder ? (
                    <Badge
                      color="error"
                      badgeContent={"!"}
                      // className="mt-3"
                      style={{
                        position: "absolute",
                        top: -2,
                        right: -2,
                      }}
                    ></Badge>
                  ) : (
                    ""
                  )} */}
                {/* P&L
                </NavLink> */}
                <NavLink
                  to={'/broker'}
                  exact
                  activeStyle={{
                    color: '#4074F9',
                    fontWeight: '500',
                  }}
                  style={{
                    color: '#000',
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    marginRight: '27px',
                    position: 'relative',
                  }}
                  sx={{ my: 0, color: 'white', display: 'block' }}
                  activeClassName="admin-link-active"
                >
                  BROKER
                </NavLink>

                <NavLink
                  to="#"
                  style={{
                    color: '#000',
                    fontSize: '1.3rem',
                    fontWeight: '400',
                    position: 'relative',
                  }}
                >
                  <Popover
                    content={notificationData}
                    trigger={['click']}
                    visible={notification}
                    onOpenChange={handleOpenChange}
                    placement={'topRight'}
                    overlayClassName={'notificationPopover'}
                  >
                    <BsBell />
                  </Popover>
                </NavLink>

                {/* </Button> */}
              </Box>

              {/* <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Settings">
                  <IconButton
                    onClick={() => setProfileVisibility(true)}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      alt="T"
                      src="/static/images/avatar/2.jpg"
                      style={{ width: '30px', height: '27px' }}
                    />
                  </IconButton>
                </Tooltip>
              </Box> */}

              <Box
                sx={{
                  flexGrow: 0,
                  marginRight: '10px',
                  display: { xs: 'block', lg: 'none', md: 'none', xl: 'block' },
                }}
              >
                <Tooltip title="Settings">
                  <IconButton
                    id="basic-button"
                    aria-controls={openMenu ? 'menu1' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClickNew1}
                    sx={{ p: 0 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu1"
                  anchorEl={anchorElNew1}
                  open={openMenu}
                  onClose={handleCloseNew1}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      menuTab = 'Strategy';
                      handleCloseNew1();
                      history.push('/strategies');
                    }}
                  >
                    Strategy
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      menuTab = 'Chart';
                      handleCloseNew1();
                      history.push('/chart');
                    }}
                  >
                    Chart
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      menuTab = 'P&L';
                      handleCloseNew1();
                      history.push('/profit-n-loss');
                    }}
                  >
                    P&L
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      menuTab = 'Broker';
                      handleCloseNew1();
                      history.push('/broker');
                    }}
                  >
                    Broker
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Settings">
                  <IconButton
                    id="basic-button"
                    aria-controls={openNew ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNew ? 'true' : undefined}
                    onClick={handleClickNew}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      alt="T"
                      src="/static/images/avatar/2.jpg"
                      style={{ width: '30px', height: '30px' }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElNew}
                  open={openNew}
                  onClose={handleCloseNew}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      menuTab = 'ViewProfile';
                      handleCloseNew();
                      setProfileVisibility(true);
                    }}
                  >
                    View Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      menuTab = 'EditProfile';
                      handleCloseNew();
                      setProfileVisibility(true);
                    }}
                  >
                    Edit Profile
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      handleCloseNew();
                    }}
                  >
                    My account
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      // socket.disconnect();
                      priceSocket.disconnect();
                      chartSocket.disconnect();
                      tradeSocket.disconnect();
                      handleCloseNew();
                      localStorage.removeItem('token');
                      // history.push('/');
                      window.location.href = '/';
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <Modal
        footer={null}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="row mt-4">
          <div className="col-12">
            <h5>Change Password</h5>
          </div>
          <div className="col-12">
            <Form className="mt-3" layout="vertical">
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: 'Password is required',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter current password"
                  name="old_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              {oldPassError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: '0px' }}
                    >
                      {oldPassError}
                    </div>
                  </div>
                </>
              ) : null}

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'New Password is required',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  name="new_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              {passwordError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: '0px' }}
                    >
                      {passwordError}
                    </div>
                  </div>
                </>
              ) : null}
              <Form.Item
                label="Verify Password"
                name="verifyPassword"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Password field required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Enter verify password"
                  name="verify_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '40px',
                    borderRadius: '0.375rem',
                  }}
                />
              </Form.Item>
              <div className="d-flex align-items-center">
                <Button1
                  type="primary"
                  htmlType="submit"
                  className="allBtns me-3"
                  onClick={updatedPass}
                  loading={ButtonLoader}
                >
                  Update Password
                </Button1>
                <a
                  onClick={() => setIsModalVisible(false)}
                  style={{ color: '#8C7E7E' }}
                >
                  Cancel
                </a>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <UserProfile
        visible={profileVisible}
        invisible={closeModal}
        close={closeModal}
        menuTab={menuTab}
      />
    </>
  );
};

export default Header;
