import React, { useState, useEffect } from 'react';
import BrockerCard from '../../../utils/BrokerCard';
import Correct from '../../../assets/images/correct.png';
import { Card, Col, Modal, Button, NavLink, Row, Badge } from 'react-bootstrap';
import { Switch, Table, Input, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Container } from 'reactstrap';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { BsInfoCircle, CrossIcon } from '../../../assets/Icon';
import moment from 'moment';
import Header from '../Header';
import Skeleton from '@mui/material/Skeleton';
import { MdOutlineClose } from 'react-icons/md';
import config from '../../../Config/config';
import Sidebar from '../Sidebar';
import Accordion from 'react-bootstrap/Accordion';
import ConnectedBroker from '../../../utils/ConnectedBroker';

let message = '';
let deleteMessage = '';
var modalData = '';
const Broker = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  //console.log(userid);
  const [brocker, setBrocker] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [showKey, setShowKey] = useState(false);
  //Broker data Hooks
  const [brokerDatas, setBrokerDatas] = useState([]);
  const [broker, setBroker] = useState([]);
  const [connectedBroker, setConnectedBroker] = useState([]);
  const [connectBroker, setConnectBroker] = useState({});
  const [brokerID, setbrokerID] = useState({
    id: '',
    name: '',
  });
  const [updateBroker, setUpdateBroker] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  //console.log("open: ", open);

  const showModal = () => {
    setOpen(true);
  };
  const handleModelOk = () => {
    setOpenModel(false);
  };
  const handleOk = () => {
    setLoading(true);
    setOpenModel(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getBrokerList();
    getconnectedBrokers();
  }, []);

  //API to get all brokers list

  const getBrokerList = () => {
    //console.log("here");
    axios
      .get(`${config.base_url}${config.broker}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        // let extraData = [
        //   {
        //     category_name: 'Collections',
        //   },
        //   { category_name: 'Creators' },
        // ];
        setBrokerDatas(res.data.data);
        // setBrokerDatas([...res.data.data, ...extraData]);
        // console.log('Response Data', res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const header = [
    {
      key: '1',
      title: 'Brocker Name',
      dataIndex: 'broker_name',
      render: (name) => {
        return name && name !== '' ? name : 'Brocker Name';
      },
    },
    {
      title: 'Verified',
      dataIndex: 'input',
      key: '2',
      render: (input, record) => {
        //console.log(input);
        for (let i = 0; i < input.length; i++) {
          //console.log(input[i].key_name);

          return (
            <div className="d-flex gap-2 align-items-center">
              {/* {input[i].key_name === 'client_id' ? (
                <span className="d-flex">{input[i].key_value}</span>
              ) : (
                <span className="d-flex">-</span>
              )} */}

              <span className="d-flex">
                <span>
                  {record.status === 'verified' ? (
                    <Badge bg="success" className="pt-1">
                      Verified
                    </Badge>
                  ) : (
                    <Badge bg="danger" className="pt-1">
                      Not Verified
                    </Badge>
                  )}
                </span>
              </span>
            </div>
          );
        }
        //return status === true ? "Connect On" : "Connect Off";
      },
    },

    {
      title: 'Funds',
      dataIndex: 'funds',
      key: '3',
      render: (funds) => {
        if (
          funds !== undefined &&
          funds !== null &&
          funds !== '' &&
          funds !== 'invalid'
        ) {
          return funds;
        } else {
          return '-';
        }
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: '4',
      render: (status, record) => {
        const onToggle = (checked) => {
          status = checked;
          onActiveUser(status, record);
        };
        return (
          <Switch
            defaultChecked={status === 'verified' ? true : false}
            onChange={onToggle}
          />
        );
      },
    },

    {
      title: 'Added On',
      dataIndex: 'updated_at',
      key: '5',
      render: (updated_at) => {
        return moment(updated_at).format('DD MMM, YYYY h:mm a');
      },
    },

    {
      title: 'Actions',
      dataIndex: '_id',
      key: '6',
      render: (status, record) => {
        //console.log(status, record);
        return (
          <div className="d-flex">
            <button
              onClick={() => viewModal(status, record)}
              className="view__button"
            >
              View
            </button>
            <button
              onClick={() => {
                modalData = record;
                setConfirmDelete(true);
              }}
              className="view__button ml-2"
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  // Function to view particular broker information in the view modal

  const viewModal = (status, record) => {
    // console.log(status, record);
    modalData = record;
    let demo = brokerDatas.filter((el) => el._id === modalData.broker_id);
    modalData = { ...record, logo_img: demo[0].logo_img };
    let Tempinput = {};
    modalData.input.map((el) => {
      if (Tempinput[el.key_name] === undefined) {
        Tempinput[el.key_name] = el.key_value;
      }
    });
    //console.log(Tempinput);
    setUpdateBroker(Tempinput);
    setOpenViewModal(true);
    //console.log(modalData);
    //console.log(modalData);
  };

  //API request made when we update the status

  const onActiveUser = (status, record) => {
    console.log(status, record);
    let payload = {
      id: record._id,
      user_id: record.user_id,
      broker_id: record.broker_id,
      broker_name: record.broker_name,
      input: record.input,
      funds: record.funds ? record.funds : 0,
      status: status === true ? 'verified' : 'not verified',
    };
    //console.log(payload);
    axios
      .put(`${config.base_url}${config.broker_crendientials}`, payload, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        //console.log("updated Data", res);
        getconnectedBrokers();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  //Get single Broker
  const getBroker = (id, name) => {
    //console.log(id);
    axios
      .get(`${config.base_url}${config.broker}/${id}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        //console.log("Response Data", res);
        setBroker(res.data.data);
        setbrokerID({
          id: id,
          name: name,
        });
        let keys = {};
        let input = res.data.data[0].input.map((el) => {
          keys[el.key_name] = '';
        });
        //console.log(dummy);
        setConnectBroker(keys);
        //setBrokerDatas(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // Connected Brokers list
  const getconnectedBrokers = () => {
    //console.log("here");
    axios
      .get(`${config.base_url}${config.broker_crendientials}/${userid.id}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        // console.log("Connected Brokers ", res);
        setConnectedBroker(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // Connect to Brokers

  const handelChange = (e) => {
    setConnectBroker({ ...connectBroker, [e.target.name]: e.target.value });
  };

  const handelChangeUpdateBroker = (e) => {
    setUpdateBroker({ ...updateBroker, [e.target.name]: e.target.value });
  };

  // Connect with brokers API

  const handelSubmit = (e) => {
    e.preventDefault();
    //console.log(connectBroker);
    let inputValue = [];
    for (let x in connectBroker) {
      inputValue.push({ key_name: x, key_value: connectBroker[x] });
    }
    let payload = {
      user_id: userid.id,
      broker_id: brokerID.id,
      broker_name: brokerID.name,
      input: inputValue,
      status: 'not verified',
    };
    //console.log(payload);
    axios
      .post(`${config.base_url}${config.broker_crendientials}`, payload, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        //console.log("added Data", res);
        if (res.data.message === 'Insert successfully.') {
          message = res.data.message;
        } else {
          message = res.data.message;
        }
        handleCancel();
        setOpenModel(true);
        getconnectedBrokers();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // API to update the brokers.

  const updateBrokerData = (payload) => {
    axios
      .put(`${config.base_url}${config.broker_crendientials}`, payload, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        //console.log("added Data", res);
        if (res.data.message === 'Insert successfully.') {
          message = res.data.message;
        } else {
          message = res.data.message;
        }
        setOpenViewModal(false);
        setOpenModel(true);
        getconnectedBrokers();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handelUpdate = (e) => {
    e.preventDefault();
    //console.log(updateBroker);
    let inputValue = [];
    for (let x in updateBroker) {
      inputValue.push({ key_name: x, key_value: updateBroker[x] });
    }
    let payload = {
      id: modalData._id,
      user_id: modalData.user_id,
      broker_id: modalData.broker_id,
      broker_name: modalData.broker_name,
      funds: modalData.funds ? modalData.funds : 0,
      input: inputValue,
      status: modalData.status,
    };
    //console.log(payload);
    updateBrokerData(payload);
  };

  const Keyname = (data) => {
    let name = '';
    if (data !== '' && data !== null && data !== undefined) {
      let temp = data.split('_').join(' ');
      name = temp.charAt(0).toUpperCase() + temp.slice(1);
    }
    //console.log(name);
    return name;
  };

  // Delete the connected brokers.
  const handelDelete = () => {
    let getID = modalData !== '' ? modalData._id : '';
    //console.log(getID);
    axios
      .delete(
        `${config.base_url}${config.broker_crendientials}/${getID}`,

        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      )
      .then((res) => {
        //console.log("Response", res);
        deleteMessage = res.data.message;
        getconnectedBrokers();
        setConfirmDelete(false);
        setSuccessModalShow(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const iconRenders = (visible) => {
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;
  };

  console.log(connectedBroker);
  return (
    <>
      {/* <Header /> */}
      <Sidebar />
      <div className="mt-5 mt-lg-0">
        <Container>
          <Row className="mb-4">
            <Col>
              <h5 className="text-muted text-capitalize d-flex align-items-center mt-4 mt-lg-0">
                Suggested{' '}
                <NavLink
                  href={'/broker/instructions'}
                  className="fsize-12 text-muted ms-3 fweight-4 hoverLink textBColor"
                >
                  <BsInfoCircle /> Setup Instructions
                </NavLink>
              </h5>
            </Col>
          </Row>
        </Container>

        {brokerDatas && brokerDatas.length > 0 ? (
          <section className="mbPx-100 mt__spc">
            <Container>
              <Row className="row-cols-2 row-cols-md-3 row-cols-xl-5 row-cols-xxl-6">
                {brokerDatas.map((el) => {
                  return (
                    <Col key={el._id} className="mb-3">
                      <BrockerCard
                        name={el.name}
                        description={el.description}
                        image={el.logo_img}
                        showModal={showModal}
                        getBroker={getBroker}
                        id={el._id}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
        ) : (
          <section className="mbPx-100 mb__spc">
            <Container>
              <Row className="row-cols-2 row-cols-md-3 row-cols-xl-5 row-cols-xxl-6">
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
                <Col>
                  <Skeleton
                    height="150px"
                    variant="rectangular"
                    style={{ borderRadius: '10px', marginTop: '0px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Col>
              </Row>
            </Container>
          </section>
        )}

        <section className="mt-0 cstm__btm__spc">
          <Container>
            <Row className="mb-4">
              <Col>
                <h5 className="text-muted text-capitalize">
                  Connected Brokers
                </h5>
              </Col>
            </Row>
            {isMobile == false ? (
              <Row>
                <Col>
                  {connectedBroker && connectedBroker.length === 0 ? (
                    <Card className="border-0 round-40 mbPx-24 hpx-200 py-5 d-flex align-items-center justify-content-center">
                      <Card.Body className="px-4">
                        <Card.Subtitle className="mb-4 fw-normal fsize-18 text-capitalize">
                          No connected Broker
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  ) : (
                    <div className="BrockerTable__container mb-4">
                      <Table
                        columns={header}
                        dataSource={connectedBroker}
                        pagination={false}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <div>
                <div className="tbl__hd mobile broker">
                  <div className="coll">Brocker Name</div>
                  <div className="coll">Verified</div>
                  <div className="coll">Funds</div>
                </div>

                <Accordion className="cstm__accordion">
                  {connectedBroker?.map((item) => (
                    <Accordion.Item eventKey={item._id}>
                      <Accordion.Header>
                        <div className="tbl__body broker">
                          <div className="coll text-truncate">
                            {item.broker_name}
                          </div>
                          <div className="coll">
                            {item.status === 'verified' ? (
                              <Badge bg="success" className="pt-1">
                                Verified
                              </Badge>
                            ) : (
                              <Badge bg="danger" className="pt-1">
                                Not Verified
                              </Badge>
                            )}
                            {/* <span className="pt-1 badge bg-success">
                              Verified
                            </span> */}
                          </div>
                          <div className="coll">
                            {item.funds ? item.funds : '-'}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tbl__body__inner broker">
                          <div className="coll text-truncate">Added On</div>
                          <div className="coll">Status</div>
                          <div className="coll">Actions</div>
                        </div>

                        <div className="tbl__body__inner__data broker">
                          <div className="coll text-truncate">
                            {moment(item.updated_at).format(
                              'DD MMM, YYYY h:mm a',
                            )}
                          </div>
                          <div className="coll">
                            <Switch
                              defaultChecked={
                                item.status === 'verified' ? true : false
                              }
                              onChange={(checked) => {
                                // console.log(checked, item);
                                onActiveUser(checked, item);
                              }}
                            />
                            {/* <button
                              type="button"
                              role="switch"
                              aria-checked="true"
                              class="ant-switch ant-switch-checked"
                            >
                              <div class="ant-switch-handle"></div>
                              <span class="ant-switch-inner"></span>
                            </button> */}
                          </div>
                          <div className="coll">
                            <button
                              className="view__button"
                              onClick={() => viewModal('', item)}
                            >
                              View
                            </button>
                            <button
                              className="view__button ml-1"
                              onClick={() => {
                                modalData = item;
                                setConfirmDelete(true);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              // <ConnectedBroker connectedBroker={connectedBroker} />
            )}
          </Container>
        </section>

        <Modal
          show={open}
          onHide={handleCancel}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <Modal.Title>Add Broker</Modal.Title>
              <span onClick={handleCancel} style={{ cursor: 'pointer' }}>
                <CrossIcon className="fsize-25" />
              </span>
            </div>
            <h6 className="steps__header mt-4">Follow the steps below</h6>
            {broker && broker.length > 0 ? (
              <div className="steps__container">
                <p>
                  {broker && broker[0].instructions
                    ? broker[0].instructions
                    : ''}
                </p>

                <div className="">
                  <div>
                    {broker[0].input.map((el, index) => {
                      return (
                        <div className="form-group">
                          <label> {Keyname(el.key_name)}:</label>
                          {el.key_name === 'password' ||
                          el.key_name === 'access_key' ||
                          el.key_name === 'secret_key' ? (
                            <>
                              <div className="div__margin form-group">
                                <Input.Password
                                  iconRender={(visible) =>
                                    visible ? (
                                      <EyeTwoTone />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                  name={el.key_name}
                                  onChange={(e) => handelChange(e)}
                                  style={{ borderRadius: '5px' }}
                                  //placeholder="Api Secret :"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="div__margin form-group">
                                <input
                                  type={
                                    el.key_name === 'password' ||
                                    el.key_name === 'access_key' ||
                                    el.key_name === 'secret_key'
                                      ? 'password'
                                      : 'text'
                                  }
                                  name={el.key_name}
                                  onChange={(e) => handelChange(e)}
                                  //placeholder="Api Secret :"
                                  className="form-control"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                      size="small"
                    />
                  }
                />
              </div>
            )}
            <div className="d-flex justify-content-end mt-4">
              <Button variant="default" className="me-2" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handelSubmit}>
                Add Broker
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={openModel}
          onHide={handleModelOk}
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="position-absolute topPx-10 rightPx-10 zindex-2">
            <button
              onClick={() => {
                handleModelOk();
              }}
              className="btn btnPrimary max-content wpx-40 hpx-40 position-relative"
            >
              <MdOutlineClose className="m-0 position-absolute top-percent-50 left-percent-50 translate-neg-50-50" />
            </button>
          </div>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-end">
              <span onClick={handleModelOk} style={{ cursor: 'pointer' }}>
                <CrossIcon className="fsize-25" />
              </span>
            </div>
            {message === 'Insert successfully.' ||
            message === 'Update successfully.' ? (
              <div className="display__container mt-4">
                <img src={Correct} width="100px" height="100px" alt="img" />
                <h6 className="display__title">
                  {message === 'Insert successfully.'
                    ? 'Connected Successfully.'
                    : 'Updated Successfully.'}
                </h6>
              </div>
            ) : (
              <div className="display__container mt-4">
                <h6 className="display__title">{message}</h6>
              </div>
            )}
            <div className="d-flex justify-content-end mt-4">
              <Button variant="primary" onClick={handleModelOk}>
                Ok, Got it!
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={openViewModal}
          onHide={() => setOpenViewModal(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-end">
              <span
                onClick={() => setOpenViewModal(false)}
                style={{ cursor: 'pointer' }}
              >
                <CrossIcon className="fsize-25" />
              </span>
            </div>

            <div>
              <Row className="mt-4">
                <Col>
                  <div className="d-flex align-items-center">
                    <img
                      src={modalData ? modalData.logo_img : ''}
                      className="mxp-width-100 w-100 me-3"
                      alt={modalData ? modalData.broker_name : ''}
                    />
                    <div>
                      <h4 className="fsize-18 mb-1">
                        {modalData ? modalData.broker_name : ''}
                      </h4>
                      <div>
                        <small className="text-muted fsize-12">
                          <strong>Created At: </strong>
                          {modalData.created_at
                            ? moment(modalData.created_at).format(
                                'DD MMM, YYYY',
                              )
                            : ''}
                        </small>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                {modalData.input &&
                  modalData.input.map((el, index) => {
                    return (
                      <Col sm={12} className="form-group">
                        <label>{Keyname(el.key_name)}</label>
                        {el.key_name === 'password' ||
                        el.key_name === 'access_key' ||
                        el.key_name === 'secret_key' ? (
                          <div>
                            <Input.Password
                              iconRender={iconRenders()}
                              defaultValue={el.key_value}
                              onChange={handelChangeUpdateBroker}
                              //className="form-control"
                              style={{ borderRadius: '5px' }}
                              name={el.key_name}
                            />
                          </div>
                        ) : (
                          <div>
                            <input
                              name={el.key_name}
                              type="text"
                              defaultValue={el.key_value}
                              className="form-control"
                              onChange={handelChangeUpdateBroker}
                            />
                          </div>
                        )}
                      </Col>
                    );
                  })}
              </Row>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <Button
                variant="default"
                className="me-2"
                onClick={() => setOpenViewModal(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => handelUpdate(e)}>
                Update
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* {deletemodal} */}

        <Modal
          show={confirmDelete}
          onHide={() => setConfirmDelete(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="position-absolute topPx-10 rightPx-10 zindex-2">
            <button
              onClick={() => {
                setConfirmDelete(false);
              }}
              className="btn btnPrimary max-content wpx-40 hpx-40 position-relative"
            >
              <MdOutlineClose className="m-0 position-absolute top-percent-50 left-percent-50 translate-neg-50-50" />
            </button>
          </div>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <Modal.Title>Delete Confirmation</Modal.Title>
              <div className="text-end mb-4">
                <span
                  onClick={() => setConfirmDelete(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <CrossIcon className="fsize-25" />
                </span>
              </div>
            </div>
            <div className="alert alert-danger mt-4">
              Are you sure you want to delete?
            </div>
            {/* {console.log(modalData)} */}
            <div className="d-flex align-items-center justify-content-end mt-4">
              <Button
                variant="default"
                onClick={() => {
                  modalData = '';
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={handelDelete}>
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={successModalShow}
          size={'lg'}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="position-relative">
            <div className="text-end mb-4">
              <span
                onClick={() => setSuccessModalShow(false)}
                style={{ cursor: 'pointer' }}
              >
                <CrossIcon className="fsize-25" />
              </span>
            </div>
            {deleteMessage === 'Delete Successfully.' ? (
              <div className="text-center">
                <p>Deleted Successfully</p>
                <button
                  onClick={() => {
                    setSuccessModalShow(false);
                  }}
                  className="mt-4 mb-4 textBColor w-25 ms-auto btn py-2 round-50 hpx-40 fsize-16 fw-semibold secondaryBttn"
                >
                  Okay
                </button>
              </div>
            ) : (
              <p>{deleteMessage}</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Broker;
