import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsFolderX, BsGraphUp } from 'react-icons/bs';

export default function NoDataFound({ title }) {
  return (
    <>
      <div className="container">
        <Row className="justify-content-center text-center">
          <Col xs={12} md={12}>
            {/* <NotFoundIcon /> */}
            <div
              style={{ fontSize: '130px', color: '#c2c2c2' }}
              className="fw-100"
            >
              {/* <MdManageSearch /> */}
              {/* <BsFolderX /> */}
              <BsGraphUp />
            </div>
            {/* <img src={thinkIcon} alt="notFound" className="img-fluid" /> */}
          </Col>
          <Col md={6} className="">
            <h4
              className="text-#ddd5be mb-3 fw-normal"
              style={{ color: 'grey', fontSize: '20px' }}
            >
              {title ? title : 'No Data Found'}
            </h4>
          </Col>
        </Row>
      </div>
    </>
  );
}
