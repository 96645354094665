import {
  makeApiRequest,
  makeApiRequestForSymbol,
  generateSymbol,
  parseFullSymbol,
  makeApiRequestForNFO,
} from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const lastBarsCache = new Map();

let symbol = '';
let exchange = '';
let timeframe = '';
let NFOsymbol = '';
let NFOexchange = '';
let NFOtimeframe = '';
let NFOdata = {};
let data = {};
let page = 1;
let isNullData = false;
let configurationData = {
  currency_codes: [['USD', 'EUR', 'GBP']],
  // supported_resolutions: [
  //   '1',
  //   '2',
  //   '3',
  //   '4',
  //   '5',
  //   '6',
  //   '120',
  //   '60',
  //   '240',
  //   '1D',
  // ],

  timeframe: '6M',
  // exchanges: [
  //   {
  //     value: 'NSE',
  //     name: 'NSE',
  //     desc: 'NSE',
  //   },
  // ],
  symbols_types: [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Stock',
      value: 'Stock',
    },
    {
      name: 'Option',
      value: 'Option',
    },
    // ...
  ],
  symbols_grouping: {
    futures: `/^(.+)([12]!|[FGHJKMNQUVXZ]\d{1,2})$/`,
    stock: `/^(.+)([12]!|[FGHJKMNQUVXZ]\d{1,2})$/`,
  },
};

async function getAllSymbols(symbolType) {
  //console.log(symbolType);
  try {
    const data = await makeApiRequestForSymbol();

    let allSymbols = [];

    for (const exchange in data.data) {
      //console.log(exchange);
      const pairs = data.data[exchange];
      if (pairs) {
        for (let i = 0; i < pairs.length; i++) {
          allSymbols.push({
            symbol: pairs[i],
            full_name: `${exchange}:${pairs[i]}`,
            description: pairs[i],
            exchange: exchange,
            type: exchange === 'NSE' ? 'Stock' : 'Option',
          });
        }
      }
    }
    //console.log(allSymbols);

    let filteredSymbol = allSymbols.filter((data) => data.type === symbolType);
    // console.log(filteredSymbol);
    if (symbolType === undefined || symbolType === '') {
      return allSymbols;
    } else {
      //console.log('here');
      return filteredSymbol;
    }
  } catch (error) {}
}

export default {
  onReady: (callback) => {
    // console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback,
  ) => {
    // console.log('[searchSymbols]: Method call');
    const symbols = await getAllSymbols(symbolType);

    //console.log('configurationData', configurationData);
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    //console.log(exchange, newSymbols);
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
  ) => {
    // console.log('[resolveSymbol]: Method call', symbolName);
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(
      ({ full_name }) => full_name === symbolName,
    );
    if (!symbolItem) {
      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }
    // console.log('symbolItem', symbolItem);
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '0915-1530',
      timezone: 'Asia/Kolkata',
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      //supported_resolutions: configurationData.supported_resolutions,
      timeframe: configurationData.timeframe,
      volume_precision: 2,
      data_status: 'streaming',
      period: '1D',
    };

    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    // console.log(periodParams);
    // console.log('[getBars]: Method call', symbolInfo, resolution, from, to);

    const getResolution = (resolution) => {
      return resolution[resolution.length - 1] === 'D' ? 'day' : resolution;
    };

    if (symbolInfo.exchange === 'NFO') {
      if (
        NFOsymbol !== symbolInfo.name ||
        NFOexchange !== symbolInfo.exchange ||
        NFOtimeframe !== getResolution(resolution)
      ) {
        console.log('request made');
        try {
          NFOdata = await makeApiRequestForNFO(
            `${symbolInfo.name}`,
            getResolution(resolution),
          );
          console.log(NFOdata);
          if (
            (NFOdata.Response && NFOdata.Response === 'Error') ||
            NFOdata.data.length === 0
          ) {
            // "noData" should be set if there is no data in the requested period.
            onHistoryCallback([], {
              noData: true,
            });
            return;
          }

          let bars = [];
          NFOdata.data.forEach((bar) => {
            if (NFOdata.data.length < 300) {
              if (bar.timestamp >= from && bar.timestamp < to) {
                bars = [
                  ...bars,
                  {
                    time:
                      getResolution(resolution) === 'day'
                        ? (bar.timestamp + 19800) * 1000
                        : bar.timestamp * 1000,
                    low: bar.low,
                    high: bar.high,
                    open: bar.open,
                    close: bar.close,
                  },
                ];
              }
            } else {
              bars = [
                ...bars,
                {
                  time: bar.timestamp * 1000,
                  low: bar.low,
                  high: bar.high,
                  open: bar.open,
                  close: bar.close,
                },
              ];
            }
          });
          NFOsymbol = symbolInfo.name;
          NFOexchange = symbolInfo.exchange;
          NFOtimeframe = getResolution(resolution);
          //console.log(bars);
          if (firstDataRequest) {
            lastBarsCache.set(symbolInfo.full_name, {
              ...bars[bars.length - 1],
            });
          }
          console.log(`[getBars]: returned ${bars.length} bar(s)`);

          onHistoryCallback(bars, {
            noData: false,
            nextTime: NFOdata.data[0].timestamp,
          });
        } catch (error) {
          console.log('[getBars]: Get error', error);
          onErrorCallback(error);
        }
      } else {
        //console.log(NFOdata.data[0].timestamp);
        let bars = [];
        NFOdata.data.forEach((bar) => {
          if (NFOdata.data.length < 300) {
            if (bar.timestamp >= from && bar.timestamp < to) {
              bars = [
                ...bars,
                {
                  time:
                    getResolution(resolution) === 'day'
                      ? (bar.timestamp + 19800) * 1000
                      : bar.timestamp * 1000,
                  low: bar.low,
                  high: bar.high,
                  open: bar.open,
                  close: bar.close,
                },
              ];
            }
          } else {
            bars = [
              ...bars,
              {
                time: bar.timestamp * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
              },
            ];
          }
        });
        console.log('request not made');
        //console.log(bars);
        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          });
        }
        console.log(`[getBars]: returned ${bars.length} bar(s)`);

        onHistoryCallback(bars, {
          noData: false,
          nextTime: NFOdata.data[0].timestamp,
        });
        NFOsymbol = symbolInfo.name;
        NFOexchange = symbolInfo.exchange;
        NFOtimeframe = getResolution(resolution);
      }
    } else {
      if (
        Object.keys(data).length > 0 &&
        data.data[0]?.timestamp > from &&
        data.data[0]?.timestamp > to &&
        !isNullData
      ) {
        //console.log('need network request');
        page++;
        try {
          data = await makeApiRequest(
            `${symbolInfo.name}`,
            getResolution(resolution),
            symbolInfo.exchange,
            page,
          );
          //console.log(data);
          if (data.data.length === 0) {
            isNullData = true;
          }
          //console.log(data.data);
          if (
            (data.Response && data.Response === 'Error') ||
            data.data.length === 0
          ) {
            // "noData" should be set if there is no data in the requested period.
            onHistoryCallback([], {
              noData: true,
            });
            return;
          }
          let bars = [];
          data.data.forEach((bar) => {
            // if (bar.timestamp >= from && bar.timestamp < to) {
            bars = [
              ...bars,
              {
                time:
                  getResolution(resolution) === 'day'
                    ? (bar.timestamp + 19800) * 1000
                    : bar.timestamp * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
              },
            ];
            // }
          });
          symbol = symbolInfo.name;
          exchange = symbolInfo.exchange;
          timeframe = getResolution(resolution);
          //console.log(bars);
          if (firstDataRequest) {
            lastBarsCache.set(symbolInfo.full_name, {
              ...bars[bars.length - 1],
            });
          }
          console.log(`[getBars]: returned ${bars.length} bar(s)`);

          onHistoryCallback(bars, {
            noData: false,
            //nextTime: data.data[0].timestamp,
          });
        } catch (error) {
          console.log('[getBars]: Get error', error);
          onErrorCallback(error);
        }
      } else {
        if (
          symbol !== symbolInfo.name ||
          exchange !== symbolInfo.exchange ||
          timeframe !== getResolution(resolution)
        ) {
          console.log('request made');
          try {
            page = 1;
            isNullData = false;
            data = await makeApiRequest(
              `${symbolInfo.name}`,
              getResolution(resolution),
              symbolInfo.exchange,
              page,
            );
            //console.log(data);
            if (
              (data.Response && data.Response === 'Error') ||
              data.data.length === 0
            ) {
              // "noData" should be set if there is no data in the requested period.
              onHistoryCallback([], {
                noData: true,
                //nextTime: data.data[0].timestamp,
              });
              return;
            }

            let bars = [];
            data.data.forEach((bar) => {
              //if (bar.timestamp >= from && bar.timestamp < to) {
              bars = [
                ...bars,
                {
                  time:
                    getResolution(resolution) === 'day'
                      ? (bar.timestamp + 19800) * 1000
                      : bar.timestamp * 1000,
                  low: bar.low,
                  high: bar.high,
                  open: bar.open,
                  close: bar.close,
                },
              ];
              // }
            });
            symbol = symbolInfo.name;
            exchange = symbolInfo.exchange;
            timeframe = getResolution(resolution);
            //console.log(bars);
            if (firstDataRequest) {
              lastBarsCache.set(symbolInfo.full_name, {
                ...bars[bars.length - 1],
              });
            }
            console.log(`[getBars]: returned ${bars.length} bar(s)`);

            onHistoryCallback(bars, {
              noData: false,
              //nextTime: data.data[0].timestamp,
            });
          } catch (error) {
            console.log('[getBars]: Get error', error);
            onErrorCallback(error);
          }
        } else {
          let bars = [];
          data.data.forEach((bar) => {
            //if (bar.timestamp >= from && bar.timestamp < to) {
            bars = [
              ...bars,
              {
                time:
                  getResolution(resolution) === 'day'
                    ? (bar.timestamp + 19800) * 1000
                    : bar.timestamp * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
              },
            ];
            // }
          });
          console.log('request not made');
          //console.log(bars);
          if (firstDataRequest) {
            lastBarsCache.set(symbolInfo.full_name, {
              ...bars[bars.length - 1],
            });
          }
          console.log(`[getBars]: returned ${bars.length} bar(s)`);

          onHistoryCallback(bars, {
            noData: false,
            // nextTime: data.data[0].timestamp,
          });
          symbol = symbolInfo.name;
          exchange = symbolInfo.exchange;
          timeframe = getResolution(resolution);
        }
      }
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
  ) => {
    console.log(
      '[subscribeBars]: Method call with subscribeUID:',
      subscribeUID,
    );
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name),
    );
  },

  unsubscribeBars: (subscriberUID) => {
    // console.log(
    //   '[unsubscribeBars]: Method call with subscriberUID:',
    //   subscriberUID,
    // );
    unsubscribeFromStream(subscriberUID);
  },
};
