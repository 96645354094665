import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

import { mydecodedTokenFunction } from '../../utils/decodetoken';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../Config/config';

const DeleteConfirmation = ({
  showModal,
  hideModal,
  id,
  updateReportOnDel,
  from,
  refetch,
  loading,
}) => {
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  const tokenexist = localStorage.getItem('token');

  const handleDelete = () => {
    updateReportOnDel(true);
    axios
      .delete(
        from === 'virtual'
          ? `${config.base_url}${config.virtual_trade_delete}/${id}`
          : `${config.base_url}${config.live_trade_delete}/${id}`,
        { headers: { Authorization: `Bearer ${getuserBots}` } },
      )
      .then((res) => {
        console.log('Response Data', res.data.message);
        // from === 'virtual';
        // from === 'live';

        updateReportOnDel(false);
        hideModal();
        refetch();
        return toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        // if (res.data.message === 'Data Delete Successfully.') {
        //   return toast.success('Deleted Successfully', {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 3000,
        //   });
        // } else {
        //   return toast.console.error(res.data.message, {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      })
      .catch(function (status) {});
    //console.log(id);
  };
  // console.log(id,5555);
  return (
    <Modal show={showModal} onHide={hideModal} style={{ marginTop: '200px' }}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">
          Are you sure you want to delete?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {loading && <i className={`fas fa-circle-notch fa-spin`}></i>} Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
