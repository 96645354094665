import React, { useState, useEffect } from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const ReportCardsData = ({
  tab,
  fundBalance,
  lastOrder,
  configData,
  amountInvested,
  todayPnL,
}) => {
  return (
    <>
      <div className="strategy__sml__details__bellow row market__active mt-3">
        <ul>
          <li>
            <span>Avl. Margin : </span>{' '}
            <p className="primary__text__blue text__semibold">
              {tab === 'LT'
                ? fundBalance
                : lastOrder.length > 0
                ? Number(configData?.investment - amountInvested).toFixed(2)
                : 'N.A'}
            </p>
          </li>
          <li>
            <span>Amount Invested : </span>{' '}
            <p className="primary__text__blue text__semibold">
              {Number(amountInvested).toFixed(2)}
            </p>
          </li>
          <li>
            <span>Current PNL : </span>
            <p className="text__semibold">
              {lastOrder.length > 0 ? (
                lastOrder[0]?.pnl >= 0 ? (
                  <span className="text-success ml-2">
                    <BsArrowUp />
                    {Number(lastOrder[0]?.pnl).toFixed(2)}
                  </span>
                ) : (
                  <span className="text-danger ml-2">
                    <BsArrowDown />
                    {Number(lastOrder[0]?.pnl).toFixed(2)}
                  </span>
                )
              ) : (
                <span>N.A</span>
              )}
            </p>
          </li>
          <li>
            <span>Max Cap : </span>{' '}
            <p className="text__semibold">
              {' '}
              {Number(configData?.investment).toFixed(2)}
            </p>
          </li>
          <li>
            <span>Total PNL : </span>{' '}
            <p className="text__semibold primary__green">
              {todayPnL >= 0 ? (
                <span className="text-success ml-2">
                  {Number(todayPnL).toFixed(2)}
                </span>
              ) : (
                <span className="text-danger ml-2">
                  {Number(todayPnL).toFixed(2)}
                </span>
              )}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(ReportCardsData);
